/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-prototype-builtins */
/*
 * pc端查询客户、供应商联系数量
 * @Author: your name
 * @Date: 2022-03-24 17:10:17
 * @LastEditTime: 2022-06-23 19:01:36
 * @LastEditors: 张立鹏 2475171028@qq.com
 * @Description: In User Settings Edit
 * @FilePath: \signser\src\containers\ChatForm\index.js
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import { useMount } from '../../../utils/hooks';
// eslint-disable-next-line import/extensions
import { chatFeedBackFn } from '../../../utils/utils';

// eslint-disable-next-line
import ChatFeedBackGood_2300 from '@/images/ChatFeedBackGood_2300.gif';
// eslint-disable-next-line import/extensions
import ChatFeedBackDown from '@/images/ChatFeedBackDown_2300.png';
// eslint-disable-next-line import/extensions
// eslint-disable-next-line no-unused-vars
import ChatFeedBackUp from '@/images/ChatFeedBackUp_2300.png';

export default function ChatFeedBackPCAndMobile({ MessageId, device }) {
  ChatFeedBackPCAndMobile.propTypes = {
    MessageId: PropTypes.string,
    device: PropTypes.string,
  };
  // eslint-disable-next-line no-unused-vars
  const [pageSatus, setPageStatus] = useState(true);
  // 点赞点踩
  const [feedStatus, setFeedStatus] = useState(null);

  useMount(() => {
    let timer;
    if (pageSatus) {
      timer = setTimeout(() => {
        Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollTop = Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollHeight;
      }, 300);
      return () => {
        clearTimeout(timer);
      };
    }
    return () => {
      // eslint-disable-next-line
      timer && clearTimeout(timer);
    };
  });

  function setChatFeedBack(type) {
    if (feedStatus) return;
    if (type === 'good') {
      setFeedStatus('startGood');
      const timeout = setTimeout(() => {
        setFeedStatus(type);
        clearTimeout(timeout);
      }, 1000);
    } else {
      setFeedStatus(type);
    }
    
    chatFeedBackFn(MessageId, type);
  }

  return (
    <div className={device === 'pc' ? 'v2300ChatFeedBack' : 'v2300MobileChatFeedBack'}>
      <div className="goodImg">
        {
          ['bad', null].includes(feedStatus) ? (
            <img className="img-up" src={ChatFeedBackDown} onClick={() => setChatFeedBack('good')} />
          ) : ''
        }
        {
          feedStatus === 'startGood' ? (
            <img src={ChatFeedBackGood_2300} />
          ) : ''
        }
        {
          feedStatus === 'good' ? (
            <img src={ChatFeedBackUp} />
          ) : ''
        }
      </div>
      <div className="mt3">
        {
          feedStatus === 'bad' ? (
            <img src={ChatFeedBackUp} className="img-up" />
          ) : <img src={ChatFeedBackDown} onClick={() => setChatFeedBack('bad')} />
        }
      </div>
    </div>
  );
}
