/*
pc端条形图组件
 * @Author: your name
 * @Date: 2021-10-25 19:01:06
 * @LastEditTime: 2022-07-26 14:21:15
 * @LastEditors: 张立鹏 2475171028@qq.com
 * @Description: In User Settings Edit
 * @FilePath: \signser\src\containers\Line\index.js
 */
/*eslint-disable*/
import React, { useEffect } from 'react';
import * as echarts from 'echarts/core';
import { TooltipComponent, GridComponent } from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { LegendComponent } from 'echarts/components';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import '@/style/line.scss';
import _ from 'lodash';

echarts.use([
  TooltipComponent,
  GridComponent,
  LineChart,
  CanvasRenderer,
  UniversalTransition,
  LegendComponent
]);

export default function Line({ msg, id }) {
    const item1 = Object.values(msg.info.series[1].data).map(item => {
      return Number(item)
    })
    const item0 = Object.values(msg.info.series[0].data).map(item => {
      return Number(item)
    })
    const max = Math.max(_.max(item1), _.max(item0));
    let data1 = [], data2 = [];
    msg.info.category.forEach((item) =>{
      data1.push(msg.info.series[0].data[item]);
      data2.push(msg.info.series[1].data[item])
    });
    let RMB;
    if(max/1000 > 10) {
      RMB = '万元';
    } else {
      RMB = '元'
    }
    useEffect(() => {
        var chartDom = document.getElementById(id);
        chartDom.style.width = '100%'
        chartDom.style.height = '224px'
        var myChart = echarts.init(chartDom);
        var option;
        option = {
            color: ['#3377FF','#FFAA00'],
            tooltip: {
                trigger: 'axis',
                borderColor: 'transparent',
                axisPointer: {
                    type: null
                },
                backgroundColor: 'rgba(62, 70, 77, 0.8)',
                formatter(params) {
                  return `
                  <div style="color: #FFFFFF" class="tooltip-line">
                    <div style="font-weight: bold;">${params[0].axisValue}月</div>
                    <div style="height: 1px;background: #C0C4CC;"></div>
                    <div class="a1">${params[0].seriesName}: ￥${params[0].data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}元</div>
                    <div class="a2">${params[1].seriesName}: ￥${params[1].data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}元</div>
                 <div/>
                  `
                }
            },
            legend:{
                top: '14%',
                right: '25%',
                itemGap: 20,
                icon: 'circle',
                itemWidth: 6,
                textStyle: {
                  color: '#909399',
                  fontSize: 10
                }
            },
            grid: {
                left: '5%',
                right: '10%',
                bottom: '5%',
                top: '30%',
                containLabel: true
            },
          xAxis: {
            type: 'category',
            data: msg.info.category,
            name: '月份',
            axisTick: {
                show: false
            },
            boundaryGap: false,
            splitLine: {
                show: true
            },
            axisLine: {
                show: false
            },
            axisLabel: {
              color: '#606266',
              fontSize: 10,
              fontWeight: 400,
            }
          },
          yAxis: {
            type: 'value',
            name: `金额(${RMB})`,
            splitLine:{
                show:false
            },
            axisLabel: {
              color: '#606266',
              fontSize: 10,
              fontWeight: 400,
            },
            axisLabel: {
              formatter(params) {
                if(RMB === '万元') {
                  return (params/10000).toFixed(2)
                } else {
                  return params.toFixed(2)
                }
              }
            }
          },
          series: [
            {
              data: data1,
              type: 'line',
              smooth: true,
              symbol:'circle',
              showSymbol: false,
              name: msg.info.series[0].name
            },
            {
              data: data2,
              type: 'line',
              smooth: true,
              symbol:'circle',
              showSymbol: false,
              name: msg.info.series[1].name
            }
          ]
        };
        
        option && myChart.setOption(option);
        const timer = setTimeout(() => {
          Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollTop = Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollHeight;
        }, 300);
        return () => {
          clearTimeout(timer);
        }
    }, []);
    return (
      <div>这是线性图标</div>
    );
}
