/*
移动端查指定组件
 * @Author: your name
 * @Date: 2021-12-06 17:50:10
 * @LastEditTime: 2022-07-06 14:48:32
 * @LastEditors: 张立鹏 2475171028@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \Signser-web\src\containers\MobileAccounts\index.js
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '@/style/mobileAccounts.scss';
import jianshe from '@/images/jianshe.png';
import zhaoshang from '@/images/zhaoshang.png';
import yinhangka from '@/images/yinhangka.png';
import nolook from '@/images/nolook.png';
import islook from '@/images/islook.png';
import wei from '@/images/wei@2x.png';
import xianjin from '@/images/xianjin@2x.png';
import zhi from '@/images/zhi@2x.png';
import qita from '@/images/qita.png';
import right from '@/images/icon-right.png';
import Robot from '@/images/robot_logo.png';
import { useMount } from '../../utils/hooks';
import bankName from '../../utils/bank';
// eslint-disable-next-line
import request from '@/utils/request';
import loading from '@/images/loading.png';

let startAccount = false;// 为true时说明move事件触发 start事件的东西不执行
// eslint-disable-next-line
export default function MobileAccounts({ msg, allAccountCallback, appendMessage }) {
  MobileAccounts.propTypes = {
    msg: PropTypes.object,
    allAccountCallback: PropTypes.func,
    appendMessage: PropTypes.func,
  };
  const [force, setForce] = useState(null);
  let dataArr = msg?.info.list;
  dataArr = returnData(dataArr);
  const [flagStatusObj, setFalgStatusObj] = useState({});
  const bankList = [jianshe, zhaoshang, yinhangka];
  const iconList = [0, bankList, xianjin, zhi, wei, qita];
  const [pageSatus, setPageStatus] = useState(true);
  const [current, setCurrent] = useState(1);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [timeAccount, setTimeAccount] = useState(null);
  const [pickArr, setPickArr] = useState([]);

  useMount(() => {
    if (current > 1) {
      request.instanceZhangkuan.get(`${process.env.REACT_APP_ZK}/${msg.info.api}`, {
        params: {
          ...msg.info.parameter,
          per_page: 3,
          current_page: current,
        },
      }).then((res) => {
        setLoadingStatus(false);
        if (current < 3) {
          dataArr = [...dataArr, ...res.info.list];
          setForce(returnData(dataArr));
        } else {
          setForce(returnData([...force, ...res.info.list]));
        }
      });
    }
  }, [current]);

  useMount(() => {
    let timer;
    if (pageSatus) {
      timer = setTimeout(() => {
        Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollTop = Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollHeight;
      }, 300);
      return () => {
        clearTimeout(timer);
      };
    }
    return () => {
      // eslint-disable-next-line
      timer && clearTimeout(timer);
    };
  });
  /**
   * @description 格式化银行账户数据
   * @param {*} item 银行账户数据
   * @returns 
   */
  function returnData(item) {
    item.map((ele) => {
      if (ele.values) {
        if (ele.values.includes('@')) {
          ele.trueValues = ele.values;
          ele.falseValue = ele.trueValues.replace(/(.{3}).+(.{0}@.+)/g, '$1******$2');
        } else if (ele.values.length === 11) {
          ele.trueValues = ele.values.replace(/^(.{3})(.*)(.{4})/, '$1 $2 $3');
          ele.falseValue = ele.trueValues.replace(ele.trueValues.slice(4, 8), '****');
        } else {
          ele.trueValues = ele.values.replace(/(.{4})/g, '$1 ');
          ele.falseValue = ele.trueValues.replace(ele.trueValues.slice(0, 18), '**** **** **** ***');
        }
      }
      if (ele.leb === 1 || ele.leb === 5) {
        if (ele.bank_name) {
          ele.bankIcon = bankName(ele.bank_name);
        } else {
          ele.bankIcon = yinhangka;
        }
      }
      return ele;
    });
    return item;
  }
  // 是否脱敏
  function isLook(e, item) {
    e.stopPropagation();
    if (flagStatusObj[item.payment_method_id]) {
      setFalgStatusObj({
        ...flagStatusObj,
        [item.payment_method_id]: false,
      });
    } else {
      setFalgStatusObj({
        ...flagStatusObj,
        [item.payment_method_id]: true,
      });
    }
  }

  // 下一页
  function nextPage() {
    startAccount = false;
    const timeOut = setTimeout(() => {
      if (!startAccount) {
        if (pickArr.length) {
          setPickArr([]);
          return;
        } 
        setLoadingStatus(true);
        setCurrent(current + 1);
        setPageStatus(false);
      }
      clearTimeout(timeOut);
    }, 100);
  }

  // 账户详情
  function detail(e, item) {
    if (item.api) {
      allAccountCallback();
      // eslint-disable-next-line
      const values = item.leb === 1 ? (item.bank_name === null ? '' : item.bank_name)  + '（' + item.values.slice(item.values.length - 4) + '）' : item.payment_method;
      request.instanceZhangkuan.get(`${process.env.REACT_APP_ZK}${item.api}`, {
        params: {
          per_page: 3,
          current_page: 1,
          payment_method_id: item.payment_method_id,
          values,
        },
      }).then((res) => {
        appendMessage({ text: res }, 'left', 'Mobile', Robot, true, 'Text');
      });
    }
  }
  function onTouchMove() {
    startAccount = true;
  }
  function onTouchStart(e, item) {
    startAccount = false;
    const timeOut = setTimeout(() => {
      if (!startAccount) {
        detail(e, item);
      }
    }, 100);
    setTimeAccount(timeOut);
  }
  useMount(() => {
    clearTimeout(timeAccount);
  });
  function packUp() {
    startAccount = false;
    const timeOut = setTimeout(() => {
      if (!startAccount) {
        setPickArr(force.slice(0, 3));
      }
      clearTimeout(timeOut);
    }, 100);
  }
  return (
    <div className="mobileAccountsList">
      {
        (pickArr.length ? pickArr : force || dataArr).map((item) => (
          <div className="mobileAccounts" key={item.payment_method_id} onTouchStart={(e) => onTouchStart(e, item)} onTouchMove={onTouchMove}>
            <div>
              <div className="icon" style={{ marginRight: 'calc(8rem/75)' }}>
                <img src={item.leb === 1 ? item.bankIcon : iconList[item.leb]} alt="" />
              </div>
              <div className="company-name">
                <span>{item.payment_method}</span>
                <span className="huise">
                  {item.bank_name ? `（${item.bank_name}）` : ''}
                </span>
              </div>

            </div>
            <div className={item.values ? 'card-num' : 'none'} style={{ postion: 'relative', left: 'calc(36rem/75)' }}>
              {/* eslint-disable-next-line */}
              <span>{flagStatusObj[item.payment_method_id] ? item.trueValues : item.falseValue}</span>
              <div style={{ marginLeft: 'calc(12rem/75)', width: 'calc(32rem/75)' }} onTouchStart={(e) => isLook(e, item)}>
                <img src={flagStatusObj[item.payment_method_id] ? islook : nolook} alt="" />
              </div>
            </div>
            <div className="huise zhihang">
              开户支行：
              { item.account_branch ? item.account_branch : '-'}
            </div>
          </div>
        ))
      }
      <div className={Number(msg.info.total) > 3 ? 'filter-bottom' : 'none'}>
        <div className="filter" />
        <div className="flex ismore" style={{ height: 'calc(48rem/75)' }}>
          {/* eslint-disable-next-line */}
          <div className={current >= Number(msg.info.total_page) && pickArr.length === 0 ? 'none' : 'more'} onTouchStart={() => loadingStatus ? '' : nextPage()} onTouchMove={onTouchMove}>{loadingStatus ? <div className="loading"><img src={loading} alt="" /></div> : '查看更多'}</div>
          <div className={current > 1 && pickArr.length === 0 ? 'flex align-item left pick-style' : 'none'} style={{ position: 'absolute', right: 0 }} onTouchStart={packUp} onTouchMove={onTouchMove}>
            <div className="pack-up">
              收起
            </div>
            <div style={{ width: 'calc(28rem/75)', height: 'calc(28rem/75)' }}>
              <img src={right} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}
