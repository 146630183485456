/* eslint-disable max-len */
/* eslint-disable no-empty */
/*
pc端第一次进入页面是展示的样式
 * @Author: your name
 * @Date: 2021-10-25 14:46:25
 * @LastEditTime: 2022-07-08 21:21:34
 * @LastEditors: 张立鹏 2475171028@qq.com
 * @Description: In User Settings Edit
 * @FilePath: \signser\src\containers\TableData\index.js
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMount } from '../../utils/hooks';
import { recommendClick, getRecommendEntrance } from '../../utils/utils';
import '@/style/chat-form.scss';
import '@/style/FirstSentence.scss';
//  sendFAQ 直接点击发送
export default function FirstSentence({ pcSend, id, appendMessage, setIsShowFooterInput }) {
  FirstSentence.propTypes = {
    // sendFAQ: PropTypes.func,
    pcSend: PropTypes.func,
    appendMessage: PropTypes.func,
    setIsShowFooterInput: PropTypes.func,
    id: PropTypes.string,
  };

  // eslint-disable-next-line
  // let initArr = ['我的逾期应收', '我跟进的合同有没有逾期', '查逾期的应收合同', '查逾期的应付合同', '查我上个月的跟进的合同有没有逾期', '账龄在90天内的应收账款', '我跟进的30天内的应收账款', '产品部门跟进的账龄在90天内的应收账款', '这个月开了多少普票', '这个月有多少发票', '这个月开了多少合同发票', '已付款未收票的合同还有多少', '西安朋客信息科技有限公司有没有已付款未收票的合同', '还有多少发票没有收款', '还有多少收款没有开票', '我的销售额', '销售额', '产品部这个月的销售额', '今年的销售额', '我上个月的销售额', '我们公司的销售额', '这个月公司业绩排名', '我们公司这个月谁的销售额最高', '产品部门的销售业绩', '张三这个月的销售额怎么样', '这个月账户支出有多少', '查一下这个月的账户收入', '查一下这个月的账户支出', '这个月账户收入有多少', '这个月的交易流水', '看一下十月份的现金流明细', '查上个月的交易流水'];
  // const [arr, setArr] = useState(initArr);
  const [arr, setArr] = useState([]);
  const [random, setRandom] = useState(Math.floor(Math.random() * arr.length));
  // eslint-disable-next-line prefer-const
  let [messageInfo, setMessageInfo] = useState(null);

  useMount(async () => {
    // 推荐列表初始化
    // eslint-disable-next-line max-len
    getRecommendEntrance(id, setMessageInfo, setArr, setRandom, pcSend, appendMessage, setIsShowFooterInput, 'pc');
  });
  // 点击换一批页面上的数据发生改变
  function change() {
    setRandom(Math.floor(Math.random() * arr.length));
  }
  /**
   * @description App组件传递点击的内容
   * @param {*} e 事件对象 通过e.target.innerText获取标签内容
   */
  function send(e) {
    // 推荐点击事件
    recommendClick('recommendEnterClick', messageInfo, e.target.innerText.split('、')[1]);
    // sendFAQ(e.target.innerText.split('、')[1]);
    pcSend(e.target.innerText.split('、')[1]);
  }
  return (
    <div style={{ minWidth: '196px' }}>
      <div style={{
          fontSize: '14px',
          fontFamily: 'PingFang SC-Regular, PingFang SC',
          fontWeight: '400',
          color: '#333333',
        }}>
        {/* {
          messageInfo && messageInfo.message_type === 1 ? '' : '您可以试试这么问：'
        } */}
      </div>
      <div className="ChatForm" style={{ marginTop: '6px' }}>
        {
          arr.length >= 1 ? (
            <div className="list cursor font margin-bottom" onClick={(e) => send(e)}>
              1、
              {
                // eslint-disable-next-line no-nested-ternary
                arr.length === 1 ? arr[0] : (random === 0 ? arr[random + 1] : arr[random - 1])
              }
            </div>
          ) : ''
        }
        {
          arr.length >= 2 ? (
            <div className="list cursor font margin-bottom" onClick={(e) => send(e)}>
              2、
              {
                // eslint-disable-next-line no-nested-ternary
                arr.length === 2 ? arr[1] : arr[random]
              }
            </div>
          ) : ''
        }
        {
          arr.length >= 3 ? (
            <div className="list cursor font margin-bottom" onClick={(e) => send(e)}>
              3、
              {
                // eslint-disable-next-line no-nested-ternary
                arr.length === 3 ? arr[2] : (random + 1 === arr.length ? arr[random - 2] : (random === 0 ? arr[random + 2] : arr[random + 1]))
              }
            </div>
          ) : ''
        }
    
      </div>
      {
        arr.length <= 3 ? '' : (
          <div className="FirstSentence" onClick={change}>
            <div className="img">
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="&#229;&#136;&#183;&#230;&#150;&#176;">
                  <path id="Vector" d="M6.93453 9.80954C5.667 10.0673 4.3009 9.71067 3.33031 8.7404C1.88479 7.29456 1.78592 5.0373 3.03336 3.49258V4.22523C3.03336 4.44308 3.21166 4.62138 3.42951 4.62138C3.64735 4.62138 3.82533 4.44308 3.82533 4.22523V2.64097C3.82533 2.52231 3.78578 2.42312 3.70668 2.36379C3.67059 2.32662 3.62748 2.29699 3.57984 2.27662C3.5322 2.25625 3.48099 2.24555 3.42919 2.24514H1.84556C1.62771 2.24514 1.44941 2.42312 1.44941 2.64097C1.44941 2.85881 1.62771 3.03711 1.84556 3.03711H2.39991C0.914522 4.87877 1.05295 7.57205 2.75619 9.29506C3.9443 10.4829 5.58789 10.8988 7.11283 10.5821C7.17216 10.5623 7.25126 10.5227 7.29113 10.4832C7.32877 10.4474 7.35874 10.4044 7.37922 10.3567C7.3997 10.309 7.41026 10.2576 7.41026 10.2057C7.41026 10.1538 7.3997 10.1024 7.37922 10.0547C7.35874 10.007 7.32877 9.96395 7.29113 9.9282C7.21171 9.80954 7.05319 9.76999 6.93453 9.80954ZM10.4198 8.83927H9.88521C11.3307 6.97784 11.2121 4.28456 9.48907 2.56186C8.38005 1.45285 6.87521 1.01715 5.42937 1.21522C5.3305 1.21522 5.25139 1.25477 5.17197 1.33419C5.13443 1.36994 5.10455 1.41294 5.08413 1.46059C5.06371 1.50823 5.05318 1.55953 5.05318 1.61137C5.05318 1.66321 5.06371 1.7145 5.08413 1.76215C5.10455 1.80979 5.13443 1.8528 5.17197 1.88854C5.27085 1.98742 5.4096 2.02729 5.54834 1.98742C6.73646 1.80944 8.004 2.18549 8.91494 3.09644C10.3605 4.54227 10.4593 6.79954 9.21157 8.34425V7.6116C9.21157 7.39376 9.0336 7.21546 8.81607 7.21546C8.5979 7.21546 8.4196 7.39376 8.4196 7.6116V9.19587C8.4196 9.31452 8.45947 9.41372 8.53857 9.47304C8.61768 9.55215 8.71687 9.5917 8.81607 9.5917H10.4C10.6179 9.5917 10.7962 9.41372 10.7962 9.19587C10.7962 8.97802 10.6376 8.83927 10.4198 8.83927Z" fill="#909399" />
                </g>
              </svg>
            </div>
            <div className="font">换一批</div>
          </div>
        )
      }
    </div>
  );
}
