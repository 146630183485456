/*
移动端常见问题
 * @Author: your name
 * @Date: 2021-10-20 16:19:54
 * @LastEditTime: 2022-01-25 18:23:35
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \signser\src\containers\More\index.js
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '@/style/more.scss';
import signser from '@/images/signser.png';
import IconRight from '@/images/Icon_right.png';

let start = false;// 为true时说明move事件触发 start事件的东西不执行
export default function More({ getKnowledgeId }) {
  const [more, setMore] = useState(false); // true查看更多 false收起查看更多 默认false
  const [timer, setTimer] = useState(null);
  More.propTypes = {
    getKnowledgeId: PropTypes.func,
  };
  /**
   * @description 点击查看更多按钮触发此方法
   * @returns more的状态 取反
   */
  function getMore() {
    setMore(!more);
  }
  function onTouchMove() {
    start = true;
  }
  function onTouchStart(value, id) {
   start = false;
   const timeOut = setTimeout(() => {
      if (!start) {
        getKnowledgeId(value, id);
      }
    }, 100);
    setTimer(timeOut);
  }
  React.useEffect(() => () => {
      clearTimeout(timer);
    }, []);
  return (
    <div className={more ? 'card card-height' : 'card'}>
      <div className="left">
        <strong>常见问题</strong>
        <div className="signser">
          <img src={signser} alt="" />
        </div>
      </div>
      <div className="right">
        <li className="li-1" onTouchStart={() => onTouchStart('怎么添加员工报销', '30001630384')} onTouchMove={onTouchMove}>
          <span>1.怎么添加员工报销？</span>
          <div className="icon-right">
            <img src={IconRight} alt="" />
          </div>
        </li>
        <li onTouchStart={() => onTouchStart('设置审批流的时候，无法添加其为付款人', '30001630389')} onTouchMove={onTouchMove}>
          <span>2.设置审批流的时候，无法添加其为付款人？</span>
          <div className="icon-right">
            <img src={IconRight} alt="" />
          </div>
        </li>
        <li onTouchStart={() => onTouchStart('费用类型如何添加？', '30001630848')} onTouchMove={onTouchMove}>
          <span>3.费用类型如何添加？</span>
          <div className="icon-right">
            <img src={IconRight} alt="" />
          </div>
        </li>
        <li className={more ? '' : 'li-4'} onTouchStart={() => onTouchStart('人员离职，之前负责的合同怎么移交给他人', '30001630846')} onTouchMove={onTouchMove}>
          <span>4.人员离职，之前负责的合同怎么移交给他人</span>
          <div className="icon-right">
            <img src={IconRight} alt="" />
          </div>
        </li>
        <li className={more ? '' : 'none'} onTouchStart={() => onTouchStart('付款是真实支付出去的还是只是记录？', '30001630381')} onTouchMove={onTouchMove}>
          <span>5.付款是真实支付出去的还是只是记录？</span>
          <div className="icon-right">
            <img src={IconRight} alt="" />
          </div>
        </li>
        <li className={more ? '' : 'none'} onTouchStart={() => onTouchStart('创建人、负责人和跟进人有什么不同？', '30001626844')} onTouchMove={onTouchMove}>
          <span>6.创建人、负责人和跟进人有什么不同？</span>
          <div className="icon-right">
            <img src={IconRight} alt="" />
          </div>
        </li>
        <li className={more ? '' : 'none'} onTouchStart={() => onTouchStart('新建应付合同成功之后 为什么在“应付合同”里面看不见', '30001630847')} onTouchMove={onTouchMove}>
          <span>7.新建应付合同成功之后 为什么在“应付合同”里面看不见？</span>
          <div className="icon-right">
            <img src={IconRight} alt="" />
          </div>
        </li>
        <li className={more ? '' : 'none'} onTouchStart={() => onTouchStart('没有合同的付款怎么付？', '30001630382')} onTouchMove={onTouchMove}>
          <span>8.没有合同的付款怎么付？</span>
          <div className="icon-right">
            <img src={IconRight} alt="" />
          </div>
        </li>
      </div>
      <div className={more ? 'none' : 'filter'}> </div>
      <div className="look-more" onTouchStart={getMore}>{more ? '点击收起' : '查看更多'}</div>
    </div>
  );
}
