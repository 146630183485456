/*
 * @Author: your name
 * @Date: 2022-01-25 09:47:43
 * @LastEditTime: 2022-01-25 18:21:11
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \Signser-web\src\containers\MobileQues\index.js
 */
/*eslint-disable*/
// 移动追问点击组件
import React from 'react';
import PropTypes from 'prop-types';
import '@/style/MobileQues.scss';
import MobileAccounts from '../MobileAccounts';
import MobileMoneyDetail from '../MobileMoneyDetail';

export default function MobileQues({ msg, nextPageType, allAccountCallback, appendMessage }) {
    MobileQues.propTypes = {
        msg: PropTypes.object,
        nextPageType: PropTypes.string,
        allAccountCallback: PropTypes.func,
        appendMessage: PropTypes.func,
    };
    let value = '';
    if (msg.info?.list || msg.info?.info) {
      switch (nextPageType) {
        case 'Text':
          value = '';
          break;
        case 'MobileAccounts':
          value = <MobileAccounts msg={msg} allAccountCallback={allAccountCallback} appendMessage={appendMessage} />
          break;
        case 'MobileMoneyDetail':
          value = <MobileMoneyDetail msg={msg} />
          break;
        default:
          break;
      }
    } 

    return (
      <div className="MobileQues">
          {/* eslint-disable-next-line */}
        <header id={msg._id} className="header" dangerouslySetInnerHTML={{ __html: msg.title }} />
        {msg.info?.list || msg.info?.info ? value : ''}
      </div>
    );
}
