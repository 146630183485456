/*eslint-disable*/
import jianshe from '@/images/jianshe.png';
import zhaoshang from '@/images/zhaoshang.png';
import zg from '../images/phone/zg.png';
import kf from '../images/phone/kf.png';
import jck from '../images/phone/jck.png';
import gd from '../images/phone/gd.png';
import hx from '../images/phone/hx.png';
import gs from '../images/phone/gs.png';
import jt from '../images/phone/jt.png';
import ms from '../images/phone/ms.png';
import pa from '../images/phone/pa.png';
import yz from '../images/phone/yz.png';
import qn from '../images/phone/qn.png';
import pufa from '../images/phone/pufa.png';
import rm from '../images/phone/rm.png';
import xy from '../images/phone/xy.png';
import zjmt from '../images/phone/zjmt.png';
import zx from '../images/phone/zx.png';
import yinhangka from '@/images/yinhangka.png';

export default function bankName (str) {
    if (str?.indexOf('建设') >= 0) {
        return jianshe;
    } if (str?.indexOf('招商') >= 0) {
        return zhaoshang;
    } if (str?.indexOf('中国银行') >= 0) {
        return zg;
    } if (str?.indexOf('开发') >= 0) {
        return kf;
    } if (str?.indexOf('进出口') >= 0) {
        return jck;
    } if (str?.indexOf('光大') >= 0) {
        return gd;
    } if (str?.indexOf('华夏') >= 0) {
        return hx;
    } if (str?.indexOf('工商') >= 0) {
        return gs;
    } if (str?.indexOf('交通') >= 0) {
        return jt;
    } if (str?.indexOf('民生') >= 0) {
        return ms;
    } if (str?.indexOf('平安') >= 0) {
        return pa;
    } else if (str?.indexOf('邮政') >= 0) {
        return yz;
    } else if (str?.indexOf('秦农') >= 0) {
        return qn;
    } else if (str?.indexOf('浦发') >= 0) {
        return pufa;
    } else if (str?.indexOf('中国人民') >= 0) {
        return rm;
    } else if (str?.indexOf('兴业') >= 0) {
        return xy;
    } else if (str?.indexOf('浙江民泰商业') >= 0) {
        return zjmt;
    } else if (str?.indexOf('中信') >= 0) {
        return zx;
    } else {
        return yinhangka;
    } 
}