/* eslint-disable prefer-const */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/*
 * pc端应付发票
 * @Author: your name
 * @Date: 2022-04-14 18:17:47
 * @LastEditTime: 2022-07-28 10:53:38
 * @LastEditors: 张立鹏 2475171028@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \signser-web\src\containers\Contacts\index.js
 */

import React, { useState } from 'react';
// eslint-disable-next-line no-unused-vars
import { Pagination, Tooltip } from 'antd';

import PropTypes from 'prop-types';
import 'moment/locale/zh-cn';
import './receivableOrPayable.scss';
import { useMount } from '../../../utils/hooks';
import { getIconImg, initArr, lebStatusObj, updMobileDrownFlag } from './utils/index';
// eslint-disable-next-line
import request from '@/utils/request';
import loading from '@/images/loading.png';
import drownImg from '@/images/drown_1124.png';
import tipsImg from '@/images/tips-bill_20220426.png';

export default function PayableBill({ msg }) {
  PayableBill.propTypes = {
    msg: PropTypes.object,
  };

  let [content, setContent] = useState([]); // 接口返回的list
  const [pageSatus, setPageStatus] = useState(true);
  const [current, setCurrent] = useState(msg.info?.current_page || 1); // 当前页数
  const [prePage, setPrePage] = useState(msg.info?.per_page || 3); // 每页展示的条数 
  const [totalPage, setTotalPage] = useState(msg.info?.total || 0); // 一共多少页
  const [loadingStatus, setLoadingStatus] = useState(false);
  // console.log(msg, 'payable'); 
  useMount(() => {
    const arr = initArr(msg.info?.list || []);
    setContent(arr);
    let timer;
    if (pageSatus) {
      timer = setTimeout(() => {
        Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollTop = Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollHeight;
      }, 300);
      return () => {
        clearTimeout(timer);
      };
    }
    return () => {
      // eslint-disable-next-line
      timer && clearTimeout(timer);
    };
  });

  function getDateList(perPage, currentPage) {
    setLoadingStatus(true);
    const params = {
      ...msg.info.parameter,
      per_page: perPage,
      current_page: currentPage,
      // per_page: 5,
      // current_page: 1,
      // 费用发票/报销发票/合同发票/其他发票
      // bill_leb: JSON.stringify(['报销发票']),
      // date: JSON.stringify(['2000-10-01/2025-10-01']),
    };
    request.instanceZhangkuan.get(`${process.env.REACT_APP_ZK}/${msg.info.api}`, {
      params,
    }).then((res) => {
      const arr1 = initArr(res.info.list || []);
      // console.log(arr1, 'res', res);
      msg.info.list = arr1;
      msg.info.current_page = res.info.current_page || 1;
      msg.info.per_page = res.info.per_page || 3;
      setTotalPage(res.info.total);
      setCurrent(res.info.current_page || 1);
      setPrePage(res.info.per_page || 3);
      setContent(arr1);
      setLoadingStatus(false);
    });
  }

  // 切换页码
  function onChangePageSize(page) {
    setCurrent(page);
    setPageStatus(false);
    getDateList(prePage, page);
  }

  // icon 展示数据
  async function handleShowList(index, flag) {
    let requestArr = [];
    if (!content[index].requestFlag) {
      setLoadingStatus(true);
      // 请求接口 超过3条
      const params = {
        contract_ids: content[index].relation_ids,
        bill_id: content[index].bill_id,
      };
      const getApiInfo = await request.instanceZhangkuan.get(`${process.env.REACT_APP_ZK}/api/ap/bill/contract`, { params });
      requestArr = getApiInfo?.info?.list;
      setLoadingStatus(false);
    }
    requestArr = Array.isArray(requestArr) ? requestArr : [];
    updContentContracts(index, flag, requestArr);
  }

  // 请求接口更新content
  function updContentContracts(index, flag, res = []) {
    content = updMobileDrownFlag(content, index, flag, res);
    msg.info.current_page = current;
    msg.info.per_page = prePage;
    msg.info.total = totalPage;
    msg.info.list = content;
    setContent([...content]);
  }

  return (
    <div className={loadingStatus ? 'receivableBill v1123_relative' : 'receivableBill'}>
      <div className={loadingStatus ? 'mask' : 'none'} />
      <div className={loadingStatus ? 'loading' : 'none'}>
        <img src={loading} alt="" />
      </div>
      <div className="billList">
   
        <div className="item-main">
          {
            content.map((item, index1) => (
              // eslint-disable-next-line react/no-array-index-key
              <div className="content-item" key={index1}>
                <div className="item-title txt-ellipsis cursorPointer tooltipFontWeight400Size12">
                  { lebStatusObj.noFee.includes(item.leb) ? (
                    <Tooltip placement="top" getPopupContainer={(triggerNode) => triggerNode.parentNode} title={item.supplier_name || '-'}>
                      <span>{item.supplier_name || '-'}</span>
                    </Tooltip>
                  ) : (
                    lebStatusObj.fee.includes(item.leb) ? (
                      <>
                        <Tooltip placement="top" getPopupContainer={(triggerNode) => triggerNode.parentNode} title={`${item.relation_type} - ${item.payment_title} | ${item.payment_number} ` || '-'}>
                          <span>{item.relation_type}</span>
                          <span>-</span>
                          <span>{item.payment_title}</span>
                          <span className="ml5">|</span>
                          <span>{item.payment_number}</span>
                        </Tooltip>
                      </>
                    ) : ''
                  )}  
                </div>
                <div className="item-content">
                  <div className={lebStatusObj.fee.includes(item.leb) ? 'txt-des txt-des_noBoder' : 'txt-des'}>
                    <div className="des-item V2310billMain">
                      <div className="icon-txt item-supplier txtWordBreakAll">
                        <img src={getIconImg(item.bill_type)} alt="" />
                        <div>{item.bill_type}</div>
                      </div>
                      <div className="item-contract_money mL8 txt-ellipsis cursorPointer tooltipFontWeight400Size12">
                        <Tooltip placement="top" getPopupContainer={(triggerNode) => triggerNode.parentNode} title={`¥  ${item.billing_money?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}` || '-'}>
                          <span className="v2210_moneyUnitMR3">¥</span> 
                          {`${item.billing_money?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                        </Tooltip>
                      </div>
                      <div className="item-yingfu_money mL8 txt-ellipsis cursorPointer tooltipFontWeight400Size12">
                        <Tooltip placement="top" getPopupContainer={(triggerNode) => triggerNode.parentNode} title={`¥  ${item.tax?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} （${item.tax_rate}）` || '-'}>
                          <span className="v2210_moneyUnitMR3">¥</span> 
                          {`${item.tax?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                          （
                          {item.tax_rate}
                          ）
                        </Tooltip>
                      </div>
                    </div>
                    <div className="des-item des-item_title V2310billMain">
                      <div className="item-supplier txtWordBreakAll">
                        {item.billing_number}
                        <span className="ml5">|</span>
                        {item.billing_date}
                      </div>
                      <div className="item-contract_money mL8">发票金额</div>
                      <div className="item-yingfu_money mL8">税额（税率）</div>
                    </div>
                  </div>
                  {
                    // 报销
                    lebStatusObj.fee.includes(item.leb) ? '' : (
                      <div className="link-list">
                        <div className="link-title">
                          <span>
                            { lebStatusObj.contract.includes(item.leb) ? '关联合同' : '关联项目'}
                            ：
                          </span>
                        </div>
                        { 
                          lebStatusObj.contract.includes(item.leb) ? (
                            <div className="link-content">
                              {
                                // eslint-disable-next-line max-len
                                ((item.drownFlag ? item.contracts : item.contracts_list) || []).map((listItem, listIndex) => (
                                    // eslint-disable-next-line react/no-array-index-key
                                    // eslint-disable-next-line react/jsx-indent
                                    <div className="link-item" key={listIndex}>
                                      <div>
                                        <span>
                                          { listItem.contract_name }
                                          <span className="ml5">|</span>
                                        </span>
                                        <span>
                                          {
                                            // eslint-disable-next-line max-len
                                            msg.info.parameter.department_name || msg.info.parameter.is_my_department 
                                            // eslint-disable-next-line operator-linebreak
                                            ?
                                            (
                                              <span>
                                                <span>
                                                  所属部门：
                                                  {listItem.depart_name}
                                                </span>
                                              </span>
                                            ) : (
                                              <span>
                                                <span>
                                                  经办人：
                                                  {listItem.followup_name}
                                                </span>
                                              </span>
                                            )

                                          }
                                          <span className="ml5">|</span>
                                        </span>
                                        <span>
                                          发票金额：
                                          <span className="v2210_moneyUnitMR3">¥</span> 
                                          {`${listItem.contract_billing_money?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                                        </span>
                                      </div>
                                      {/* 子项 */}
                                      {
                                        Array.isArray(listItem.children) ? (
                                          listItem.children.map((childItem, childIndex) => (
                                            <div className="item-child" key={childIndex}>
                                              <div>
                                                <span>{childItem.contract_number}</span>
                                                <span className="ml5" />
                                                <span>发票金额：</span>
                                                <span>
                                                  <span className="v2210_moneyUnitMR3">¥</span> 
                                                  {`${listItem.contract_billing_money?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                                                </span>
                                              </div>
                                            </div>
                                          ))
                                        ) : ''
                                      }
                                    </div>
                                ))
                              }
                              {/* 展示提示信息 */}
                              {
                                item.showDrownImg && item.drownFlag && item.relation_count > 10 ? (
                                  <div className="show-tips">
                                    <img src={tipsImg} alt="" />
                                    <div>
                                      其余
                                      {item.relation_count - item.contracts.length}
                                      个关联合同可前往账款管家查看
                                    </div>
                                  </div>
                                ) : ''
                              }                                 
                              {
                                item.showDrownImg ? (<div className={item.drownFlag ? 'showDrownImg showDrownImg-up' : 'showDrownImg'} onClick={() => { handleShowList(index1, item.drownFlag); }}><img src={drownImg} alt="" /></div>) : ''
                              }
                            </div>
                          ) : (
                            <div className="link-content">
                              <div className="link-item">
                                <span>{item.project_name}</span>
                                <span className="ml5">|</span>
                                <span>
                                  {
                                    // eslint-disable-next-line max-len
                                    msg.info.parameter.department_name || msg.info.parameter.is_my_department
                                    // eslint-disable-next-line operator-linebreak
                                    ?
                                    (
                                      <span>
                                        <span>
                                          所属部门：
                                          {item.depart_name}
                                        </span>
                                      </span>
                                    ) : (
                                      <span>
                                        <span>
                                          项目成员：
                                          {item.project_members}
                                        </span>
                                      </span>
                                    )
                                  }
                                </span>
                                <span className="ml5">|</span>
                                <span>
                                  发票金额：
                                  <span className="v2210_moneyUnitMR3">¥</span> 
                                  {`${item.billing_money?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                                </span>
                              </div>
                            </div>
                          )
                        }
                      </div>
                    )
                  }
                </div>
              </div>
            ))
          }
          {
              Number(totalPage) > msg.info.per_page 
              ? (
                <Pagination current={Number(current)} hideOnSinglePage pageSize={Number(prePage)} onChange={(page, pageSize) => onChangePageSize(page, pageSize)} className="v1124_pageinationPC" size="small" showSizeChanger={false} total={Number(totalPage)} />
              ) : ''
          }
        </div>

      </div>

    </div>
  );
}
