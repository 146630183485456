/*
钉钉语音说话太短提示
 * @Author: your name
 * @Date: 2021-10-25 09:23:42
 * @LastEditTime: 2022-01-25 18:22:38
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \signser\src\containers\MobileTimeShort\index.js
 */
import React from 'react';
import '../../style/mobileTimeShort.scss';
import PropTypes from 'prop-types';

export default function MobileTimeShort({ mobileTimeShortStatus }) {
    MobileTimeShort.propTypes = {
        mobileTimeShortStatus: PropTypes.bool,
    };
    return (
      <div className={mobileTimeShortStatus ? 'mobile-time-short' : 'none'}>
        <div className="warn"> </div>
        <div className="time-short">说话时间太短</div>
      </div>
    );
}
