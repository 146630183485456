/*
移动端查所有账户组件
 * @Author: your name
 * @Date: 2021-12-06 17:51:04
 * @LastEditTime: 2022-01-25 18:15:46
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \Signser-web\src\containers\MobileAllAccounts\index.js
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '@/style/mobileAllAccounts.scss';
import wei from '@/images/wei@2x.png';
import xianjin from '@/images/xianjin@2x.png';
import zhi from '@/images/zhi@2x.png';
import qita from '@/images/qita.png';
import yinhangka from '@/images/yinhangka.png';
// eslint-disable-next-line import/extensions
import { accountList } from '@/apis/http';
import { useMount, useScrollBottom } from '../../utils/hooks';
import Robot from '@/images/robot_logo.png';

let startAllAccount = false;
export default function MobileAllAccounts({ allAccountCallback, appendMessage, msg }) {
  MobileAllAccounts.propTypes = {
    allAccountCallback: PropTypes.func,
    appendMessage: PropTypes.func,
    msg: PropTypes.object,
  };
  useScrollBottom();
  const [accounts, setAccounts] = useState([
    {
      icon: yinhangka,
      leb_name: '银行账户',
      leb: '1',
      leb_num: 0,
      link: '/api/ap/account/list',
    },
    {
      icon: xianjin,
      leb_name: '现金账户',
      leb: '2',
      leb_num: 0,
      link: '/api/ap/account/list',
    },
    {
      icon: zhi,
      leb_name: '支付宝账户',
      leb: '3',
      leb_num: 0,
      link: '/api/ap/account/list',
    },
    {
      icon: wei,
      leb_name: '微信账户',
      leb: '4',
      leb_num: 0,
      link: '/api/ap/account/list',
    },
    {
      icon: qita,
      leb_name: '其他账户',
      leb: '5',
      leb_num: 0,
      link: '/api/ap/account/list',
    },
  ]);
  const [time, setTimer] = useState(null);
  useMount(() => {
    const list = msg?.info.list;
    const list2 = accounts;
    if (list && list.length) {
      list.forEach((item) => {
        list2[item.leb - 1].leb_num = item.leb_num;
      });
      setAccounts([...list2]);
    }
  });

  function onTouchMove() {
    startAllAccount = true;
  }
  function onTouchStart(number, leb) {
    startAllAccount = false;
    const timeOut = setTimeout(() => {
      if (!startAllAccount) {
        handleAccount(number, leb);
      }
    }, 100);
    setTimer(timeOut);
  }

  useMount(() => {
    clearTimeout(time);
  });
  function handleAccount(number, leb) {
    if (number === 0) return;
    allAccountCallback();
    accountList({
      ...msg.info.parameter,
      leb: leb.toString(),
      per_page: '3',
      current_page: '1',
    }).then((res) => {
      appendMessage({ text: res }, 'left', 'Mobile', Robot, true, 'MobileAccounts');
    });
  }
  return (
    <div className="flex mobileAllAccountsList">
      {
        accounts.length && accounts.map((element, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className="mobileAllAccounts" key={index} onTouchStart={() => onTouchStart(element.leb_num, element.leb)} onTouchMove={onTouchMove}>
            <div style={{ display: 'flex' }}>
              <div style={{ width: 'calc(32rem/75)', height: 'calc(32rem/75)', marginRight: 'calc(8rem/75)' }}>
                <img src={element.icon} alt="" />
              </div>
              {element.leb_name}
            </div>
            <div>
              {element.leb_num}
              个
            </div>
          </div>
        ))
      }
    </div>
  );
}
