/* eslint-disable react/no-array-index-key */
/* eslint-disable array-callback-return */
/*
 * pc端查审批
 * @Author: your name
 * @Date: 2022-03-24 18:17:47
 * @LastEditTime: 2022-04-13 16:10:00
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \signser-web\src\containers\Contacts\index.js
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tabs } from 'antd';
import 'moment/locale/zh-cn';
import '@/style/myApproval.scss';
import { useMount } from '../../utils/hooks';
import { myApprovalNavList } from '../../utils/utils';
import MyApprovalList from './MyApprovalList';
// eslint-disable-next-line
import request from '@/utils/request';
import loading from '@/images/loading.png';

const { TabPane } = Tabs;

export default function MyApproval({ msg, id }) {
  MyApproval.propTypes = {
    msg: PropTypes.object,
    id: PropTypes.string,
  };
  // 修改total
  const changeNavTotal = (val, total) => {
    const arr = tabData.map((el) => {
      if (el.state === val) el.total = total;
      return el;
    });
    setTabsData(arr);
  };
  const initState = msg.info?.parameter.state || '待审批';
  const tabData = myApprovalNavList(msg, changeNavTotal);
  const [pageSatus, setPageStatus] = useState(true);
  const [tabsData, setTabsData] = useState(tabData);
  const [tabsActiveKey, setTabsActiveKey] = useState(msg.info.parameter.state || '待审批'); // nav展示索引
  const [loadingStatus, setLoadingStatus] = useState(false);
  // 请求三个列表参数
  useMount(() => {
    // pending_count 待审批数量 refuse_count 驳回数量 pass_count 	通过数量
    let currentStateArr = [
      { state: '待审批', index: 0 },
      { state: '已驳回', index: 1 },
      { state: '已通过', index: 2 },
    ]; // 存储nav
    const arr = tabsData;
    currentStateArr = currentStateArr.filter((el) => el.state !== initState);
    const params = {
      per_page: msg.info.per_page || '5',
      current_page: msg.info.current_page || 1,
      ...msg.info.parameter,
    };
    if (Array.isArray(currentStateArr)) {
      currentStateArr.map(async (el) => {
        const item = await request.instanceZhangkuan.get(`${process.env.REACT_APP_ZK}/${msg.info.api}`, { params: { ...params, state: el.state } });
        arr[el.index].msg = { ...arr[el.index].msg, ...item };
      });
    }
    setTabsData(arr);
  });
  useMount(() => {
    let timer;
    if (pageSatus) {
      timer = setTimeout(() => {
        Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollTop = Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollHeight;
      }, 300);
      return () => {
        clearTimeout(timer);
      };
    }
    return () => {
      // eslint-disable-next-line
      timer && clearTimeout(timer);
    };
  });

  // 切换nav 
  function changeNav(key) {
    setTabsActiveKey(key);
    setLoadingStatus(false);
    setPageStatus(false);
  }

  return (
    <div className={loadingStatus ? 'myApproval v1123_relative' : 'myApproval'}>
      <div className={loadingStatus ? 'mask' : 'none'} />
      <div className={loadingStatus ? 'loading' : 'none'}>
        <img src={loading} alt="" />
      </div>
      <Tabs className="tabs-main" activeKey={tabsActiveKey} onChange={(key) => changeNav(key)}>
        { 
          tabsData.map((ele) => (
            <TabPane tab={ele.total > 99 ? (`${ele.state}(99+)`) : `${ele.state}(${ele.total})`} key={ele.state}>
              <MyApprovalList msg={ele.msg} id={id} activeKey={tabsActiveKey} />
            </TabPane>
          ))
        }
      </Tabs>
    </div>
  );
}
