/*
pc端Knowledge组件
 * @Author: your name
 * @Date: 2021-10-27 10:09:27
 * @LastEditTime: 2022-04-27 16:55:49
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \signser\src\containers\Knowledge\index.js
 */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import '@/style/knowledge.scss';

export default function Knowledge({ msg, pcSend }) {
    const [showImg, setShowImg] = React.useState(false); // 是否展示大图
    const [bigUrl, setBigUrl] = React.useState(''); // 大图的url
    Knowledge.propTypes = {
        msg: PropTypes.object,
        pcSend: PropTypes.func,
    };
    useEffect(() => {
      const div = document.getElementById(msg._id);
      div.innerHTML = msg.content.text;
      const timer = setTimeout(() => {
        Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollTop = Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollHeight;
      }, 300);
      return () => {
        clearTimeout(timer);
      };
  }, []);
    // 展示图片大图
    function bigImg(e) {
        if (e.target.tagName === 'IMG') {
            setShowImg(true);
            setBigUrl(e.target.src);
        }
    }
    // 取消图片大图
    function cancelMask(e) {
        if (e.target.tagName === 'DIV') {
            setShowImg(false);
        }
    }

    // 发送消息
    function send(title) {
      pcSend(title);
    }

    return (
      <div className="knowledges">
        <div className={showImg ? 'mask' : 'none'} onClick={(e) => cancelMask(e)}>
          <div className="bigUrl">
            <div />
            <img alt="" src={bigUrl} />
          </div>
        </div>
        <div className="knowledge">
          <div id={msg._id} onClick={(e) => bigImg(e)} /> 
          {
            msg.content && Array.isArray(msg.content.RelatedKnowledges) ? (
              <>
                <div style={{ color: '#909399', fontSize: '12px', marginTop: '6px' }}>猜您可能还想了解</div>
                {
                  msg.content.RelatedKnowledges.map((el, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div style={{ color: '#3377FF', cursor: 'pointer', fontSize: '12px' }} key={index} onClick={() => send(el.Title)}>{el.Title}</div>
                  ))
                }
              </>
            ) : ''
          }
        </div>
        
      </div>
    );
}
