/*
移动问号组件
 * @Author: your name
 * @Date: 2021-10-28 18:26:23
 * @LastEditTime: 2022-01-25 18:19:56
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \Signser-web\src\containers\MobileNav\index.js
 */
import React from 'react';
import PropTypes from 'prop-types';
import '@/style/mobile-nav.scss';
// eslint-disable-next-line import/extensions
import { tips } from '@/apis/http.js';

// eslint-disable-next-line no-unused-vars
export default function MobileNav({ tipStatus, setTipStatus }) {
    MobileNav.propTypes = {
      tipStatus: PropTypes.bool,
      setTipStatus: PropTypes.func,
    };
    const [HTML, setHTML] = React.useState('');
    function getHTML() {
      tips().then((res) => {
        if (res.function_description_wap) {
          setHTML(res.function_description_wap);
        } else {
          setHTML('无内容');
        }
      });
    }
    React.useEffect(() => {
      getHTML();
    }, []);
    return (
      <div className={tipStatus ? 'mobile-nav' : 'none'}>
        <div className="mask" onTouchStart={() => setTipStatus(false)}>
          {/* eslint-disable-next-line */}
          <div onTouchStart={(e) => e.stopPropagation()} className={tipStatus ? '' : 'none'}  dangerouslySetInnerHTML={{ __html: HTML }} />
        </div>
      </div>
    );
}
