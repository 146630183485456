/* eslint-disable max-len */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable prefer-const */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/*
 * 预算提醒
 * @Author: your name
 * @Date: 2022-04-14 18:17:47
 * @LastEditTime: 2022-07-14 14:41:19
 * @LastEditors: 张立鹏 2475171028@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \signser-web\src\containers\Contacts\index.js
 */

import React, { useState } from 'react';
import { Switch, Tooltip } from 'antd';
import { toast } from '@chatui/core';
import PropTypes from 'prop-types';

import 'moment/locale/zh-cn';
// eslint-disable-next-line no-unused-vars
import { CaretDownOutlined } from '@ant-design/icons';
import './index.scss';
import { useMount } from '../../../utils/hooks';
import { setRemindPoint } from './utils/index';
// eslint-disable-next-line
import request from '@/utils/request';
// import loading from '@/images/loading.png';
import contractTipImg from '@/images/contract-tips_v2300.png';

// eslint-disable-next-line no-unused-vars
export default function RemindSetIndex({ msg, pcSend }) {
  RemindSetIndex.propTypes = {
    msg: PropTypes.object,
    pcSend: PropTypes.func,
  };
  // console.log(msg);
  // eslint-disable-next-line no-unused-vars
  let [content, setContent] = useState(msg.info?.list || []); // 接口返回的list
  // eslint-disable-next-line no-unused-vars
  const [pageSatus, setPageStatus] = useState(true);
  // eslint-disable-next-line no-unused-vars
  // const [loadingStatus, setLoadingStatus] = useState(false);
  useMount(() => {
    let timer;
    if (pageSatus) {
      timer = setTimeout(() => {
        Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollTop = Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollHeight;
      }, 300);
      return () => {
        clearTimeout(timer);
      };
    }
    return () => {
      // eslint-disable-next-line
      timer && clearTimeout(timer);
    };
  });

  function change(e, index) {
    // eslint-disable-next-line no-unreachable
    // setLoadingStatus(true);
    const params = {
      ...msg.info.parameter,
      type: content[index].type,
      status: e ? 1 : 0,
    };
    request.instanceZhangkuan.post(`${process.env.REACT_APP_ZK}/api/remind/set`, {
      ...params,
    // eslint-disable-next-line no-unused-vars
    }).then((res) => {
      toast.success(e ? '已开启' : '已关闭');
      // setLoadingStatus(false);
      content[index].status = e ? 1 : 0;
      // msg.info.list = res.info.list;
      setContent([...content]);
    });
  }

  return (
    <div className="v2400-RemindSet v1123_relative">
      <div className="remindList" style={{ display: Array.isArray(content) && content.length ? 'block' : 'none' }}>
        <div className="item-main">
          {
            content.map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div className="content-item" key={index}>
                <div className="set-main">
                  <div className="name-tips">
                    <div>{item.name || '-'}</div>
                    <div className="V2300PCTooltipMain ML4">
                      <Tooltip arrowPointAtCenter placement="bottomRight" getPopupContainer={(triggerNode) => triggerNode.parentNode} title={item.tips}>
                        <img src={contractTipImg} className="tipsImg" />
                      </Tooltip>
                    </div>  
                  </div>
                  <div className="changeSwitch">
                    <Switch checked={item.status === 1} defaultChecked onChange={(e) => change(e, index)} />
                  </div>
                </div>
                {
                  [1, 2].includes(item.type) ? (
                    item.status === 1 ? (
                      <div className="budgetTipTxt" onClick={() => setRemindPoint('pc', pcSend, '批量设置项目预算')}>
                        <span>
                          设置预算
                          &nbsp;&gt;
                        </span>
                      </div>
                    ) : (
                      <div className="budgetTipTxt">
                        <span className="notSetTxt">
                          设置预算
                          &nbsp;&gt;
                        </span>
                      </div>
                    )
                  ) : ''
                }
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
}
