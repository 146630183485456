/*
 * @Author: your name
 * @Date: 2021-11-08 09:22:26
 * @LastEditTime: 2022-01-25 09:51:04
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \Signser-web\src\utils\hooks.js
 */
import { useEffect, useState, useRef, useCallback } from 'react';
import { toast } from '@chatui/core';
// 设置3分钟出现时间
export function useTime(params) {
    const [chatTime, setChatTime] = useState(params);
    return {
        add(Item) { 
            setChatTime(() => Item);
         },
        chatTime,
    };
}
// 滚动条下拉到最底部
export function useScrollBottom() {
    useEffect(() => {
        const timer = setTimeout(() => {
            Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollTop = Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollHeight;
          }, 300);
          return () => {
            clearTimeout(timer);
          };
    }, []);
}
// 实时检测网络状态
export function useNetWork() {
    useEffect(() => {
        window.addEventListener('online', () => {
        });
        window.addEventListener('offline', () => {
            toast.fail('网络已断开,请重新连接');
        });
    }, []);
}
// 防抖
export const useDebounce = (fn, delay, dep = []) => {
    const { current } = useRef({ fn, timer: null });
    useEffect(() => {
      current.fn = fn;
    }, [fn]);
  
    return useCallback(function f(...args) {
      if (current.timer) {
        clearTimeout(current.timer);
      }
      const callNow = !(current.timer);
      current.timer = setTimeout(() => {
        current.timer = null;
      }, delay);
      if (callNow) current.fn.call(this, ...args);
    }, dep);
  };
// 页面加载完成触发的生命周期
export const useMount = (callback, deps = []) => {
  useEffect(() => {
    callback();
  }, deps);
};

export const debonse = (fn) => {
  let time;
  return () => {
    // eslint-disable-next-line
    time && clearTimeout(time);
    setTimeout(() => {
      fn();
    }, 3000);
  };
};
