/*
 * @Author: your name
 * @Date: 2021-11-24 10:46:14
 * @LastEditTime: 2022-06-10 16:51:52
 * @LastEditors: 张立鹏 2475171028@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \Signser-web\src\index.js
 */
import React from 'react';
import ReactDOM from 'react-dom';
import qs from 'querystring';
import '@chatui/core/dist/index.css';
import './style/chatui-theme.scss';
import initReactFastclick from 'react-fastclick';
// eslint-disable-next-line
import dd from '@/utils/dd_authentication';
// import './index.css';
// eslint-disable-next-line import/extensions
import reportWebVitals from './reportWebVitals';
import App from './App';
import Context from './utils/context';

const device = qs.parse(window.location.search.slice(1)).device || '';

initReactFastclick();
const corpidList = ['ding8cb98c4333d693bb', 'ding70dec97438da4d84', 'ding1cc45d51ab38783735c2f4657eb6378f', 'ding6582bb21b5d3e342acaaa37764f94726', 'dingfd5853c9e7060c8bacaaa37764f94726', 'ding4ed33b5b785d130135c2f4657eb6378f', 'ding2d7f4131e20634febc961a6cb783455b']; // 给正式公司的一些公司开通vconsole
// eslint-disable-next-line eqeqeq
if (process.env.REACT_APP_VCONSOLE == 'true' || corpidList.includes(qs.parse(window.location.search.slice(1)).corpid)) {
  // eslint-disable-next-line no-new
  new window.VConsole();
}
// 钉钉鉴权
console.log(process.env.REACT_APP_DD, 'process.env.REACT_APP_DDprocess.env.REACT_APP_DDprocess.env.REACT_APP_DDprocess.env.REACT_APP_DDprocess.env.REACT_APP_DDprocess.env.REACT_APP_DD');
if (['Android', 'iPhone'].indexOf(device) >= 0 && navigator.userAgent.indexOf('DingTalk') > 0) {
  // eslint-disable-next-line
  process.env.REACT_APP_DD === 'true' ? dd() : ""; 
}
if (['Android', 'iPhone'].indexOf(device) >= 0) {
  import('./style/chatui-theme-mobile.css');
}
ReactDOM.render(
  <React.StrictMode>
    <Context.Provider value={device}>
      <App />
    </Context.Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
