/* eslint-disable no-nested-ternary */
/*
 * 客户供应商数量
 * @Author: your name
 * @Date: 2022-03-31 14:54:48
 * @LastEditTime: 2022-06-23 18:28:32
 * @LastEditors: 张立鹏 2475171028@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \signser-web\src\containers\MobileCustomerOrSuppliers\number.js
 */
import React, { useState } from 'react';
import '@/style/MobileCustomerOrSuppliers.scss';
import PropTypes from 'prop-types';
// import { useScrollBottom } from '../../utils/hooks';
import right from '@/images/icon-right.png';
import { useMount } from '../../utils/hooks';
// eslint-disable-next-line
import request from '@/utils/request';
import loading from '@/images/loading.png';

let start = false;
export default function MobileCustomerSuppliersContract({ msg }) {
  MobileCustomerSuppliersContract.propTypes = {
    msg: PropTypes.object,
  };
  let arr = Object.values(msg.info.list);
  const [force, setForce] = useState(null);
  const [pageSatus, setPageStatus] = useState(true);
  const [current, setCurrent] = useState(1);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [pickArr, setPickArr] = useState([]);
  
  useMount(() => {
    if (current > 1) {
      request.instanceZhangkuan.get(`${process.env.REACT_APP_ZK}/${msg.info.api}`, {
        params: {
          ...msg.info.parameter,
          per_page: 5,
          current_page: current,
        },
      }).then((res) => {
        setLoadingStatus(false);
        if (current < 3) {
          arr = [...arr, ...Object.values(res.info.list)];
          setForce(arr);
        } else {
          setForce([...force, ...Object.values(res.info.list)]);
        }
      });
    }
  }, [current]);

  useMount(() => {
    let timer;
    if (pageSatus) {
      timer = setTimeout(() => {
        Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollTop = Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollHeight;
      }, 300);
      return () => {
        clearTimeout(timer);
      };
    }
    return () => {
      // eslint-disable-next-line
      timer && clearTimeout(timer);
    };
  });

  // 下一页
  function nextPage() {
    start = false;
    const time = setTimeout(() => {
      if (!start) {
        if (pickArr.length) {
          setPickArr([]);
          return;
        }
        setLoadingStatus(true);
        setCurrent(current + 1);
        setPageStatus(false);
      }
      clearTimeout(time);
    }, 100);
  }

  // 收起
  function packUp() {
    start = false;
    const time = setTimeout(() => {
      if (!start) {
        setPickArr(force.slice(0, 5));
      }
      clearTimeout(time);
    }, 100);
  }

  function onTouchMove() {
    start = true;
  }
  return (
    <div className="contract-number_main" style={{ width: 'calc(540rem/75)' }}>
      {arr.length === 0 ? <div /> : (
        <div className="list-main" style={{ marginBottom: Number(msg.info.total) > 5 ? 'calc(52rem/75)' : '0' }}>
          {/* eslint-disable-next-line */}
          {(pickArr.length ? pickArr : force || arr).map((ele, index) => (
            // eslint-disable-next-line
            <div className="item-main" key={index}>
              <span>
                {index + 1} 
                、
              </span>
              {/* // 19: [客户/供应商]数量 20: [客户/供应商]联系方式 */}
              { /* eslint-disable-next-line no-prototype-builtins */ }
              <span>{ ele.hasOwnProperty('custom_name') ? ele.custom_name : ele.supplier_name}</span>
              <span className="MLR8">|</span>
              <span>
                负责人：
                {ele.name}
              </span>
            </div>
          ))}
        </div>
      )}
      <div className={Number(msg.info.total) > 5 ? 'mobile-filter-bottom' : 'none'}>
        <div className={current >= Number(msg.info.total_page) && pickArr.length === 0 ? '' : 'filter'} style={{ width: 'calc(540rem/75)' }} />
        <div className="flex ismore" style={{ height: 'calc(48rem/75)' }}>
          {/* eslint-disable-next-line */}
          <div className={current >= Number(msg.info.total_page) && pickArr.length === 0 ? 'none' : 'more'} onTouchStart={() => loadingStatus ? '' : nextPage()} onTouchMove={onTouchMove}>{loadingStatus ? <div className="loading"><img src={loading} alt="" /></div> : '查看更多'}</div>
          <div className={current > 1 && pickArr.length === 0 ? 'flex align-item left pick-style' : 'none'} style={{ position: 'absolute', right: 0 }} onTouchStart={packUp} onTouchMove={onTouchMove}>
            <div className="pack-up">
              收起
            </div>
            <div style={{ width: 'calc(28rem/75)', height: 'calc(28rem/75)' }}>
              <img src={right} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
