/*
 * @Author: your name
 * @Date: 2022-01-25 09:47:43
 * @LastEditTime: 2022-07-11 22:16:06
 * @LastEditors: 张立鹏 2475171028@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \Signser-web\src\utils\allow.js
 */
/*eslint-disable*/
// 判断是否让什么环境进入
export default function allow() {
  // return process.env.REACT_APP_ENV === 'cs' ? true :
  return ['cs', 'kf'].includes(process.env.REACT_APP_ENV) ? true :
  (navigator.userAgent.indexOf('DingTalk') > 0 || window.flutter_inappwebview ? true : false)
}
