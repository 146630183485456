/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
/*
pc端第一次进入页面是展示的样式
 * @Author: your name
 * @Date: 2021-10-25 14:46:25
 * @LastEditTime: 2022-07-08 14:37:48
 * @LastEditors: 张立鹏 2475171028@qq.com
 * @Description: In User Settings Edit
 * @FilePath: \signser\src\containers\TableData\index.js
 */
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Carousel } from 'antd';
// import { Carousel } from '@chatui/core';
import { getQuikQueryInfo } from '../../../utils/utils';
import { useMount } from '../../../utils/hooks';
import './index.scss';
import quickQueryV2300MobileTxt from '@/images/quickQueryV2300_mobileTxt.png';
import List from './list';

//  sendFAQ 直接点击发送
export default function MobileQuickQuery({ pcSend }) {
  MobileQuickQuery.propTypes = {
    // sendFAQ: PropTypes.func,
    pcSend: PropTypes.func,
  };

  // eslint-disable-next-line prefer-const
  const [current, setCurrent] = useState(1); // 当前页码
  // eslint-disable-next-line prefer-const
  let [content, setContent] = useState([]); // 渲染数据的list
  const [pageSatus, setPageStatus] = useState(true);
  const carouselRef = useRef(null); // 文本框聚焦

  useMount(() => {
    // console.log(msg, 'content');
    getQuikQueryInfo(setContent, 2);
    let timer;
    if (pageSatus) {
      timer = setTimeout(() => {
        Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollTop = Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollHeight;
      }, 300);
      return () => {
        clearTimeout(timer);
      };
    }
    return () => {
      // eslint-disable-next-line
      timer && clearTimeout(timer);
    };
  });

  // 切换页码
  function changePage(type) {
    setCurrent(type === 'next' ? current + 1 : current - 1);
    // eslint-disable-next-line no-unused-expressions
    type === 'next' ? carouselRef.current.next() : carouselRef.current.prev();
  }

  return (
    <div className="MobileV2300CarouselMain">
      <div className="queryImg">
        <img src={quickQueryV2300MobileTxt} />
      </div>
      
      {/* 内容部分 */}
      <div className="carousel-pannel">
        <Carousel ref={carouselRef} dots>
          {
            Array.isArray(content) && content.length ? (
              content.map((el, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div className="carouse-ct" key={index}>
                  <div className="ct-R">
                    {
                      Array.isArray(el) ? (
                        el.map((item, index1) => (
                          <>
                            <div className="top-line" />
                            <List pcSend={pcSend} msg={item} key={index1} />
                          </>
                        ))
                      ) : ''
                    }
                  </div>
                </div>
              ))
            ) : '' 
          }
        </Carousel>
      </div>
    </div>
  );
}
