/* eslint-disable import/prefer-default-export */
/*
 * @Author: rightAware 2475171028@qq.com
 * @Date: 2022-05-19 14:54:22
 * @LastEditors: 张立鹏 2475171028@qq.com
 * @LastEditTime: 2022-05-25 18:42:21
 * @FilePath: \signser-web\src\containers\v1.1.24_PC\bill\utils\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import _ from 'lodash';
// eslint-disable-next-line import/extensions
import request from '@/utils/request';
import specialBill from '@/images/special_bill_1124.png';
import ordinaryBill from '@/images/ordinary_bill_1124.png';
import otherBill from '@/images/other_bill_1124.png';
// 修改下拉图标展示
export const updMobileDrownFlag = (item, index, flag, newArr = []) => {
  if (Array.isArray(item)) {
    item = _.cloneDeep(item).map((el, i) => {
      if (index === i) {
        el.drownFlag = !flag;
        el.requestFlag = true;
        if (Array.isArray(el.receivables)) {
          // eslint-disable-next-line max-len
          el.receivables = el.receivables.length > 3 ? el.receivables : [...el.receivables, ...newArr];
        }
      }
      return el;
    });
  }
  return item;
};

// 格式化数组 合同 添加向下下拉图标
export const initArr = (item = []) => {
  let arr = Array.isArray(item) ? item : [];
  if (Array.isArray(arr)) {
    arr = arr.map((el) => {
      // el.requestFlag = true; // 默认不发送接口
      if (Array.isArray(el.receivables)) {
        el.receivables_list = el.receivables.slice(0, 3);
        el.showDrownImg = el.relation_count > 3;
        el.requestFlag = el.relation_count <= 3;
      }
      el.drownFlag = false;
      return el;
    });
  }
  return arr;
};

// 展示icon
// 增值税普通发票 增值税专用发票 增值税电子普通发票 增值税电子专用发票 其他
// eslint-disable-next-line import/prefer-default-export
export const getIconImg = (txt) => {
  let imgIcon = specialBill;
  switch (txt) {
    case '增值税普通发票':
      imgIcon = ordinaryBill;
      break;
    case '增值税专用发票':
      imgIcon = specialBill;
      break;
    case '增值税电子普通发票':
      imgIcon = ordinaryBill;
      break;
    case '增值税电子专用发票':
      imgIcon = specialBill;
      break;  
    case '其他':
      imgIcon = otherBill;
      break;    
    default:
      break;  
  }
  return imgIcon;
};

// 格式化数组 合同 添加向下下拉图标
export const initMobileArr = (item = []) => {
  let arr = Array.isArray(item) ? item : [];
  if (Array.isArray(arr)) {
    arr = arr.map((el) => {
      el.drownFlag = false; // 控制下拉
      el.requestFlag = true; // 控制是否请求接口
      if (Array.isArray(el.receivables) && el.relation_count > 3) {
        el.requestFlag = false;
      } 
      return el;
    });
  }
  return arr;
};

// 设置对话框底部按钮-查看明细
export const setChatFooter = (msg, appendMessage, setSlotChatFooter) => {
  // 查看开票详情明细---添加内容
  // eslint-disable-next-line no-redeclare
  // eslint-disable-next-line no-shadow
  function changeDetail(msg, value, appendMessage) {
    if (msg && msg.info) {
      const params = {
        ...msg.info.parameter,
        per_page: msg.info?.per_page,
        current_page: 1,
        only_receive: value,
        total: msg.info?.total,
      };
      request.instanceZhangkuan.get(`${process.env.REACT_APP_ZK}/${msg.info.api}`, {
        params,
      }).then((res) => {
        appendMessage({ text: JSON.stringify({ data: res, code: 0 }) }, 'left', 'text');
      });
    }
  }
  // 仅看有回款的 only_receive = 1 仅看没有回款的 only_receive = 0
  // eslint-disable-next-line no-inner-declarations
  function SlotFooter() {
    return (
      <div className="slotFooterMain-V2110">
        <div className="footerItem" onClick={() => changeDetail(msg, 1, appendMessage)}>仅看有回款的发票</div>
        <div className="footerItem" onClick={() => changeDetail(msg, 0, appendMessage)}>仅看没有回款的发票</div>
      </div>
    );
  }
  setSlotChatFooter(SlotFooter);
};
