/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/*
移动端组件都在这个容器下
 * @Author: your name
 * @Date: 2021-10-27 14:31:39
 * @LastEditTime: 2022-07-07 19:35:46
 * @LastEditors: 张立鹏 2475171028@qq.com
 * @Description: In User Settings Edit
 * @FilePath: \signser\src\containers\MobileText\index.js
 */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import qs from 'querystring';
import dd from 'dingtalk-jsapi';
import { toast } from '@chatui/core';
// eslint-disable-next-line import/extensions
import ChatFeedBackDown from '@/images/ChatFeedBackDown_2300.png';
// eslint-disable-next-line import/extensions
import ChatFeedBackUp from '@/images/ChatFeedBackUp_2300.png';
// eslint-disable-next-line import/extensions
import { isJSON, chatFeedBackFn } from '../../utils/utils';
// 条形图
import MobileBar from '../MobileBar';
// 折线图组件
import MobileLine from '../MobileLine';
// 查发票组件
import MobileTextTable from '../MobileTextTable';
// 逾期合同组件
import MobileTable from '../MobileTable';
// 到期合同组件
import MobileTableTwo from '../MobileTableTwo';
// 全部账户
import MobileAllAccounts from '../MobileAllAccounts';
// 账龄
import MobileAging from '../MobileAging';
// 查付款组件
import MobilePayment from '../MobilePayment';
// 指定类型账户
import MobileAccounts from '../MobileAccounts';
// 报销
import MobileRate from '../MobileRate';
// 账户流水
import MobileMoneyDetail from '../MobileMoneyDetail';
// 可点击(追问)的账户
import MobileDetailAccount from '../MobileDetailAccount';
// 刚进入页面的话术
import MobileFirstSentence from '../MobileFirstSentence';
// eslint-disable-next-line import/extensions
import { openLink } from '@/utils/dd_methods.js';
// 查合同应收 合同回款
import MobileContractMoney from '../MobileContractMoney';
// 查合同金额 数量
import MobileContractNumber from '../MobileContractNumber';
// 回款排名
import MobileReceivableRanking from '../MobileReceivableRanking';
// [客户/供应商]联系方式 20
import MobileCustomerSuppliersContract from '../MobileCustomerOrSuppliers/contract';
// [客户/供应商]数量 19
import MobileCustomerSuppliersNumber from '../MobileCustomerOrSuppliers/number';
// 判断 联系人 和 供应商  选择哪一个 is_inquiry
import MobileInquiryContent from '../MobileInquiryContent';
// 抄送我的审批17
import MobileMyCheckApproval from '../MobileMyApproval/check';
// 抄送我的审批18
import MobileMyMyApproval from '../MobileMyApproval/index';
// 21 应付发票
import MobilePayableBill from '../v1.1.24_Mobile/ReceivableOrPayable/payable';
// 23 应付发票
import MobileReceivableBill from '../v1.1.24_Mobile/ReceivableOrPayable/receivable';
// 无答案推荐 和 有答案推荐
import MobileRecommendIndex from '../v1.1.24_Mobile/recommend';
// 发票邮寄信息 27， 28
import MobileBillPostIndex from '../v1.1.24_Mobile/bill/post/index';
// 查应收发票缴税信息 25  查应收发票明细信息 26
import MobileBillTaxDetailIndex from '../v1.1.24_Mobile/bill/taxDetail/index';
// is_chart 30 查应收发票关联回款信息
import MobileBillReceivableIndex from '../v1.1.24_Mobile/bill/receivable';
// 预收款 is_chart 34
// eslint-disable-next-line import/extensions
import MobileArPrePaymentIndex from '../v1.1.24_Mobile/ar/prePayment/index';
// 应付合同金额/数量 33
import MobileApContractAmountorPay from '../v1.1.24_Mobile/ap/contractAmountorPay/index';
// 坏账31
import MobileArBaddebtContractIndex from '../v1.1.24_Mobile/ar/baddebt/index';
// 坏账子合同32
import MobileArBaddebtContractChild from '../v1.1.24_Mobile/ar/baddebt/list';
// 费用报销42
import MobileZKGJProjectBudgetRemind from '../v1.1.24_Mobile/zkgj/projectBudget/remind/index';
// 合同付款 43
import MobileZKGJProjectBudgetContract from '../v1.1.24_Mobile/zkgj/projectBudget/contract/index';
// 项目预算列表
import MobileProjectBudgetIndex from '../v1.1.24_Mobile/ac/projectBudgetIndex/index';
// 点赞点踩组件
import ChatFeedBackPCAndMobile from '../v1.1.24_PC/chatFeedBack/index';
// 自定义title
import CustomerTitlePCAndMobile from '../CustomerTitlePCAndMobile/index';
// 快捷查询
import MobileQuickQuery from '../v1.1.24_Mobile/quickQuery/index';
// 消息信息提醒
import MobileRemindSetIndex from '../v1.1.24_Mobile/remindSet';

import MobileStaff from '../MobileStaff';

// eslint-disable-next-line max-len
export default function MobileText({ msg, pcSend, failSend, allAccountCallback, appendMessage, sendFAQ, kfID, deleteMsg, textID, setIsShowFooterInput }) {
  const corpid = qs.parse(window.location.search.slice(1)).corpid || '';
  MobileText.propTypes = {
    msg: PropTypes.object,
    pcSend: PropTypes.func,
    failSend: PropTypes.func,
    appendMessage: PropTypes.func,
    allAccountCallback: PropTypes.func,
    sendFAQ: PropTypes.func,
    setIsShowFooterInput: PropTypes.func,
    kfID: PropTypes.string,
    deleteMsg: PropTypes.func,
    textID: PropTypes.string,
  };
  const [result, setResult] = React.useState({
    data: {},
  });
  const [load, setLoad] = React.useState(false);
  const [className, setClassName] = React.useState('');
   // 插入底部内容
   const [slotChatFooter, setSlotChatFooter] = React.useState('');
  useEffect(() => {
    setClassName(msg.status && load === false ? 'text-right-wrong' : ''); // status为true出现小红点
    if (load && msg.status) {
      setClassName('text-right-loading');
    }
    const id = document.getElementById(msg._id);
    if (!isJSON(msg.content.text)) {
      id.innerHTML = msg.content.text || '';
    } else {
      const resultObj = isJSON(msg.content.text) && JSON.parse(msg.content.text);
      setResult(resultObj);
      if (resultObj.code.toString().indexOf('422') === 0 || resultObj.code.toString().indexOf('400') === 0 || resultObj.code.toString().indexOf('401') === 0) {
        // 100 不修改title
        // eslint-disable-next-line no-empty
        if (resultObj && resultObj.data && resultObj.data.is_chart === 100) {} else { id.innerHTML = resultObj.msg || ''; }
      } else if (resultObj.code === 0) {
        // 100 不修改title
        // eslint-disable-next-line no-empty
        if (resultObj && resultObj.data && resultObj.data.is_chart === 100) {} else { id.innerHTML = resultObj.data.title || ''; }
      } else if (resultObj.code.toString().indexOf('402') === 0) {
        toast.fail(resultObj.msg);
      } else {
        toast.fail('检测不到登录信息，请重试');
      }
    }
  }, [msg]);
  useEffect(() => {
    if (kfID) {
      const msgTime = setTimeout(() => {
        clearTimeout(msgTime);
      }, 1000);
    }
  }, [kfID]);
  let content = '';
  // console.log(result.data, 'result.data');
  // 根据chat状态匹配不同的组件
  if (result.data.is_chart) {
    switch (result.data.is_chart) {
      case 100:
        // sendFAQ={sendFAQ}
        content = <MobileFirstSentence appendMessage={appendMessage} setIsShowFooterInput={setIsShowFooterInput} pcSend={pcSend} id={msg._id} />;
        break;
      case 2:
        content = <MobileTableTwo msg={result.data} />;
        break;
      case 1:
        content = <MobileTable msg={result.data} />;
        break;
      case 3:
        content = (
          <div id={msg.createdAt} style={{ padding: '0px' }}>
            <MobileBar msg={result.data} id={msg.createdAt} />
          </div>
        );
        break;
      case 4:
        content = (
          <div id={msg.createdAt} className="mobile-line" style={{ padding: '0px' }}>
            <MobileLine msg={result.data} id={msg.createdAt} />
          </div>
        );
        break;
      case 5:
        content = <MobileTextTable msg={result.data} />;
        break;
      case 6:
        content = (
          <div id={msg.createdAt} className="nopadding">
            <MobileBar msg={result.data} id={msg.createdAt} />
          </div>
        );
        break;
      case 7:
        content = <MobileAging msg={result.data} />;
        break;
      case 8:
        content = <MobileMoneyDetail msg={result.data} />;
        break;
      case 9:
        // eslint-disable-next-line max-len
        content = <MobileAllAccounts msg={result.data} appendMessage={appendMessage} allAccountCallback={allAccountCallback} />;
        break;
      case 10:
        // eslint-disable-next-line max-len
        content = <MobileAccounts msg={result.data} id={msg._id} appendMessage={appendMessage} allAccountCallback={allAccountCallback} />;
        break;
      case 11:
        // eslint-disable-next-line max-len
        content = <MobileDetailAccount msg={result.data} id={msg._id} appendMessage={appendMessage} allAccountCallback={allAccountCallback} />;
        break;
      case 12:
        content = <MobileRate msg={result.data} />;
        break;
      case 13:
        content = <MobilePayment msg={result.data} />;
        break;
      case 14:
        content = <MobileContractMoney msg={result.data} />;
        break;
      case 15:
        content = <MobileContractNumber msg={result.data} />;
        break;
      case 16:
        content = <MobileReceivableRanking msg={result.data} />;
        break;
      case 17:
        // 17抄送给我的审批
        content = <MobileMyCheckApproval msg={result.data} />;
        break;
      case 18:
        // 18 查看我的审批
        content = <MobileMyMyApproval msg={result.data} id={msg._id} />;
        break;
      case 19:
        // [客户/供应商]数量
        content = <MobileCustomerSuppliersNumber msg={result.data} />;
        break;  
      case 20:
        // [客户/供应商]联系方式
        content = <MobileCustomerSuppliersContract msg={result.data} />;
        break; 
      case 21:
        // 21 应付发票
        content = <MobilePayableBill msg={result.data} />;
        break;  
      case 23:
        // eslint-disable-next-line no-case-declarations
        const titleDom = document.getElementById(msg._id);
        if (titleDom) {
          titleDom.innerHTML = '';
        }
        // 23 应收发票
        // eslint-disable-next-line max-len
        content = <MobileReceivableBill setSlotChatFooter={setSlotChatFooter} msg={result.data} appendMessage={appendMessage} />;
        break; 
        case 25:
          // 查应收发票缴税信息
          content = <MobileBillTaxDetailIndex msg={result.data} />;
          break; 
        case 26:
          // 查应收发票明细信息
          content = <MobileBillTaxDetailIndex msg={result.data} />;
          break; 
      case 27:
        // 发票邮寄信息--- 有邮寄信息
        content = <MobileBillPostIndex msg={result.data} />;
        break;    
      case 28:
        // 发票邮寄信息--- 无邮寄信息
        content = <MobileBillPostIndex msg={result.data} />;
        break; 
      case 30:
        //  查应收发票关联回款信息
        // eslint-disable-next-line max-len
        content = <MobileBillReceivableIndex msg={result.data} appendMessage={appendMessage} setSlotChatFooter={setSlotChatFooter} />;
        break;   
      case 31:
        //  查坏账
        // eslint-disable-next-line max-len
        content = <MobileArBaddebtContractIndex msg={result.data} appendMessage={appendMessage} />;
        break; 
      case 32:
        //  查坏账子合同
        // eslint-disable-next-line max-len
        content = <MobileArBaddebtContractChild msg={result.data} />;
        break; 
      case 33:
        // 应付合同金额/数量
        content = <MobileApContractAmountorPay msg={result.data} />;
        break;    
      case 34:
        // 预收款
        content = <MobileArPrePaymentIndex msg={result.data} />;
        break;   
      case 35:
        //  查到期最多（跟到期应收的页面显示一致）msg.info.parameter.contract_status ==='到期'
        //  查逾期最多（跟逾期应收合同 is_chart: 1显示一致）msg.info.parameter.contract_status ==='逾期' 
        if (['逾期', '到期'].includes(result.data?.info?.parameter?.contract_status)) {
          content = <MobileTable msg={result.data} id={msg._id} />;
        }
        break;
      case 42:
        // 费用报销
        content = <MobileZKGJProjectBudgetRemind msg={result.data} />;
        break;  
      case 43:
        // 合同付款
        content = <MobileZKGJProjectBudgetContract msg={result.data} appendMessage={appendMessage} />;
        break;  
      case 44:
      // 合同付款子合同
      content = <MobileZKGJProjectBudgetRemind msg={result.data} />;
      break; 
      case 50:
        // 项目预算列表
        content = <MobileProjectBudgetIndex id={msg._id} msg={result.data} setSlotChatFooter={setSlotChatFooter} />;
        break;  
      case 52:
        // 消息信息提醒
        content = <MobileRemindSetIndex msg={result.data} pcSend={pcSend} />;
        break;      
      default:
        break;
    }
  }
  
  if (result.data.is_cs === 1) {
    // eslint-disable-next-line max-len
    content = <MobileStaff pcSend={pcSend} appendMessage={appendMessage} deleteMsg={deleteMsg} kfID={kfID} kfStatus={0} />;
  }
  // is_inquiry  客户 供应商 选择哪一个
  if (result.data.is_inquiry === 1) {
    content = <MobileInquiryContent msg={result.data} pcSend={pcSend} appendMessage={appendMessage} />;
  }

  // 自定义title
  if (result.data.customer_title === 1) {
    content = <CustomerTitlePCAndMobile device="mobile" msg={result.data} pcSend={pcSend} appendMessage={appendMessage} />;
  } 
  // 无答案推荐 和 有答案推荐
  if (result.data && result.data.recommendType && ['is_no_answer', 'is_yes_answer'].includes(result.data.recommendType)) {
    const titleDom = document.getElementById(msg._id);
    if (titleDom) {
      titleDom.innerHTML = '';
    }
    // eslint-disable-next-line max-len
    content = <MobileRecommendIndex msg={result.data} pcSend={pcSend} appendMessage={appendMessage} />;
  }
  // 关闭对话框
  if (result.data.is_close && result.data.is_close === 1) {
    // DOM 渲染完后退出
    const timeout = setTimeout(() => {
    clearTimeout(timeout);
    dd.biz.navigation.goBack();
    }, 1000);
  }
  
  // 快捷查询
  if (result?.data?.is_quick_query === 1) {
    content = <MobileQuickQuery pcSend={pcSend} />;
  }

  if (result.data?.is_open_cs === 1) {
    // eslint-disable-next-line max-len
    content = <MobileStaff pcSend={pcSend} setSlotChatFooter={setSlotChatFooter} msg={result.data} appendMessage={appendMessage} deleteMsg={deleteMsg} kfID={kfID} kfStatus={1} textID={textID} />;
  }
  // 查看更多的跳转链接
  // function onTouchStart() {
  //   const url = `${process.env.REACT_APP_ZKGJ}/${result.data.info.link}?corpid=${corpid}`;
  //   openLink(`dingtalk://dingtalkclient/action/openapp?corpid=${corpid}&container_type=work_platform&app_id=3716&redirect_type=jump&redirect_url=${encodeURIComponent(url)}`);
  // }
  // 点击小红点重新发送
  function onClick() {
    setLoad(true);
    failSend(msg.content.text, msg._id);
  }

  return (
    <>
      <div className={msg.position === 'left' ? result?.data?.is_quick_query === 1 ? 'mobile mobile-left V2300QuickQueryMobile' : 'mobile mobile-left' : 'mobile mobile-right'} style={{ maxWidth: result.data.is_chart && result.data.is_chart !== 100 ? 'calc(540rem/75)' : 'calc(608rem/75)' }}>
        <div>
          <div className={className} onClick={onClick} />
          <header id={msg._id} style={{ wordBreak: 'break-word', wordWrap: 'break-word', maxWidth: 'calc(540rem/75)' }} />
        </div>
        {content}
        {
          result.data.is_select
            ? (
              <div className={result.data.is_select ? 'buttons' : 'none'}>
                {(Array.isArray(result.data.info?.list) ? result.data.info?.list : []).map((res, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={index} className="button999" style={{ cursor: 'pointer' }} onClick={() => pcSend(res.text)}>
                    {index + 1}
                    、
                    {res.text}
                  </div>
                ))}
              </div>
            ) : ''
        }
        {
          result.data.is_chart >= 0 && result.data.is_chart !== 100 && msg.content.MessageId ? (
            <ChatFeedBackPCAndMobile device="mobile" MessageId={msg.content.MessageId} />
          ) : ''
        }
        {/* <footer className={result.data.is_link === 1 ? '' : 'none'}>
          <div className="line" />
          <div className="look-more" style={{ color: '#3377FF', fontSize: 'calc(24rem/75)' }} 
          onTouchStart={onTouchStart}>查看全部明细 &gt;</div>
        </footer> */}
      </div>
      {/* 对话框底部内容 */}
      {
        slotChatFooter ? (
          <>
            {slotChatFooter}
          </>
        ) : ''
      }
    </>
  );
}
