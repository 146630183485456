/*
移动端逾期合同组件
 * @Author: your name
 * @Date: 2021-10-26 09:18:41
 * @LastEditTime: 2022-07-25 14:03:07
 * @LastEditors: 张立鹏 2475171028@qq.com
 * @Description: In User Settings Edit
 * @FilePath: \signser\src\containers\MobileTable\index.js
 */
import React, { useState } from 'react';
import '@/style/mobile-table.scss';
import '@/style/mobile-table-two.scss';
import PropTypes from 'prop-types';
import { useMount } from '../../utils/hooks';
import right from '@/images/icon-right.png';
// eslint-disable-next-line
import request from '@/utils/request';
import loading from '@/images/loading.png';
import MobileOverdue from '../MobileOverdue';
import MobileExipre from '../MobileExipre';

let start = false;
export default function MobileTable({ msg }) {
  MobileTable.propTypes = {
    msg: PropTypes.object,
  };

  let arr = Object.values(msg.info?.list);
  const [force, setForce] = useState(null);
  const [pageSatus, setPageStatus] = useState(true);
  const [current, setCurrent] = useState(1);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [pickArr, setPickArr] = useState([]);
  useMount(() => {
    if (current > 1) {
      request.instanceZhangkuan.get(`${process.env.REACT_APP_ZK}/${msg.info.api}`, {
        params: {
          ...msg.info.parameter,
          per_page: 3,
          current_page: current,
        },
      }).then((res) => {
        setLoadingStatus(false);
        if (current < 3) {
          arr = [...arr, ...Object.values(res.info.list)];
          setForce(arr);
        } else {
          setForce([...force, ...Object.values(res.info.list)]);
        }
      });
    }
  }, [current]);

  useMount(() => {
    let timer;
    if (pageSatus) {
      timer = setTimeout(() => {
        Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollTop = Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollHeight;
      }, 300);
      return () => {
        clearTimeout(timer);
      };
    }
    return () => {
      // eslint-disable-next-line
      timer && clearTimeout(timer);
    };
  });
  // 下一页
  function nextPage() {
    start = false;
    const time = setTimeout(() => {
      if (!start) {
        if (pickArr.length) {
          setPickArr([]);
          return;
        }
        setLoadingStatus(true);
        setCurrent(current + 1);
        setPageStatus(false);
      }
      clearTimeout(time);
    }, 100);
  }
  function packUp() {
    start = false;
    const time = setTimeout(() => {
      if (!start) {
        setPickArr(force.slice(0, 3));
      }
      clearTimeout(time);
    }, 100);
  }

  function onTouchMove() {
    start = true;
  }
  return (
    <div className="mobile-table" style={{ marginTop: arr.length === 0 ? '0' : 'calc(16rem/75)' }}>
      {arr.length === 0 ? <div /> : (
        <div style={{ backgroundColor: '#F3F6FA', paddingLeft: (Array.isArray(arr) && arr.length && (arr[0].overdue_rate && !arr[0].supplier_name)) ? 'calc(12rem/75)' : '0', paddingTop: 'calc(20rem/75)', paddingRight: (Array.isArray(arr) && arr.length && (arr[0].overdue_rate && !arr[0].supplier_name)) ? 'calc(12rem/75)' : '0', marginTop: 'calc(16rem/75)' }}>
          {/* eslint-disable-next-line */}
          {arr[0].overdue_rate ? arr[0].supplier_name ? <MobileOverdue msg={msg} /> : (pickArr.length ? pickArr : force || arr).map((ele, index) => (
            // eslint-disable-next-line
            <div key={index}>
              <header className="margin-bottom">
                合同名称：
                <span style={{ color: '#3377FF ', cursor: 'pointer' }}>{ele.contract_name}</span>
              </header>
              <div className="margin-bottom">
                客户名称：
                {ele.custom_name}
              </div>
              <div className="margin-bottom">
                合同金额：
                <span style={{ color: '#FFAA00' }}>
                  <span className="v2210_moneyUnitMobileMR3">¥</span>
                  {ele.contract_money?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </span>
              </div>
              <div className="margin-bottom">
                逾期金额：
                <span style={{ color: '#FFAA00' }}>
                  <span className="v2210_moneyUnitMobileMR3">¥</span>
                  {ele.overdue_money?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </span>
                (逾期
                {ele.overdue_rate}
                %)
              </div>
              <div className={index === (pickArr.length ? pickArr : force || arr).length - 1 ? 'margin-bottom noline' : 'margin-bottom line'} />
            </div>
          )) : <MobileExipre msg={msg} />}
        </div>
      )} 
      <div className={Number(msg.info.total) > 3 && (Array.isArray(arr) && arr.length && (arr[0].overdue_rate && !arr[0].supplier_name)) ? 'filter-bottom' : 'none'}>
        <div className="flex ismore" style={{ height: 'calc(48rem/75)' }}>
          {/* eslint-disable-next-line */}
          <div className={current >= Number(msg.info.total_page) && pickArr.length === 0 ? 'none' : 'more'} onTouchStart={() => loadingStatus ? '' : nextPage()} onTouchMove={onTouchMove}>{loadingStatus ? <div className="loading"><img src={loading} alt="" /></div> : '查看更多'}</div>
          <div className={current > 1 && pickArr.length === 0 ? 'flex align-item left' : 'none'} style={{ position: 'absolute', right: 0 }} onTouchStart={packUp} onTouchMove={onTouchMove}>
            <div className="pack-up">
              收起
            </div>
            <div style={{ width: 'calc(28rem/75)', height: 'calc(28rem/75)' }}>
              <img src={right} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
