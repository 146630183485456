/*
 * @Author: your name
 * @Date: 2022-01-25 16:03:20
 * @LastEditTime: 2022-07-18 17:44:32
 * @LastEditors: 张立鹏 2475171028@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \Signser-web\src\containers\StaffService\index.js
 */
// pc端人工客服
import React from 'react';
import PropTypes from 'prop-types';
import '@/style/StaffService.scss';
import axios from 'axios';
import qs from 'querystring';
// 人工客服二维码展示
import CustomServiceQrCode from '../v1.1.24_PC/customServiceQrCode';
import { useScrollBottom, useMount } from '../../utils/hooks';

/*eslint-disable*/
export default function StaffService({ msg, pcSend, setSlotChatFooter, appendMessage, kfStatus, textID, deleteMsg, macCode }) {
    const params = qs.parse(window.location.search.slice(1)) || {};
    const proxy = process.env.REACT_APP_ZKGJ_PROXY || process.env.REACT_APP_ZKGJ;
    StaffService.propTypes = {
      msg: PropTypes.object,
      appendMessage: PropTypes.func,
      kfStatus: PropTypes.number, // kf为1时用户主动进入客服 为0的时候是元宝没有识别出来
      textID: PropTypes.string,
      deleteMsg: PropTypes.func,
      pcSend: PropTypes.func,
      macCode: PropTypes.func,
      setSlotChatFooter: PropTypes.func,
    };
    let groupUrl = ''; // 获取接口请求的客服url
    let returnStatus = false; // 是否到了一秒钟
    useMount(() => {
      if (msg?.is_open_cs === 1) {
        if (msg.info.groupUrl === '') {
          const manualServiceData = { ...msg.info };
          setSlotChatFooter(<CustomServiceQrCode manualServiceData={manualServiceData} device="pc" />);
        } else {
          const params = qs.parse(window.location.search.slice(1)) || {};
          if (params.device === 'Mac') {
            macCode(true, msg.info.groupUrl);
          } else {
            window.location.href = msg.info.groupUrl;
          }
        }
        return
      }
      if (kfStatus === 1) {
        deleteMsg(textID);
        goHref();
      }
    });
    useScrollBottom();
    function goHref() {
      axios({
        method: 'POST',
        url: `${proxy}/laravel/user/getGroupCode`,
        headers: {
          Authorization: params.token || process.env.REACT_APP_TOKEN,
        },
      }).then((res) => {
      
        if (res.data.code === 0) {
          if (params.device !== 'Mac') {
            appendMessage({ text: '已为您转接至钉钉客服群' }, 'left', 'kf');
          }
          if (returnStatus) {
            if (params.device === 'Mac') {
              console.log('params.deviceparams.deviceparams.device', params.device);
              macCode(true, res.data.data.groupUrl);
            } else {
              window.location.href = res.data.data.groupUrl;
            }
          } else {
            groupUrl = res.data.data.groupUrl;
          }
        } else if (returnStatus) {
            appendMessage({ text: '人工客服忙...' }, 'left', 'text');
          } else {
            groupUrl = 0;
          }
      }).catch(() => {
        // groupUrl = '';
        // 展示客服电话和二维码
        groupUrl = 0;
      });
      // if (params.device !== 'Mac') {
      //   appendMessage({ text: '已为您转接至钉钉客服群' }, 'left', 'kf');
      // }
      const time = setTimeout(() => {
        returnStatus = true;
        if (groupUrl) {
          if (params.device === 'Mac') {
            macCode(true, groupUrl);
          } else {
            window.location.href = groupUrl;
          }
        } else if (groupUrl === 0) {
          appendMessage({ text: '人工客服忙...' }, 'left', 'text');
        }
        clearTimeout(time);
      }, 1000);
    }
  
    return (
      <div className={kfStatus === 1 ? 'none' : 'StaffService'}>
        <div>
          <span className="link" onClick={()=> pcSend('转人工')}>转人工</span>
          <span className="no-link">或在当前页面重新提问</span>
        </div>
      </div>
    );
}
