/*
移动报销组件
 * @Author: your name
 * @Date: 2021-12-08 17:13:50
 * @LastEditTime: 2022-01-25 18:21:21
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \Signser-web\src\containers\MobileRate\index.js
 */
import React, { useState } from 'react';
import '@/style/mobileRate.scss';
import PropTypes from 'prop-types';
import success from '@/images/success.png';
import right from '@/images/icon-right.png';
import { useMount } from '../../utils/hooks';
// eslint-disable-next-line
import request from '@/utils/request';
import loading from '@/images/loading.png';

let start = false;
export default function MobileRate({ msg }) {
  MobileRate.propTypes = {
    msg: PropTypes.object,
  };
  let list = msg?.info.list;
  const twoStatus = ['待审批', '已驳回', '已通过', '已通过', '待审批', '已通过', 6, '已通过', '已通过', '已通过'];
  const threeStatus = ['待付款', '待付款', '待付款', '已拒绝', '待付款', '付款失败', 6, '已付款', '付款失败', '付款中'];
  const twoTime = [0, 4];
  const twoName = [4];
  const twoIcon = [5, 7, 9, 8];
  const threeTime = [...twoIcon, 3];
  const threeName = [...twoIcon, 2, 3];
  const threeNoaActive = [0, 1, 2, 4];
  const [force, setForce] = useState(null);
  const [pageSatus, setPageStatus] = useState(true);
  const [current, setCurrent] = useState(1);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [pickArr, setPickArr] = useState([]);

  useMount(() => {
    if (current > 1) {
      request.instanceZhangkuan.get(`${process.env.REACT_APP_ZK}/${msg.info.api}`, {
        params: {
          ...msg.parameter,
          per_page: 3,
          current_page: current,
        },
      }).then((res) => {
        setLoadingStatus(false);
        if (current < 3) {
          list = [...list, ...res.info.list];
          setForce(list);
        } else {
          setForce([...force, ...res.info.list]);
        }
      });
    }
  }, [current]);

  useMount(() => {
    let timer;
    if (pageSatus) {
      timer = setTimeout(() => {
        Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollTop = Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollHeight;
      }, 300);
      return () => {
        clearTimeout(timer);
      };
    }
    return () => {
      // eslint-disable-next-line
      timer && clearTimeout(timer);
    };
  });

  // 下一页
  function nextPage() {
    start = false;
    const time = setTimeout(() => {
      if (!start) {
        if (pickArr.length) {
          setPickArr([]);
          return;
        }
        setLoadingStatus(true);
        setCurrent(current + 1);
        setPageStatus(false);
      }
      clearTimeout(time);
    }, 100);
  }
  function packUp() {
    start = false;
    const time = setTimeout(() => {
      if (!start) {
        setPickArr(force.slice(0, 3));
      }
      clearTimeout(time);
    }, 100);
  }

  function onTouchMove() {
    start = true;
  }
  return (
    <div>
      {
        list && (pickArr.length ? pickArr : force || list).map((item) => (
          <div className="mobileRate" key={item.id}>
            <div className="step">
              <div style={{ width: 'calc(32rem/75)', height: 'calc(32rem/75)' }}>
                <img src={success} alt="" />
              </div>
              <div className="line" />
              <div className="step-two">
                {twoIcon.includes(item.status) ? <img src={success} style={{ width: 'calc(32rem/75)' }} alt="" /> : <div className="num">2</div>}
              </div>
              {/* eslint-disable-next-line */}
              <div className={threeNoaActive.includes(item.status) ? 'line2 noactive' : 'line2'} />
              <div className={threeNoaActive.includes(item.status) ? 'step-two noactiveIcon' : 'step-three'}>
                <div className="num">3</div>
              </div>
            </div>
            <div className="tijiao" style={{ display: 'flex', marginTop: 'calc(8rem/75)' }}>
              <div className="max-width">{item.status === 4 ? '已撤销' : `提交${msg.leb}申请`}</div>
              <div className="max-width2">{item.status === 1 ? <span style={{ color: 'rgba(255, 71, 90, 1)' }}>已驳回</span> : twoStatus[item.status]}</div>
              <div>{item.status === 5 || item.status === 3 || item.status === 8 ? <span style={{ color: 'rgba(255, 71, 90, 1)' }}>{threeStatus[item.status]}</span> : threeStatus[item.status]}</div>
            </div>
            <div className="name">
              <div className="max-width">
                <div style={{ width: 'calc(136rem/75)' }}>{item.apply_user}</div>
                <div>{item.apply_time?.split(' ')[0]}</div>
                <div>{item.apply_time?.split(' ')[1]}</div>
              </div>
              <div className="max-width2">
                <div className={twoName.includes(item.status) ? 'none' : ''} style={{ width: 'calc(136rem/75)' }}>{item.approvl_user}</div>
                <div className={twoTime.includes(item.status) ? 'none' : ''}>{item.approvl_time?.split(' ')[0]}</div>
                <div className={twoTime.includes(item.status) ? 'none' : ''}>{item.approvl_time?.split(' ')[1]}</div>
              </div>
              <div style={{ flex: 1 }}>
                <div className={threeName.includes(item.status) ? '' : 'none'}>{item.payment_user}</div>
                <div className={threeTime.includes(item.status) ? '' : 'none'}>{item.payment_time?.split(' ')[0]}</div>
                <div className={threeTime.includes(item.status) ? '' : 'none'}>{item.payment_time?.split(' ')[1]}</div>
              </div>
            </div>
          </div>
        ))
      }
      <div className={Number(msg.info.total) > 3 ? 'filter-bottom' : 'none'}>
        <div className="filter" />
        <div className="flex ismore" style={{ height: 'calc(48rem/75)' }}>
          {/* eslint-disable-next-line */}
          <div className={current >= Number(msg.info.total_page) && pickArr.length === 0 ? 'none' : 'more'} onTouchStart={() => loadingStatus ? '' : nextPage()} onTouchMove={onTouchMove}>{loadingStatus ? <div className="loading"><img src={loading} alt="" /></div> : '查看更多'}</div>
          <div className={current > 1 && pickArr.length === 0 ? 'flex align-item left' : 'none'} style={{ position: 'absolute', right: 0 }} onTouchStart={packUp} onTouchMove={onTouchMove}>
            <div className="pack-up">
              收起
            </div>
            <div style={{ width: 'calc(28rem/75)', height: 'calc(28rem/75)' }}>
              <img src={right} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
