/* eslint-disable no-unreachable */
/* eslint-disable max-len */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-first-prop-new-line */
/* eslint-disable prefer-const */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/*
 * mobile 项目预算50
 * @Author: your name
 * @Date: 2022-04-14 18:17:47
 * @LastEditTime: 2022-07-14 17:17:44
 * @LastEditors: 张立鹏 2475171028@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \signser-web\src\containers\Contacts\index.js
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { Input, Popover } from 'antd-mobile';
// eslint-disable-next-line no-unused-vars
import { CaretDownOutlined } from '@ant-design/icons';
// import { Input } from '@chatui/core';
import 'moment/locale/zh-cn';
import './budgetIndex.scss';
import { useMount } from '../../../../utils/hooks';
// eslint-disable-next-line
import right from '@/images/icon-right.png';
// eslint-disable-next-line import/extensions
import request from '@/utils/request';
import loading from '@/images/loading.png';
import contractTipImg from '@/images/contract-tips_v2300.png';

let start = false;
// eslint-disable-next-line no-unused-vars
export default function MobileProjectBudgetIndex({ msg, setSlotChatFooter, id }) {
  MobileProjectBudgetIndex.propTypes = {
    msg: PropTypes.object,
    setSlotChatFooter: PropTypes.func,
    id: PropTypes.string,
  };
  let arr = msg.info?.list || [];
  arr = arr.map((el) => {
    if (!el.budget_money) el.budget_money = '';
    return el;
  });
  let [force, setForce] = useState(arr);
  let [saveForce, setSaveForce] = useState(_.cloneDeep(arr));
  const [pageSatus, setPageStatus] = useState(true);
  const [current, setCurrent] = useState(1);
  const [loadingStatus, setLoadingStatus] = useState(false);
  let [pickArr, setPickArr] = useState([]);
  
  useMount(() => {
    if (current > 1) {
      request.instanceZhangkuan.get(`${process.env.REACT_APP_ZK}/${msg.info.api}`, {
        params: {
          ...msg.info.parameter,
          per_page: msg.info.per_page,
          current_page: current,
        },
      }).then((res) => {
        setLoadingStatus(false);
        const titleDOm = document.getElementById(id);
        if (titleDOm.innerHTML !== res.title) {
          titleDOm.innerHTML = res.title || '';
        }
        let list = res.info.list || [];
        if (current < 3) {
          force = [...force, ...list];
          setForce(force);
        } else {
          setForce([...force, ...list]);
        }
        setSaveForce(_.cloneDeep([...force, ...list]));
      });
    }
  }, [current]);

  useMount(() => {
    force = force.map((el) => {
      if (!el.budget_money) el.budget_money = '';
      return el;
    });
    setForce(force);
    let timer;
    if (pageSatus) {
      timer = setTimeout(() => {
        Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollTop = Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollHeight;
      }, 300);
      return () => {
        clearTimeout(timer);
      };
    }
    return () => {
      // eslint-disable-next-line
      timer && clearTimeout(timer);
    };
  });

  // 下一页
  function nextPage() {
    start = false;
    const time = setTimeout(() => {
      if (!start) {
        if (pickArr.length) {
          setPickArr([]);
          return;
        }
        setLoadingStatus(true);
        setCurrent(current + 1);
        setPageStatus(false);
      }
      clearTimeout(time);
    }, 100);
  }

  // 收起
  function packUp() {
    start = false;
    const time = setTimeout(() => {
      if (!start) {
        setPickArr(force.slice(0, msg.info.per_page ? msg.info.per_page : 3));
      }
      clearTimeout(time);
    }, 100);
  }

  function onTouchMove() {
    start = true;
  }

  // 修改项目预算的值
  function setInputValue(e, index) {
    let content = (pickArr.length ? pickArr : force)[index];
    let val = e?.target?.value || e;
    if (val) {
      // eslint-disable-next-line prefer-destructuring
      content.budget_money = `${val.replace(/[^\-?\d.]/g, '')}`.match(/^\d*(\.?\d{0,2})/g)[0];
    } else {
      content.budget_money = '';
    }
    if (pickArr.length) {
      pickArr[index] = content;
      setPickArr([...pickArr]);
    }
    force[index] = content;
    setForce([...force]);
  }

  // function SlotFooter() {
  //   return (
  //     <div style={{ color: '#C9CED1', marginTop: 'calc(16rem/75)', marginLeft: 'calc(8rem/75)', fontSize: 'calc(24rem/75)' }}>
  //       已保存  
  //       <span style={{ marginLeft: 'calc(8rem/75)' }}>{moment().format('HH:mm')}</span>
  //     </div>
  //   );
  // }

  // 设置项目预算
  function setProjectBudget(value, index) {
    let content = (pickArr.length ? pickArr : force)[index];
    // console.log(value, '12');
    let isReq = false;
    if (value === '') {
      // eslint-disable-next-line no-unneeded-ternary
      isReq = saveForce[index].budget_money === null || saveForce[index].budget_money === '' ? false : true;
    } else {
      isReq = saveForce[index].budget_money !== value;
    }
    // eslint-disable-next-line no-unreachable
    if (isReq) {
      const params = {
        budget_money: value,
        project_id: content.project_id,
      };
      request.instanceZhangkuan.post(`${process.env.REACT_APP_ZK}/api/ac/project/budget/set`, {
        ...params,
      // eslint-disable-next-line no-unused-vars
      }).then((res) => {
        // console.log(res, '00');
        // setSlotChatFooter(SlotFooter);
        // setSlotChatFooter(SlotFooter);
        content.budget_money = value;
        content.showSaveTxtFlag = true;
        content.showSaveTxt = moment().format('HH:mm');
        if (pickArr.length) {
          pickArr[index] = content;
          setPickArr(pickArr);
        }
        force[index] = content;
        setForce(force);
        setSaveForce(_.cloneDeep(force));
        // 2s后消失
        let timeout = setTimeout(() => {
          clearTimeout(timeout);
          if (pickArr.length) {
            pickArr[index].showSaveTxtFlag = false;
            setPickArr(pickArr);
          }
          force[index].showSaveTxtFlag = false;
          setForce(force);
          setSaveForce(_.cloneDeep(force));
        }, 2000);
      });
    }
  }
  return (
    <div className="v2300MobileProjectBudgetIndex" style={{ minWidth: 'calc(527rem/75)', paddingBottom: Number(msg.info.total) > msg.info.per_page ? 'calc(52rem/75)' : '0' }}>
      <div className="billList">
        {/* 提示img */}
        <div className="budgetTipsBox">
          <div className="V2300MobileTooltipMain V2300MobileTooltipProjectBudgetIndexTips">
            <Popover trigger="click" placement="bottom-start" getContainer={() => document.getElementsByClassName('V2300MobileTooltipProjectBudgetIndexTips')[0]} content="（已归档的项目暂时不支持设置哦，如果您有需要，可以联系我们的客服对接需求~）">
              <img className="tipsImg" src={contractTipImg} />
            </Popover>
          </div> 
        </div>
        {(pickArr.length ? pickArr : force).map((item, index) => (
          <div className="content-item" key={index}>
            <div className="item-header">
              {/* <div className="header-title">
                <span>{item.project_name || '-'}</span>
              </div> */}
              <div className="V2300MobileTooltipMain V2300MobileTooltipProjectBudgetIndexTitle">
                <Popover trigger="click" placement="bottomLeft" getContainer={() => document.getElementsByClassName('V2300MobileTooltipProjectBudgetIndexTitle')[0]} content={item.project_name || '-'}>
                  <div className="header-title">
                    <span>{item.project_name || '-'}</span>
                  </div>
                </Popover>
              </div>  
            </div>
            <div className="setBudgetBox">
              {/* 设置预算 */}
              <Input onChange={(e) => { setInputValue(e, index); }} maxLength="11" onBlur={(e) => setProjectBudget(e.target.value, index)} className="budgetInputV2300Mobile" placeholder="请输入项目预算" value={item.budget_money} />
              <div className={item.showSaveTxtFlag ? 'saveTxtMain ' : 'saveTxtMain saveTxtMainNone'}>
                <div>已保存</div>
                <div>{item.showSaveTxt}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className={Number(msg.info.total) > msg.info.per_page ? 'v1124_filterBottomMobile v2200_filterBottomMobile' : 'none'}>
        {/* <div className="filter" /> */}
        <div className="flex ismore" style={{ height: 'calc(48rem/75)' }}>
          {/* eslint-disable-next-line */}
          <div className={current >= Number(msg.info.total_page) && pickArr.length === 0 ? 'none' : 'more'} onTouchStart={() => loadingStatus ? '' : nextPage()} onTouchMove={onTouchMove}>{loadingStatus ? <div className="loading"><img src={loading} alt="" /></div> : '查看更多'}</div>
          <div className={current > 1 && pickArr.length === 0 ? 'flex align-item left pick-style' : 'none'} style={{ position: 'absolute', right: 0 }} onTouchStart={packUp} onTouchMove={onTouchMove}>
            <div className="pack-up">
              收起
            </div>
            <div style={{ width: 'calc(28rem/75)', height: 'calc(28rem/75)' }}>
              <img src={right} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
