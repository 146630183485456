/* eslint-disable max-len */
/*
 * @Author: rightAware 2475171028@qq.com
 * @Date: 2022-04-28 10:00:00
 * @LastEditors: rightAware 2475171028@qq.com
 * @LastEditTime: 2022-05-23 14:36:30
 * @FilePath: \signser-web\src\containers\v1.1.24_Mobile\ReceivableOrPayable\receivable.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/* eslint-disable react/no-danger */
/* 端应收发票
 * @Author: your name
 * @Date: 2022-04-19 18:45:57
 * @LastEditTime: 2022-04-26 16:32:17
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \signser-web\src\containers\v1.1.24_Mobile\ReceivableOrPayable\receivable.js
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab } from '@chatui/core';
import '@/style/MobileMyApproval.scss';
import { useMount } from '../../../utils/hooks';
import { getBillNavList, setBillNewNavList, setChatFooter } from '../../v1.1.24_PC/ReceivableOrPayable/utils/index';

import List from './list';
// eslint-disable-next-line
// import request from '@/utils/request';

export default function MobileReceivableBill({ msg, appendMessage, setSlotChatFooter }) {
  MobileReceivableBill.propTypes = {
    msg: PropTypes.object,
    appendMessage: PropTypes.func,
    setSlotChatFooter: PropTypes.func,
  };
  // 	unbill 1.未开票 0开票
  const initState = msg.info && msg.info.parameter && (msg.info.parameter.unbill === '1') ? '未开票' : '已开票';
  // 判断是否是其他发票
  const billLebFlag = !!(msg.info && msg.info.parameter && msg.info.parameter.bill_leb && (msg.info.parameter.bill_leb.toString().indexOf('其他发票') !== -1));
  // 判断是否 是仅看有明细/仅看无明细
  const isDetailFlag = !!(msg.info && msg.info.parameter && msg.info.parameter && (['0', '1'].includes(msg.info.parameter.is_detail)));
  let tabData = getBillNavList(msg, initState); // 头部nav
  const [pageSatus, setPageStatus] = useState(true);
  const [tabsData, setTabsData] = useState(tabData);
  const [tabsActiveKey, setTabsActiveKey] = useState(initState); // nav展示索引
  // 请求列表参数
  useMount(() => {
    tabData = setBillNewNavList(tabData, msg, initState);
    if (!billLebFlag && !isDetailFlag) {
      if (Array.isArray(tabData)) {
        setTabsData(tabData);
      }
    }
    // 插入底部内容
    // 不能是其他发票  也不能是 查看明细 
    if (tabsActiveKey === '已开票' && !billLebFlag && !isDetailFlag) {
      setChatFooter(tabsData[0].msg, appendMessage, setSlotChatFooter);
    }
  });

  useMount(() => {
    let timer;
    if (pageSatus) {
      timer = setTimeout(() => {
        Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollTop = Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollHeight;
      }, 300);
      return () => {
        clearTimeout(timer);
      };
    }
    return () => {
      // eslint-disable-next-line
      timer && clearTimeout(timer);
    };
  });

  // 切换nav 
  function changeNav(key) {
    // 插入底部内容
    // 不能是其他发票  也不能是 查看明细 
    if (!billLebFlag && !isDetailFlag) {
      // eslint-disable-next-line no-unused-expressions
      ['已开票', '未开票'][key] === '已开票' ? setChatFooter(tabsData[0].msg, appendMessage, setSlotChatFooter) : setSlotChatFooter('');
    }
    setTabsActiveKey(['已开票', '未开票'][key]);
    setPageStatus(false);
    setPageStatus(false);
  }

  return (
    <div className="receivableBillMobile">
      {
        !billLebFlag && !isDetailFlag ? (
          <Tabs className={tabsActiveKey === '已开票' ? 'tabs-main tabsMain-navPointer1' : 'tabs-main tabsMain-navPointer2'} index={['已开票', '未开票'].indexOf(tabsActiveKey)} onChange={(key) => changeNav(key)}>
            { 
              tabsData.map((ele) => (
                <Tab label={ele.navTitle} key={ele.navKey}>
                  <header className="header" style={{ wordBreak: 'break-all', wordWrap: 'break-word', marginTop: 'calc(12rem/75)' }} dangerouslySetInnerHTML={{ __html: ele.headerTitle }} />
                  <List msg={ele.msg} activeKey={tabsActiveKey} appendMessage={appendMessage} setSlotChatFooter={setSlotChatFooter} />
                </Tab>
              ))
            }
          </Tabs>
        ) : (
          <>
            <header className="header" style={{ wordBreak: 'break-all', wordWrap: 'break-word' }} dangerouslySetInnerHTML={{ __html: msg.title }} />
            <List msg={msg} activeKey="已开票" />
          </>
        )
      }
    </div>
  );
}
