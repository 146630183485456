/*
 * @Author: your name
 * @Date: 2022-01-25 16:03:20
 * @LastEditTime: 2022-01-25 18:24:40
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \Signser-web\src\containers\QRcode\index.js
 */
// pc端mac电脑二维码组件
import React from 'react';
import QRCode from 'qrcode.react';
import PropTypes from 'prop-types';
import '@/style/QRcode.scss';
import cha from '@/images/chahao.png';

export default function QRcode({ macStatus, macCode, url }) {
    QRcode.propTypes = {
        macStatus: PropTypes.bool,
        macCode: PropTypes.func,
        url: PropTypes.string,
    };
    function close() {
      macCode(false);
    }
    return (
      <div className={macStatus ? 'QRcode' : 'none'}>
        <div className="mask" onClick={close} />
        <div className="QRcode-content">
          <div className="close" onClick={close}>
            <img style={{ width: '100%', height: '100%' }} src={cha} alt="" />
          </div>
          <p className="tips-bold">电脑版暂不支持</p>
          <p className="tips-normal">请用手机钉钉扫描下方二维码加群</p>
          <QRCode
            value={url} // 需要生成二维码图片的url地址
            size={250} // 二维码图片大小
            fgColor="#000000" />
        </div>

      </div>
    );
}
