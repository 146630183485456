/* eslint-disable react/no-danger */
/* eslint-disable eqeqeq */
/* eslint-disable max-len */
/*
 * pc端应收发票
 * @Author: your name
 * @Date: 2022-04-14 18:17:47
 * @LastEditTime: 2022-07-28 19:19:01
 * @LastEditors: 张立鹏 2475171028@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \signser-web\src\containers\Contacts\index.js
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tabs } from 'antd';
// eslint-disable-next-line import/extensions
import 'moment/locale/zh-cn';
import './receivableOrPayable.scss';
import { useMount } from '../../../utils/hooks';
import { getBillNavList, setBillNewNavList, setChatFooter } from './utils/index';

import loading from '@/images/loading.png';
import ReceivableList from './list';

const { TabPane } = Tabs;
export default function ReceivableBill({ msg, appendMessage, setSlotChatFooter }) {
  ReceivableBill.propTypes = {
    msg: PropTypes.object,
    appendMessage: PropTypes.func,
    setSlotChatFooter: PropTypes.func,
  };
  const initState = msg.info && msg.info.parameter && (msg.info.parameter.unbill == '1') ? '未开票' : '已开票';
  // 判断是否是其他发票
  const billLebFlag = !!(msg.info && msg.info.parameter && msg.info.parameter.bill_leb && (msg.info.parameter.bill_leb.toString().indexOf('其他发票') !== -1));
  // 判断是否 是仅看有明细/仅看无明细
  const isDetailFlag = !!(msg.info && msg.info.parameter && msg.info.parameter && (['0', '1'].includes(msg.info.parameter.is_detail)));
  let tabData = getBillNavList(msg, initState); // 头部nav
  const [pageSatus, setPageStatus] = useState(true);
  const [tabsData, setTabsData] = useState(tabData);
  const [tabsActiveKey, setTabsActiveKey] = useState(initState === '已开票' ? '0' : '1'); // nav展示索引
  const [loadingStatus, setLoadingStatus] = useState(false);
  // console.log(1111111, msg, msg.info.parameter.is_detail, isDetailFlag, billLebFlag);
  // 请求三个列表参数
  useMount(() => {
    // 其他发票 和 是仅看有明细/仅看无明细
    if (!billLebFlag && !isDetailFlag) {
      tabData = setBillNewNavList(tabData, msg, initState);
      if (Array.isArray(tabData)) {
        setTabsData(tabData);
      }
    }
    // 插入底部内容
    // 不能是其他发票  也不能是 查看明细 
    if (tabsActiveKey === '0' && !billLebFlag && !isDetailFlag) {
      setChatFooter(tabsData[0].msg, appendMessage, setSlotChatFooter);
    }
  });
  useMount(() => {
    let timer;
    if (pageSatus) {
      timer = setTimeout(() => {
        Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollTop = Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollHeight;
      }, 300);
      return () => {
        clearTimeout(timer);
      };
    }
    return () => {
      // eslint-disable-next-line
      timer && clearTimeout(timer);
    };
  });

  // 切换nav 
  function changeNav(key) {
    // 插入底部内容
    // 不能是其他发票  也不能是 查看明细 
    if (!billLebFlag && !isDetailFlag) {
      // eslint-disable-next-line no-unused-expressions
      key === '0' ? setChatFooter(tabsData[0].msg, appendMessage, setSlotChatFooter) : setSlotChatFooter('');
    }
    setTabsActiveKey(key);
    setLoadingStatus(false);
    setPageStatus(false);
  }

  return (
    <div className={loadingStatus ? 'receivableBill v1123_relative' : 'receivableBill'}>
      <div className={loadingStatus ? 'mask' : 'none'} />
      <div className={loadingStatus ? 'loading' : 'none'}>
        <img src={loading} alt="" />
      </div>
      {
        !billLebFlag && !isDetailFlag ? (
          <Tabs className="tabs-main" activeKey={tabsActiveKey} onChange={(key) => changeNav(key)}>
            { 
              tabsData.map((ele) => (
                <TabPane tab={ele.navTitle} key={ele.navKey}>
                  <header className="header" style={{ wordBreak: 'break-word', wordWrap: 'break-word', marginTop: '12px' }} dangerouslySetInnerHTML={{ __html: ele.headerTitle }} />
                  <ReceivableList msg={ele.msg} activeKey={tabsActiveKey} appendMessage={appendMessage} />
                </TabPane>
              ))
            }
          </Tabs>
        ) : (
          <>
            <header className="header" style={{ wordBreak: 'break-word', wordWrap: 'break-word' }} dangerouslySetInnerHTML={{ __html: msg.title }} />
            <ReceivableList msg={msg} activeKey="0" />
          </>
        )
      }

    </div>
  );
}
