/* eslint-disable react/self-closing-comp */
/* eslint-disable max-len */
/* eslint-disable prefer-const */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/*
 * mobile 31 坏账
 * @Author: your name
 * @Date: 2022-04-14 18:17:47
 * @LastEditTime: 2022-06-23 18:42:55
 * @LastEditors: 张立鹏 2475171028@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \signser-web\src\containers\Contacts\index.js
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import 'moment/locale/zh-cn';
import './baddebt.scss';
import { useMount } from '../../../../utils/hooks';
import right from '@/images/icon-right.png';
import { initBaddebtContractArr, updBaddebtContractDrownFlag, getBaddebtFirstList, appendBaddebtContractChild } from '../../../v1.1.24_PC/ar/utils/index';
// eslint-disable-next-line import/extensions
import request from '@/utils/request';
import loading from '@/images/loading.png';
import drownImg from '@/images/drown_1124.png';

let start = false;
export default function MobileArBaddebtContractIndex({ msg, appendMessage }) {
  MobileArBaddebtContractIndex.propTypes = {
    msg: PropTypes.object,
    appendMessage: PropTypes.func, // 发送消息方法
  };
  let [force, setForce] = useState(initBaddebtContractArr(msg?.info?.list));
  const [pageSatus, setPageStatus] = useState(true);
  const [current, setCurrent] = useState(1);
  const [loadingStatus, setLoadingStatus] = useState(false);
  let [pickArr, setPickArr] = useState([]);
  useMount(() => {
    if (current > 1) {
      request.instanceZhangkuan.get(`${process.env.REACT_APP_ZK}/${msg.info.api}`, {
        params: {
          ...msg.info.parameter,
          per_page: msg.info.per_page,
          current_page: current,
        },
      }).then((res) => {
        setLoadingStatus(false);
        let list = initBaddebtContractArr(res.info.list || []);
        if (current < 3) {
          force = [...force, ...list];
          setForce(force);
        } else {
          setForce([...force, ...list]);
        }
      });
    }
  }, [current]);

  useMount(() => {
    let timer;
    if (pageSatus) {
      timer = setTimeout(() => {
        Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollTop = Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollHeight;
      }, 300);
      return () => {
        clearTimeout(timer);
      };
    }
    return () => {
      // eslint-disable-next-line
      timer && clearTimeout(timer);
    };
  });

  // 下一页
  function nextPage() {
    start = false;
    const time = setTimeout(() => {
      if (!start) {
        if (pickArr.length) {
          setPickArr([]);
          return;
        }
        setLoadingStatus(true);
        setCurrent(current + 1);
        setPageStatus(false);
      }
      clearTimeout(time);
    }, 100);
  }

  // 收起
  function packUp() {
    start = false;
    const time = setTimeout(() => {
      if (!start) {
        setPickArr(force.slice(0, msg.info.per_page ? msg.info.per_page : 3));
      }
      clearTimeout(time);
    }, 100);
  }
  
  // 展开下拉
  async function handleShowList(index, flag) {
    // eslint-disable-next-line prefer-destructuring
    const item = (pickArr.length ? pickArr : force)[index];
    let dataInfo = null;
    // 没有请求接口
    if (item.number > 0 && !item.requestFlag) {
      dataInfo = await getBaddebtFirstList(msg, item);
      item.children = dataInfo?.info?.list || [];
    }
    if (Array.isArray(pickArr)) {
      if (dataInfo && pickArr[index]) pickArr[index] = item;
      setPickArr(updBaddebtContractDrownFlag(pickArr, index, flag));
    }
    if (Array.isArray(force)) {
      if (dataInfo && force[index]) force[index] = item;
      setForce(updBaddebtContractDrownFlag(force, index, flag));
    }
  }

  function onTouchMove() {
    start = true;
  }
  return (
    <div className="v2200MobileArBaddebtContractIndex" style={{ minWidth: 'calc(540rem/75)', paddingBottom: Number(msg.info.total) > msg.info.per_page ? 'calc(52rem/75)' : '0' }}>
      {
        // eslint-disable-next-line max-len
        Array.isArray((pickArr.length ? pickArr : force)) && (pickArr.length ? pickArr : force).length ? (
          <>
            {/* 内容部分 */}
            <div className="baddebtList">
              {
                (pickArr.length ? pickArr : force).map((item, index) => (
                  // eslint-disable-next-line
                  <div className="content-item" key={index}>
                    <div className="list-title">{item.custom_name}</div>
                    <div className="badMoney">  
                      坏账金额：
                      <span className="bad-title">
                        <span className="v2210_moneyUnitMobileMR3">¥</span>
                        {`${item.bad_money?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                      </span>
                    </div>
                    {/* 下拉 */}
                    {
                      <div className="contract-number" onClick={() => { handleShowList(index, item.drownFlag); }}>
                        <span>
                          关联
                          {item.number}
                          个合同
                        </span>
                        <img src={drownImg} alt="" className={item.drownFlag ? 'img-up' : ''} />
                      </div>
                    }
                    <div style={{ display: item.drownFlag && item.number ? 'block' : 'none' }}>
                      {
                        item.children.map((el, index1) => (
                          <div className="chilrldContract-content" key={index1}>
                            <div className="contract-name">{el.contract_name}</div>
                            <div className="followup-txt">
                              跟进人：
                              {el.followup_name}
                            </div>
                            <div className="mt8">
                              <div>
                                坏账金额：
                                <span className="color-FF475A">
                                  <span className="v2210_moneyUnitMobileMR3">¥</span>
                                  {`${el.bad_money?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                                </span>
                                <span className="mlr8">/</span>
                                <span className="v2210_moneyUnitMobileMR3">¥</span>
                                {`${el.contract_money?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                              </div>
                            </div>
                            <div className="mt8">
                              坏账日期：
                              {el.max_date}
                            </div>
                            <div className="mt8">
                              坏账原因：
                              {el.reason || '-'}
                            </div>
                          </div>
                        ))
                      }
                      {/* 显示展示多少合同 */}
                      {
                        item.number > msg.info.per_page ? (
                          <div className="selLinkContract" onClick={() => appendBaddebtContractChild(msg, item, appendMessage)}>
                            查看全部
                            {item.number}
                            个关联合同 
                            <span className="mlr5">&gt;</span>
                          </div>
                        ) : ''
                      }
                    </div>
                  </div>
                ))
              }
            </div>
            {/* 分页 */}
            <div className={Number(msg.info.total) > msg.info.per_page ? 'v1124_filterBottomMobile v2200_filterBottomMobile' : 'none'}>
              {/* <div className="filter" /> */}
              <div className="flex ismore" style={{ height: 'calc(48rem/75)' }}>
                {/* eslint-disable-next-line */}
                <div className={current >= Number(msg.info.total_page) && pickArr.length === 0 ? 'none' : 'more'} onTouchStart={() => loadingStatus ? '' : nextPage()} onTouchMove={onTouchMove}>{loadingStatus ? <div className="loading"><img src={loading} alt="" /></div> : '查看更多'}</div>
                <div className={current > 1 && pickArr.length === 0 ? 'flex align-item left pick-style' : 'none'} style={{ position: 'absolute', right: 0 }} onTouchStart={packUp} onTouchMove={onTouchMove}>
                  <div className="pack-up">
                    收起
                  </div>
                  <div style={{ width: 'calc(28rem/75)', height: 'calc(28rem/75)' }}>
                    <img src={right} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : ''
      }
    </div>
  );
}
