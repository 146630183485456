/*
 * @Author: your name
 * @Date: 2021-09-14 14:30:03
 * @LastEditTime: 2022-04-25 14:23:10
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \Signser-web\src\until\request.js
 */
import axios from 'axios';
import qs from 'querystring';
import { toast } from '@chatui/core';

// 创建axios实例

/**
 *  @description:请求头配置
 *  @params token:账款管家toekn
 *  @params version: dingTalk： 钉钉端 ；   app： 网页端    grayscale: 灰度    Feishu:飞书
 *  @params device:Windows/Mac/iPad/iPhone/Android/Unknown
 *  @params Source:pc/mobile
 *  @params Env: Windows/Mac/iPad/iPhone/Android/Unknown
 */
const params = qs.parse(window.location.search.slice(1)) || {};
const instance = axios.create({
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Env: process.env.REACT_APP_ENV || 'cs',
      Authorization: params.token || process.env.REACT_APP_TOKEN, 
      Version: params.version || 'dingTalk', 
      Device: params.device || 'Windows', 
      Source: params.source || 'pc',
      ServerVersion: process.env.REACT_APP_SERVERVERSION,
    },
});
// 请求拦截器 ,对请求的数据进行处理
instance.interceptors.request.use((config) => {
  if (config.method === 'get') {
    //  给data赋值以绕过if判断
    config.data = true;
  }
  return config;
}, (error) => Promise.reject(error));
// 响应拦截器 ,对响应的数据进行处理
instance.interceptors.response.use((response) => {
  let data;
  if (response.status === 200 && response.data.code === 0) {
    data = response.data.data;
  } else {
    data = response;
  }
  return data;
}, 
(error) => Promise.reject(error));

const instanceZhangkuan = axios.create({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    tag: JSON.stringify({ Authorization: params.token || process.env.REACT_APP_TOKEN, Version: params.version || 'dingTalk', Device: params.device || 'Windows', Env: `${process.env.REACT_APP_ENV}` || 'cs', Source: params.source || 'pc', ServerVersion: process.env.REACT_APP_SERVERVERSION }), // 
  },
});
instanceZhangkuan.interceptors.request.use((config) => {
  if (config.method === 'get') {
    //  给data赋值以绕过if判断
    config.data = true;
  }

  return config;
}, (error) => Promise.reject(error));
// 响应拦截器 ,对响应的数据进行处理
instanceZhangkuan.interceptors.response.use((response) => {
  let data;
  if (response.status === 200 && response.data.code === 0) {
    data = response.data.data;
  } else {
    data = response;
  }
  return data;
}, 
(error) => {
  toast.fail('服务器错误');
  return error;
});

const zkgj = axios.create({
  headers: {
    Authorization: params.token || process.env.REACT_APP_TOKEN, 
  },
});

export default {
  instance,
  instanceZhangkuan,
  zkgj,
};
