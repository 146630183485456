/*
 * 判断 联系人 和 供应商
 * @Author: your name
 * @Date: 2022-03-30 14:20:08
 * @LastEditTime: 2022-07-19 17:52:54
 * @LastEditors: 张立鹏 2475171028@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \signser-web\src\containers\InquiryContent\index.js
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '@/style/inquiryContent.scss';
import { useMount } from '../../utils/hooks';
import { sendInquiryContent } from '../../utils/utils';
// eslint-disable-next-line
import loading from '@/images/loading.png';

export default function InquiryContent({ msg, pcSend, appendMessage }) {
  InquiryContent.propTypes = {
    msg: PropTypes.object,
    pcSend: PropTypes.func,
    appendMessage: PropTypes.func,
  };
  const dataArr = Object.values(msg.info.list);
  const keys = Object.keys(msg.info.list);
  // eslint-disable-next-line no-unused-vars
  const [pageSatus, setPageStatus] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [loadingStatus, setLoadingStatus] = useState(false);

  useMount(() => {
    let timer;
    if (pageSatus) {
      timer = setTimeout(() => {
        Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollTop = Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollHeight;
      }, 300);
      return () => {
        clearTimeout(timer);
      };
    }
    return () => {
      // eslint-disable-next-line
      timer && clearTimeout(timer);
    };
  });

  return (
    <div className={loadingStatus ? 'InquiryContent v1123_relative' : 'InquiryContent'}>
      <div className={loadingStatus ? 'mask' : 'none'} />
      <div className={loadingStatus ? 'loading' : 'none'}>
        <img src={loading} alt="" />
      </div>
      <div className="item-main">
        {dataArr.map((ele, index) => (
          <div className="inquiry-item" key={keys[index]} onClick={() => sendInquiryContent(ele, pcSend, appendMessage, 'pc')}>
            {ele.text}
          </div>
        ))}
      </div>
    </div>
  );
}
