/*
pcRecommend问答组件
 * @Author: your name
 * @Date: 2021-10-25 14:39:41
 * @LastEditTime: 2022-01-25 18:25:06
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \signser\src\containers\Recommend\index.js
 */
import React from 'react';
import PropTypes from 'prop-types';
import '@/style/recommend.scss';
import { useScrollBottom } from '../../utils/hooks';

export default function Recommend({ msg, getKnowledgeId }) {
    Recommend.propTypes = {
        msg: PropTypes.object,
        getKnowledgeId: PropTypes.func,
    };
    useScrollBottom();
    return (
      <div className="Recommend">
        <header>猜您是不是想问：</header>
        <div className="buttons">
          {msg.content.text.map((res, index) => (
            <div className={`button${index} button`} style={{ cursor: 'pointer' }} key={res.KnowledgeId} onClick={() => getKnowledgeId(res.Title, res.KnowledgeId)}>
              {index + 1}
              、
              {res.Title}
            </div>
          ))}
        </div>
      </div>
    );
}
