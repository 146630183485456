/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-prototype-builtins */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
/* eslint-disable prefer-const */
/* 应收 已开票 未开票list
 * @Author: your name
 * @Date: 2022-04-14 10:46:48
 * @LastEditTime: 2022-04-26 20:38:45
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \signser-web\src\containers\ReceivableOrPayable\list.js
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-unused-vars
import { Pagination, Tooltip } from 'antd';
import 'moment/locale/zh-cn';
import './receivableOrPayable.scss';
import { useMount } from '../../../utils/hooks';
import { getIconImg, initArr, updMobileDrownFlag } from './utils/index';
// eslint-disable-next-line
import request from '@/utils/request';
import loading from '@/images/loading.png';
// import electronBill from '@/images/electron_bill_1124.png';
import drownImg from '@/images/drown_1124.png';
import tipsImg from '@/images/tips-bill_20220426.png';

export default function List({ msg, activeKey }) {
  List.propTypes = {
    msg: PropTypes.object,
    activeKey: PropTypes.string,
  };
  let [content, setContent] = useState(msg.info?.list || []); // 接口返回的list
  const [pageSatus, setPageStatus] = useState(true);
  const [current, setCurrent] = useState(msg.info?.current_page || 1); // 当前页数
  const [prePage, setPrePage] = useState(msg.info?.per_page || 3); // 每页展示的条数 
  const [totalPage, setTotalPage] = useState(msg.info?.total || 0); // 一共多少页
  const [loadingStatus, setLoadingStatus] = useState(false);
  useMount(() => {
    // 初始化list
    if (Array.isArray(content)) {
      content = initArr(content);
      setContent(content);
    } 
    let timer;
    if (pageSatus) {
      timer = setTimeout(() => {
        Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollTop = Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollHeight;
      }, 300);
      return () => {
        clearTimeout(timer);
      };
    }
    return () => {
      // eslint-disable-next-line
      timer && clearTimeout(timer);
    };
  });

  function getDateList(perPage, currentPage) {
      // console.log(msg, activeKey, '00000');
      setLoadingStatus(true);
      const params = {
        ...msg.info.parameter,
        per_page: perPage,
        current_page: currentPage,
      };
      request.instanceZhangkuan.get(`${process.env.REACT_APP_ZK}/${msg.info.api}`, {
        params,
      }).then((res) => {
        let arr1 = res.info.list;
        arr1 = initArr(arr1);
        msg.info.list = arr1;
        msg.info.current_page = res.info.current_page || 1;
        msg.info.per_page = res.info.per_page || 3;
        setTotalPage(res.info.total);
        setCurrent(res.info.current_page || 1);
        setPrePage(res.info.per_page || 3);
        setContent(arr1);
        setLoadingStatus(false);
      });
  }

  // 切换页码
  function onChangePageSize(page) {
    setCurrent(page);
    setPageStatus(false);
    getDateList(prePage, page);
  }

  // icon 展示数据
  async function handleShowList(index, flag) {
    let requestArr = [];
    if (!content[index].requestFlag) {
      setLoadingStatus(true);
      // 请求接口 超过3条
      const params = {
        contract_ids: content[index].relation_ids,
      };
      if (activeKey !== '1') {
        params.bill_id = content[index].bill_id;
      }
      let api = activeKey === '1' ? 'api/ar/bill/custom/contract' : 'api/ar/bill/contract';
      const getApiInfo = await request.instanceZhangkuan.get(`${process.env.REACT_APP_ZK}/${api}`, { params });
      requestArr = getApiInfo?.info?.list;
      setLoadingStatus(false);
    }
    requestArr = Array.isArray(requestArr) ? requestArr : [];
    updContentContracts(index, flag, requestArr);
  }

  // 请求接口更新content
  function updContentContracts(index, flag, res = []) {
    content = updMobileDrownFlag(content, index, flag, res);
    msg.info.current_page = current;
    msg.info.per_page = prePage;
    msg.info.total = totalPage;
    msg.info.list = content;
    setContent([...content]);
  }

  return (
    <div className={loadingStatus ? 'billList v1123_relative' : 'billList'}>
      <div className={loadingStatus ? 'mask' : 'none'} />
      <div className={loadingStatus ? 'loading' : 'none'}>
        <img src={loading} alt="" />
      </div>
      { 
        <>
          {/* 表格内容 */}
          <div className="item-main">
            {
              // 未开票的合同没有子合同
              activeKey === '1' ? (
                content.map((item, index1) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div className="content-item" key={index1}>
                    <div className="item-title txt-ellipsis cursorPointer tooltipFontWeight400Size12">
                      <Tooltip placement="top" getPopupContainer={(triggerNode) => triggerNode.parentNode} title={item.custom_name || '-'}>
                        <span>{item.custom_name || '-'}</span>
                      </Tooltip>
                    </div>
                    <div className="item-content">
                      <div className="link-list mt0">
                        <div className="link-title">
                          <span>关联合同：</span>
                        </div>
                        <div className="link-content">
                          {
                            // eslint-disable-next-line max-len
                            ((item.drownFlag ? item.contracts : item.contracts_list) || []).map((el, index2) => (
                              // eslint-disable-next-line react/no-array-index-key
                              <div className="link-item" key={index2}>
                                <div>
                                  <span>
                                    {el.contract_name}
                                    <span className="ml5">|</span>
                                  </span>
                                  <span>
                                    {
                                      // eslint-disable-next-line operator-linebreak
                                      msg.info.parameter.department_name || msg.info.parameter.is_my_department ?
                                      (
                                        <span>
                                          <span>所属部门：</span>
                                          <span>{el.depart_name}</span>
                                        </span>
                                      ) : (
                                        <span>
                                          <span>跟进人：</span>
                                          <span>{el.followup_name}</span>
                                        </span>
                                      )
                                    }
                                    <span className="ml5">|</span>
                                  </span>
                                  <span>
                                    未开票金额：
                                    <span className="v2210_moneyUnitMR3">¥</span> 
                                    {`${el.unbill_money?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                                  </span>
                                </div>
                                {
                                  item.showDrownImg ? (<div className={item.drownFlag ? 'showDrownImg showDrownImg-up' : 'showDrownImg'} onClick={() => { handleShowList(index1, item.drownFlag); }}><img src={drownImg} alt="" /></div>) : ''
                                }
                              </div>
                            ))
                          }
                          {/* 展示提示信息 */}
                          {
                            item.showDrownImg && item.drownFlag && item.relation_count > 10 ? (
                              <div className="show-tips">
                                <img src={tipsImg} alt="" />
                                <div>
                                  其余
                                  {item.relation_count - item.contracts.length}
                                  个关联合同可前往账款管家查看
                                </div>
                              </div>
                            ) : ''
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                ))   
              ) : (
                <>
                  {
                    content.map((item, index1) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <div className="content-item" key={index1}>
                        <div className="item-title txt-ellipsis cursorPointer tooltipFontWeight400Size12">
                          <Tooltip placement="top" getPopupContainer={(triggerNode) => triggerNode.parentNode} title={item.custom_name || '-'}>
                            <span>{item.custom_name || '-'}</span>
                          </Tooltip>
                        </div>
                        <div className="item-content">
                          <div className="txt-des">
                            <div className="des-item V2310billMain">
                              <div className="icon-txt item-supplier txtWordBreakAll">
                                <img src={getIconImg(item.bill_type)} alt="" />
                                <div>{item.bill_type}</div>
                              </div>
                              <div className="item-contract_money mL8 txt-ellipsis cursorPointer tooltipFontWeight400Size12">
                                <Tooltip placement="top" getPopupContainer={(triggerNode) => triggerNode.parentNode} title={`¥  ${item.billing_money?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}` || '-'}>
                                  <span className="v2210_moneyUnitMR3">¥</span> 
                                  {`${item.billing_money?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                                </Tooltip>
                              </div>
                              <div className="item-yingfu_money mL8 txt-ellipsis cursorPointer tooltipFontWeight400Size12">
                                <Tooltip placement="top" getPopupContainer={(triggerNode) => triggerNode.parentNode} title={`¥  ${item.tax?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} （${item.tax_rate}）` || '-'}>
                                  <span className="v2210_moneyUnitMR3">¥</span> 
                                  {`${item.tax?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                                  （
                                  {item.tax_rate}
                                  ）
                                </Tooltip>
                              </div>
                            </div>
                            <div className="des-item des-item_title V2310billMain">
                              <div className="item-supplier txtWordBreakAll">
                                {item.billing_number}
                                <span className="ml5">|</span>
                                {item.billing_date}
                              </div>
                              <div className="item-contract_money mL8">开票金额</div>
                              <div className="item-yingfu_money mL8">税额（税率）</div>
                            </div>
                          </div>
                          <div className="link-list">
                            <div className="link-title">
                              {/* 应收 : 发票类别：\r\n0：合同发票；\r\n1：其他发票； */}
                              <span>
                                {item.leb === 0 ? '关联合同' : '关联项目'}
                                ：
                              </span>
                            </div>
                            {
                              item.leb === 0 ? (
                                <div className="link-content">
                                  {
                                    ((item.drownFlag ? item.contracts : item.contracts_list) || []).map((listItem, index2) => (
                                      // eslint-disable-next-line react/no-array-index-key
                                      <div className="link-item" key={index2}>
                                        <div>
                                          <span>
                                            {listItem.contract_name}
                                            <span className="ml5">|</span>
                                          </span>
                                          <span>
                                            {
                                              // eslint-disable-next-line operator-linebreak
                                              msg.info.parameter.department_name || msg.info.parameter.is_my_department ?
                                              (
                                                <span>
                                                  <span>所属部门：</span>
                                                  <span>{listItem.depart_name}</span>
                                                </span>
                                              ) : (
                                                <span>
                                                  <span>跟进人：</span>
                                                  <span>{listItem.followup_name}</span>
                                                </span>
                                              )
                                            }
                                            <span className="ml5">|</span>
                                          </span>
                                          <span>
                                            开票金额：
                                            <span className="v2210_moneyUnitMR3">¥</span> 
                                            {`${listItem.contract_billing_money?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                                          </span>
                                        </div>
                                        {/* 子项 */}
                                        {
                                          Array.isArray(listItem.children) ? (
                                            listItem.children.map((childItem, childIndex) => (
                                              <div className="item-child" key={childIndex}>
                                                <div>
                                                  <span>{childItem.contract_number}</span>
                                                  <span className="ml5" />
                                                  <span>开票金额：</span>
                                                  <span>
                                                    <span className="v2210_moneyUnitMR3">¥</span> 
                                                    {`${childItem.contract_billing_money?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                                                  </span>
                                                </div>
                                              </div>
                                            ))
                                          ) : ''
                                        }
                                      </div>
                                    ))
                                  }
                                  {/* 展示提示信息 */}
                                  {
                                    item.showDrownImg && item.drownFlag && item.relation_count > 10 ? (
                                      <div className="show-tips">
                                        <img src={tipsImg} alt="" />
                                        <div>
                                          其余
                                          {item.relation_count - item.contracts.length}
                                          个关联合同可前往账款管家查看
                                        </div>
                                      </div>
                                    ) : ''
                                  }
                              
                                  {
                                    item.showDrownImg ? (<div className={item.drownFlag ? 'showDrownImg showDrownImg-up' : 'showDrownImg'} onClick={() => { handleShowList(index1, item.drownFlag); }}><img src={drownImg} alt="" /></div>) : ''
                                  }
                                </div>
                              ) : (
                                <div className="link-content">
                                  <div className="link-item">
                                    <span>
                                      {item.project_name}
                                    </span>
                                    <span className="ml5">|</span>
                                    <span>
                                      {
                                        // eslint-disable-next-line max-len
                                        msg.info.parameter.department_name || msg.info.parameter.is_my_department
                                        // eslint-disable-next-line operator-linebreak
                                        ?
                                        (
                                          <span>
                                            <span>
                                              所属部门：
                                              {item.depart_name}
                                            </span>
                                          </span>
                                        ) : (
                                          <span>
                                            <span>
                                              项目成员：
                                              {item.project_members}
                                            </span>
                                          </span>
                                        )
                                      }
                                    </span>
                                    <span className="ml5">|</span>
                                    <span>
                                      开票金额：
                                      <span className="v2210_moneyUnitMR3">¥</span> 
                                      {`${item.billing_money?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                                    </span>
                                  </div>
                                </div>
                              )
                            }

                          </div>
                        </div>
                      </div>
                    ))
                  }
                </>
              )
            }
            {
              Number(totalPage) > msg.info.per_page
              ? (
                <Pagination current={Number(current)} hideOnSinglePage pageSize={Number(prePage)} onChange={(page, pageSize) => onChangePageSize(page, pageSize)} className="v1124_pageinationPC" size="small" showSizeChanger={false} total={Number(totalPage)} />
              ) : ''
            }
          </div>
        </>
      }
    </div>
  );
}
