/*
 * pc端查指定账户组件
 * @Author: your name
 * @Date: 2021-12-06 11:25:55
 * @LastEditTime: 2022-07-25 16:22:35
 * @LastEditors: 张立鹏 2475171028@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \Signser-web\src\containers\Accounts\index.js
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Pagination } from 'antd';
import yinhangka from '@/images/yinhangka.png';
import nolook from '@/images/nolook.png';
import islook from '@/images/islook.png';
import wei from '@/images/wei@2x.png';
import xianjin from '@/images/xianjin@2x.png';
import zhi from '@/images/zhi@2x.png';
import qita from '@/images/qita.png';
import '@/style/accounts.scss';
import Robot from '@/images/robot_logo.png';
// eslint-disable-next-line import/extensions
import { useMount } from '../../utils/hooks';
// eslint-disable-next-line import/extensions
import request from '@/utils/request';
import bankName from '../../utils/bank';
import loading from '@/images/loading.png';

export default function Accounts({ msg, appendMessage, allAccountCallback }) {
  Accounts.propTypes = {
    msg: PropTypes.object,
    appendMessage: PropTypes.func,
    allAccountCallback: PropTypes.func,
  };
  const [pageSatus, setPageStatus] = useState(true); // 是否点击下一页 如果点击了下一页则页面加载不会滚动到最底部 ps 后面的这些参数通用
  const [current, setCurrent] = useState(1); // 当前页数 ps 后面的这些参数通用
  const [total, setTotal] = useState(msg.info?.total || 0); // list总数
  const [prePage, setPrePage] = useState(msg.info?.per_page || 5); // 每页展示的条数
  const [content, setContent] = useState(null); // 内容渲染 ps 后面的这些参数通用
  const [loadingStatus, setLoadingStatus] = useState(false); // 分页动画是否展示 ps 后面的这些参数通用
  useMount(() => {
    // current大于1 或者 prev为true的时候执行分页请求 ps 分页方法后面的组件通用都是一样的代码
    if (!pageSatus) {
      if (current > Number(msg.info.total_page) || current === 0) return;
      setLoadingStatus(true);
      request.instanceZhangkuan.get(`${process.env.REACT_APP_ZK}/${msg.info.api}`, {
        params: {
          ...msg.info.parameter,
          per_page: 3,
          current_page: current,
        },
      }).then((res) => {
        setLoadingStatus(false);
        setTotal(res.info.total);
        setPrePage(res.info.per_page);
        setContent(returnData([...res.info.list]));
      });
    }
  }, [current]);
  useMount(() => {
    let timer;
    if (pageSatus) {
      timer = setTimeout(() => {
        Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollTop = Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollHeight;
      }, 300);
      return () => {
        clearTimeout(timer);
      };
    }
    return () => {
      // eslint-disable-next-line
      timer && clearTimeout(timer);
    };
  });
  const [flagStatusObj, setFalgStatusObj] = useState({}); // 把每个需要脱敏的数据都存进对象
  let list = msg?.info.list; // 接口返回的数据列表
  list = returnData(list); // 格式化数据
  // eslint-disable-next-line
  const iconList = [0, 1, xianjin, zhi, wei, qita]; // 账户类型:\n1：银行账户\n2：现金账户\n第三方账户（\n3：支付宝账户\n4：微信账户\n5：其他账户 
  /**
   * @description 吧接口返回的数据进行格式化因为需要对value进行脱敏和一些其他的操作 所以多加俩个字段 trueValues: 
   * 脱敏前  falseValue: 脱敏后 还有队各个银行进行区分 bankName 则是对银行区分 增加了bankIcon 字段
   * @param {*} item 接口返回的数据
   * @returns 格式化之后的数据
   */
  function returnData(item) {
    item.map((ele) => {
      if (ele.values) {
        if (ele.values.includes('@')) {
          ele.trueValues = ele.values;
          ele.falseValue = ele.trueValues.replace(/(.{3}).+(.{0}@.+)/g, '$1******$2');
        } else if (ele.values.length === 11) {
          ele.trueValues = ele.values.replace(/^(.{3})(.*)(.{4})/, '$1 $2 $3');
          ele.falseValue = ele.trueValues.replace(ele.trueValues.slice(4, 8), '****');
        } else {
          ele.trueValues = ele.values.replace(/(.{4})/g, '$1 ');
          ele.falseValue = ele.trueValues.replace(ele.trueValues.slice(0, 18), '**** **** **** ***');
        }
      }
      if (ele.leb === 1 || ele.leb === 5) {
        if (ele.bank_name) {
          ele.bankIcon = bankName(ele.bank_name);
        } else {
          ele.bankIcon = yinhangka;
        }
      }
      return ele;
    });
    return item;
  }

  /**
   * @description value是否脱敏以及对应脱敏前后图片的变化
   * @param {*} e 事件对象, 用于阻止冒泡
   * @param {*} item 当前循环的数据
   */
  function isLook(e, item) {
    e.stopPropagation();
    if (flagStatusObj[item.payment_method_id]) {
      setFalgStatusObj({
        ...flagStatusObj,
        [item.payment_method_id]: false,
      });
    } else {
      setFalgStatusObj({
        ...flagStatusObj,
        [item.payment_method_id]: true,
      });
    }
  }

  // 切换页码
  function onChange(page) {
    setCurrent(page);
    setPageStatus(false);
  }

  // 账户详情
  function detail(item) {
    if (item.api) {
      allAccountCallback();
      // eslint-disable-next-line
      const values = item.leb === 1 ? item.values.slice(item.values.length - 4) + (item.bank_name === null ? '' : item.bank_name) : item.payment_method;
      request.instanceZhangkuan.get(`${process.env.REACT_APP_ZK}${item.api}`, {
        params: {
          per_page: 3,
          current_page: 1,
          payment_method_id: item.payment_method_id,
          values,
        },
      }).then((res) => {
        appendMessage({ text: res }, 'left', 'nextPage', Robot, true, 'Text');
      });
    }
  }

  return (
    <div className={loadingStatus ? 'accounts-list v1123_relative' : 'accounts-list'} style={{ marginTop: 8 }}>
      <div className={loadingStatus ? 'mask' : 'none'} />
      <div className={loadingStatus ? 'loading' : 'none'}>
        <img src={loading} alt="" />
      </div>
      {
        (content || list).map((item) => (
          // eslint-disable-next-line
          <div className={list.length ? item.api ? 'accounts  hover cursor' : 'accounts' : 'accounts'} key={item.payment_method_id} onClick={() => detail(item)}>
            <div className="icon">
              <img src={item.leb === 1 ? item.bankIcon : iconList[item.leb]} alt="" />
            </div>
            <div className="middle-content">
              <div>
                {item.payment_method}
                <span className="item-bank_name">
                  {item.bank_name ? `（${item.bank_name}）` : ''}
                </span>
              </div>
              <div className={item.values ? '' : 'acc-margin-top'}>
                <div className={item.values ? 'mt4 payment-number' : 'mt4 none payment-number'}>
                  {/* eslint-disable-next-line */}
                  <div className="fontWeight600">{flagStatusObj[item.payment_method_id] ? item.trueValues : item.falseValue}</div>
                  <div className="seeEyeIcon" onClick={(e) => isLook(e, item)}>
                    <img src={flagStatusObj[item.payment_method_id] ? islook : nolook} alt="" />
                  </div>
                </div>
                <div className={item.account_branch ? 'mt4 item-bank_name' : 'mt4 item-bank_name'}>
                  开户支行：
                  {item.account_branch ? item.account_branch : '-'}
                </div>
              </div>
            </div>
          </div>
        ))
      }
      {
        total ? (<Pagination hideOnSinglePage pageSize={prePage} onChange={(page, pageSize) => onChange(page, pageSize)} className="v1124_pageinationPC" size="small" showSizeChanger={false} total={total} />) : ('')
      }
    </div>
  );
}
