/*
pc端到期合同组件
 * @Author: your name
 * @Date: 2021-10-22 17:10:17
 * @LastEditTime: 2022-07-25 11:22:38
 * @LastEditors: 张立鹏 2475171028@qq.com
 * @Description: In User Settings Edit
 * @FilePath: \signser\src\containers\ChatForm\index.js
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '@/style/table-data.scss';
import { Pagination } from 'antd';
import { useMount } from '../../utils/hooks';
// eslint-disable-next-line
import request from '@/utils/request';
import loading from '@/images/loading.png';

export default function ChatForm({ msg }) {
  ChatForm.propTypes = {
    msg: PropTypes.object,
  };
  const [pageSatus, setPageStatus] = useState(true);
  const [current, setCurrent] = useState(1); // 当前页数
  const [total, setTotal] = useState(msg.info?.total || 0); // list总数
  const [prePage, setPrePage] = useState(msg.info?.per_page || 5); // 每页展示的条数
  const [content, setContent] = useState(msg?.info.list || []);
  const [loadingStatus, setLoadingStatus] = useState(false);
  useMount(() => {
    // 分页请求
    if (!pageSatus) {
      if (current > Number(msg.info.total_page) || current === 0) return;
      setLoadingStatus(true);
      request.instanceZhangkuan.get(`${process.env.REACT_APP_ZK}/${msg.info.api}`, {
        params: {
          ...msg.info.parameter,
          per_page: 3,
          current_page: current,
        },
      }).then((res) => {
        setLoadingStatus(false);
        setTotal(res.info.total);
        setPrePage(res.info.per_page);
        setContent([...Object.values(res.info.list)]);
      });
    }
  }, [current]);

  useMount(() => {
    let timer;
    if (pageSatus) {
      timer = setTimeout(() => {
        Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollTop = Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollHeight;
      }, 300);
      return () => {
        clearTimeout(timer);
      };
    }
    return () => {
      // eslint-disable-next-line
      timer && clearTimeout(timer);
    };
  });

  // 切换页码
  function onChange(page) {
    setCurrent(page);
    setPageStatus(false);
  }
  return (
    <div className={loadingStatus ? 'v2310-contractReceivable v1123_relative' : 'v2310-contractReceivable'}>
      <div className={loadingStatus ? 'mask' : 'none'} />
      <div className={loadingStatus ? 'loading' : 'none'}>
        <img src={loading} alt="" />
      </div>
      {
        Array.isArray(content) && content.length ? (
          <div>
            {content.map((ele, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div className="contract-list" key={index}>
                <div className="item-content">
                  <div className="list-title">
                    <span>
                      <span>
                        {ele.contract_name}
                      </span>
                      ｜ 
                      <span className="fontWeight400">{ele.custom_name}</span>
                    </span>
                  </div>
                  <div className="contract-money mT8">
                    <div className="flex1">
                      合同金额：
                      <span className="money-txt">
                        <span className="v2210_moneyUnitMR3">¥</span>
                        {ele.contract_money?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : ''
      }
      {
        total ? (<Pagination hideOnSinglePage pageSize={prePage} onChange={(page, pageSize) => onChange(page, pageSize)} className="v1124_pageinationPC" size="small" showSizeChanger={false} total={total} />) : ('')
      }
    </div>
  );
}
