/* eslint-disable import/prefer-default-export */
/*
 * @Author: your name
 * @Date: 2021-10-14 17:55:17
 * @LastEditTime: 2022-06-30 20:10:18
 * @LastEditors: 张立鹏 2475171028@qq.com
 * @Description: In User Settings Edit
 * @FilePath: \signser\src\utils\utils.js
 */
import _ from 'lodash';
// eslint-disable-next-line import/extensions
import request from '@/utils/request';

// 格式化数组 坏账合同 添加向下下拉图标
export const initBudgetContractArr = (item = []) => {
  let arr = Array.isArray(item) ? item : [];
  if (Array.isArray(arr)) {
    arr = arr.map((el, index) => {
      el.drownFlag = index === 0; // 控制下拉
      // el.requestFlag = false; // 是否请求接口 
      // el.children = []; // 子合同
      return el;
    });
  }
  return arr;
};

// 修改坏账合同下拉图标展示
export const updBudgetContractDrownFlag = (item, index, flag) => {
  if (Array.isArray(item)) {
    item = _.cloneDeep(item).map((el, i) => {
      if (index === i) {
        el.drownFlag = !flag;
        // el.requestFlag = true;
      }
      return el;
    });
  }
  return item;
};

// 坏账子合同请求-第一页
export const getBudgetFirstList = async (msg, item) => {
  const params = {
    ...msg.info?.parameter,
    per_page: msg.info?.per_page || 3,
    current_page: 1,
    contract_id: item.contract_id,
  };
  const getApiInfo = await request.instanceZhangkuan.get(`${process.env.REACT_APP_SIGNSERPUSH}/api/zkgj/ac/project/budget/get/contract/project`, { params });
  return getApiInfo;
};

// 坏账查看全部子合同
export const appendBudgetContractChild = async (msg, item, appendMessage) => {
  // eslint-disable-next-line prefer-const
  let dataInfo = await getBudgetFirstList(msg, item);
  appendMessage({ text: JSON.stringify({ data: dataInfo, code: 0 }) }, 'left', 'text');
};
