/*
移动折线图组件
 * @Author: your name
 * @Date: 2021-10-26 09:18:41
 * @LastEditTime: 2022-06-23 18:29:02
 * @LastEditors: 张立鹏 2475171028@qq.com
 * @Description: In User Settings Edit
 * @FilePath: \signser\src\containers\MobileLine\index.js
 */
/*eslint-disable*/
import React, { useEffect } from 'react';
import * as echarts from 'echarts/core';
import { TooltipComponent, GridComponent } from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { LegendComponent } from 'echarts/components';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import '@/style/mobile-line.scss';

echarts.use([
  TooltipComponent,
  GridComponent,
  LineChart,
  CanvasRenderer,
  UniversalTransition,
  LegendComponent
]);
export default function MobileLine({ msg, id }) {
  const item1 = Object.values(msg.info.series[1].data).map(item => {
    return Number(item)
  })
  const item0 = Object.values(msg.info.series[0].data).map(item => {
    return Number(item)
  })
  const max = Math.max(_.max(item1), _.max(item0));
  let data1 = [], data2 = [];
  msg.info.category.forEach((item) =>{
    data1.push(msg.info.series[0].data[item]);
    data2.push(msg.info.series[1].data[item])
  });
  let RMB;
  if(max/1000 > 10) {
    RMB = '万元';
  } else {
    RMB = '元'
  }
    useEffect(() => {
      const timer1 = setTimeout(() => {
        var chartDom = document.getElementById(id);
        chartDom.style.width = 'calc(540rem/75)'
        chartDom.style.height = 'calc(285rem/75)'
        var myChart = echarts.init(chartDom);
        var option;
        option = {
          color:['#3377FF ', '#FFAA00'],
          xAxis: {
            type: 'category',
            data: msg.info.category,
            name: '月份',
            boundaryGap: false,
            axisTick: {
              inside: true
            },
            axisLabel: {
              textStyle: {
                  color: '#909399 '
              },
              formatter(params) {
                return params + '月'
              }
            },
            
          },
          tooltip: {
            trigger: 'axis',
            icon: 'circle',
            axisPointer: {
                type: null
            },
            borderColor: 'transparent',
            backgroundColor: 'rgba(48, 49, 51, 0.6)',
            formatter(params) {
              return `
            <div class="tooltip-mobile">
              <div style="font-weight: bold;font-size:calc(28rem/75);line-height:calc(34rem/75);color:#fff">${params[0].axisValue}月</div>
              <div style="height: 1px;background: #C0C4CC;margin: calc(10rem/75) 0"></div>
              <div class="a1">${params[0].seriesName}: ￥${params[0].data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}元</div>
              <div class="a2">${params[1].seriesName}: ￥${params[1].data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}元</div>
            <div/>
            `
            }
          },
          yAxis: {
            type: 'value',
            splitLine: {
              lineStyle: {
                type: 'dashed'
              }
            },
            name:`金额(${RMB})`,
            axisLabel: {
              formatter(params) {
                if(RMB === '万元') {
                  return (params/10000).toFixed(2)
                } else {
                  return params.toFixed(2)
                }
              }
            }
          },
          legend:{
            data: msg.legend,
            icon:'circle',
            itemHeight: 6,            
            align: 'left',
            itemGap: 10,
            top: 8,
            right: '10%'
          },
          grid: {
            left: '0%',
            right: '15%',
            bottom: '0%',
            top: '25%',
            containLabel: true
          },
          series: [
            {
              name: msg.info.series[0].name,
              data: data1,
              type: 'line',
              smooth: true,
              symbol:'circle',
              showSymbol: false,
            },
            {
              name: msg.info.series[1].name,
              data: data2,
              type: 'line',
              smooth: true,
              symbol:'circle',
              showSymbol: false,
            }
          ]
        };
        option && myChart.setOption(option);
        Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollTop = Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollHeight;
        window.addEventListener('resize', () => {
          myChart.resize();
        })
      }, 200)
      return () => {
        clearTimeout(timer1)
      }
    }, [])
    return (
      <div>
        手机折线图
      </div>
    );
}
