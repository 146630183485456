/*
pc逾期合同组件
 * @Author: your name
 * @Date: 2021-10-25 14:46:25
 * @LastEditTime: 2022-07-25 14:03:42
 * @LastEditors: 张立鹏 2475171028@qq.com
 * @Description: In User Settings Edit
 * @FilePath: \signser\src\containers\TableData\index.js
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Pagination } from 'antd';
import '@/style/table-data.scss';
import Exipre from '../Expire';
import Overdue from '../Overdue';
import loading from '@/images/loading.png';
// eslint-disable-next-line
import request from '@/utils/request';
import { useMount } from '../../utils/hooks';

export default function TableData({ msg, id }) {
  TableData.propTypes = {
    msg: PropTypes.object,
    id: PropTypes.string,
  };
  const dataArr = Object.values(msg.info.list);
  const keys = Object.keys(msg.info.list);
  const [pageSatus, setPageStatus] = useState(true);
  const [current, setCurrent] = useState(1); // 当前页数
  const [total, setTotal] = useState(msg.info?.total || 0); // list总数
  const [prePage, setPrePage] = useState(msg.info?.per_page || 5); // 每页展示的条数
  const [content, setContent] = useState(null);
  const [loadingStatus, setLoadingStatus] = useState(false);
  useMount(() => {
    // 分页请求
    if (!pageSatus) {
      if (current > Number(msg.info.total_page) || current === 0) return;
      setLoadingStatus(true);
      request.instanceZhangkuan.get(`${process.env.REACT_APP_ZK}/${msg.info.api}`, {
        params: {
          ...msg.info.parameter,
          per_page: 3,
          current_page: current,
        },
      }).then((res) => {
        setLoadingStatus(false);
        setTotal(res.info.total);
        setPrePage(res.info.per_page);
        setContent([...Object.values(res.info.list)]);
      });
    }
  }, [current]);

  useMount(() => {
    let timer;
    if (pageSatus) {
      timer = setTimeout(() => {
        Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollTop = Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollHeight;
      }, 300);
      return () => {
        clearTimeout(timer);
      };
    }
    return () => {
      // eslint-disable-next-line
      timer && clearTimeout(timer);
    };
  });

  // 切换页码
  function onChange(page) {
    setCurrent(page);
    setPageStatus(false);
  }

  return (
    <div className={loadingStatus ? 'v2310-contractReceivable v1123_relative' : 'v2310-contractReceivable'}>
      <div className={loadingStatus ? 'mask' : 'none'} />
      <div className={loadingStatus ? 'loading' : 'none'}>
        <img src={loading} alt="" />
      </div>
      {
        // eslint-disable-next-line
        dataArr[0]?.overdue_rate ? dataArr[0]?.supplier_name ? <Overdue msg={msg} id={id} /> : (content || dataArr).map((ele, index) => (
          <div className="contract-list" key={keys[index]}>
            <main>
              <div className="list-title">
                <span>
                  <span>
                    {ele.contract_name}
                  </span>
                  ｜
                  <span className="fontWeight400">{ele.custom_name}</span>
                </span>
              </div>
              <div className="contract-money mT8">
                <div className="flex1">
                  合同金额：
                  <span className="color_FFAA00">
                    <span className="v2210_moneyUnitMR3">¥</span>
                    {ele.contract_money?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  </span>
                </div>
                <div className="flex1 mL8">
                  逾期金额：
                  <span className="color_FFAA00">
                    <span className="v2210_moneyUnitMR3">¥</span>
                    {ele.overdue_money?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  </span>
                  &nbsp;(逾期
                  {ele.overdue_rate}
                  %)
                </div>
              </div>
            </main>
          </div>
        )) : <Exipre msg={msg} id={id} />
      }
      {
        (total > prePage && (dataArr[0]?.overdue_rate && !dataArr[0]?.supplier_name)) ? (<Pagination hideOnSinglePage pageSize={prePage} onChange={(page, pageSize) => onChange(page, pageSize)} className="v1124_pageinationPC" size="small" showSizeChanger={false} total={total} />) : ('')
      }
      
    </div>
  );
}
