/* eslint-disable jsx-a11y/alt-text */
/*
 * @Author: your name
 * @Date: 2022-01-25 16:03:20
 * @LastEditTime: 2022-05-27 18:59:31
 * @LastEditors: 张立鹏 2475171028@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \Signser-web\src\containers\StaffService\index.js
 */
// 人工客服二维码展示
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './serviceQrCodePC.scss';
import './serviceQrCodeMobile.scss';
// eslint-disable-next-line import/extensions
// import request from '@/utils/request';
import { useMount } from '../../../utils/hooks';

export default function StaffService({ manualServiceData, device }) {
    StaffService.propTypes = {
      manualServiceData: PropTypes.object,
      device: PropTypes.string,
    };
    const [pageSatus] = useState(true);
    useMount(() => {
      let timer;
      if (pageSatus) {
        timer = setTimeout(() => {
          Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollTop = Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollHeight;
        }, 300);
        return () => {
          clearTimeout(timer);
        };
      }
      return () => {
        // eslint-disable-next-line
        timer && clearTimeout(timer);
      };
    });
    const [content] = useState(manualServiceData || {}); 
    return (
      <div className={device === 'pc' ? 'contract-mainPCV2110' : 'contract-mainMobileV2110'}>
        {/* <div className="title-tips">
          您可以通过下方电话/二维码联系客服人员
        </div> */}
        <div className="introduce-detail">
          <img className="qrCode" src={content.qr_code} />
          <div className="mt8">
            电话：
            {content.tel}
          </div>
        </div>
      </div>
    );
}
