/* eslint-disable no-unused-vars */
/*
 * 判断 联系人 和 供应商
 * @Author: your name
 * @Date: 2022-04-01 11:19:13
 * @LastEditTime: 2022-07-19 17:53:04
 * @LastEditors: 张立鹏 2475171028@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \signser-web\src\containers\MobileInquiryContent\index.js
 */
import React, { useState } from 'react';
import '@/style/MobileInquiryContent.scss';
import PropTypes from 'prop-types';
import { useMount } from '../../utils/hooks';
import { sendInquiryContent } from '../../utils/utils';

export default function MobileInquiryContent({ msg, pcSend, appendMessage }) {
  MobileInquiryContent.propTypes = {
    msg: PropTypes.object,
    pcSend: PropTypes.func,
    appendMessage: PropTypes.func,
  };
  const arr = Object.values(msg.info.list);
  const [pageSatus, setPageStatus] = useState(true);
  useMount(() => {
    let timer;
    if (pageSatus) {
      timer = setTimeout(() => {
        Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollTop = Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollHeight;
      }, 300);
      return () => {
        clearTimeout(timer);
      };
    }
    return () => {
      // eslint-disable-next-line
      timer && clearTimeout(timer);
    };
  });

  return (
    <div className="MobileInquiryContent">
      {arr.length === 0 ? <div /> : (
        <div className="item-main" style={{ justifyContent: msg.is_type === 'cancelBudgetCustomerStyle' ? 'flex-end' : 'flex-start' }}>
          { arr.map((ele, index) => (
            msg.is_type === 'cancelBudgetCustomerStyle' ? (
              // eslint-disable-next-line
              <div className="inquiry-item" style={{ ...ele.style }} onClick={() => sendInquiryContent(ele, pcSend, appendMessage, 'web')} key={index}>
                <span>{ele.text}</span>
              </div>
            ) : (
              // eslint-disable-next-line
              <div className="inquiry-item" onClick={() => sendInquiryContent(ele, pcSend, appendMessage, 'web')} key={index}>
                <span>{ele.text}</span>
              </div>
            )
          ))}
        </div>
      )}
    </div>
  );
}
