/*
移动端端点击查流水的账户组件
 * @Author: your name
 * @Date: 2021-12-06 17:50:10
 * @LastEditTime: 2022-01-25 18:16:24
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \Signser-web\src\containers\MobileAccounts\index.js
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '@/style/mobileAccounts.scss';
import jianshe from '@/images/jianshe.png';
import zhaoshang from '@/images/zhaoshang.png';
import yinhangka from '@/images/yinhangka.png';
import nolook from '@/images/nolook.png';
import islook from '@/images/islook.png';
import wei from '@/images/wei@2x.png';
import xianjin from '@/images/xianjin@2x.png';
import zhi from '@/images/zhi@2x.png';
import qita from '@/images/qita.png';
import right from '@/images/icon-right.png';
import Robot from '@/images/robot_logo.png';
/*eslint-disable*/
import { useMount } from '../../utils/hooks';
// eslint-disable-next-line
import request from '@/utils/request';
import bankName from '../../utils/bank';
import loading from '@/images/loading.png';

let startDetailAccount = false;
// eslint-disable-next-line
export default function MobileDetailAccount({ msg, allAccountCallback, appendMessage }) {
  MobileDetailAccount.propTypes = {
    msg: PropTypes.object,
    allAccountCallback: PropTypes.func,
    appendMessage: PropTypes.func,
  };
  const [force, setForce] = useState(null);
  let dataArr = msg?.info?.list.info;
  dataArr = returnData(dataArr);
  const [flagStatusObj, setFalgStatusObj] = useState({});
  const bankList = [jianshe, zhaoshang, yinhangka];
  const iconList = [0, bankList, xianjin, zhi, wei, qita];
  const [pageSatus, setPageStatus] = useState(true);
  const [current, setCurrent] = useState(1);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [timeDetailAccount, setTimeDetailAccount] = useState(null);
  const [pickArr, setPickArr] = useState([]);

  useMount(() => {
    if (current > 1) {
      request.instanceZhangkuan.get(`${process.env.REACT_APP_ZK}/${msg.api}`, {
        params: {
          ...msg.parameter,
          per_page: 3,
          current_page: current,
        },
      }).then((res) => {
        setLoadingStatus(false);
        if (current < 3) {
          dataArr = [...dataArr, ...res.info?.list.info];
          setForce(returnData(dataArr));
        } else {
          setForce(returnData([...force, ...res.info?.list.info]));
        }
      });
    }
  }, [current]);

  useMount(() => {
    let timer;
    if (pageSatus) {
      timer = setTimeout(() => {
        Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollTop = Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollHeight;
      }, 300);
      return () => {
        clearTimeout(timer);
      };
    }
    return () => {
      // eslint-disable-next-line
      timer && clearTimeout(timer);
    };
  });

  /**
   * @description 格式化银行账户数据,吧建设银行 招商银行和其他银行区分开
   * @param {*} item 银行账户数据
   * @returns 
   */
  function returnData(item) {
    item?.map((ele) => {
      if (ele.account_number) {
        if (ele.account_number.includes('@')) {
          ele.trueValues = ele.account_number;
          ele.falseValue = ele.trueValues.replace(/(.{3}).+(.{0}@.+)/g, '$1******$2');
        } else if (ele.account_number.length === 11) {
          ele.trueValues = ele.account_number.replace(/^(.{3})(.*)(.{4})/, '$1 $2 $3');
          ele.falseValue = ele.trueValues.replace(ele.trueValues.slice(4, 8), '****');
        } else {
          ele.trueValues = ele.account_number.replace(/(.{4})/g, '$1 ');
          ele.falseValue = ele.trueValues.replace(ele.trueValues.slice(0, 18), '**** **** **** ***');
        }
      }
      if (ele.leb === 1 || ele.leb === 5) {
        if (ele.bank_name) {
          ele.bankIcon = bankName(ele.bank_name);
        } else {
          ele.bankIcon = yinhangka;
        }
      }
      return ele;
    });
    return item;
  }
  // 是否脱敏
  function isLook(e, item) {
    e.stopPropagation();
    if (flagStatusObj[item.payment_id]) {
      setFalgStatusObj({
        ...flagStatusObj,
        [item.payment_id]: false,
      });
    } else {
      setFalgStatusObj({
        ...flagStatusObj,
        [item.payment_id]: true,
      });
    }
  }

  // 下一页
  function nextPage() {
    startDetailAccount = false;
    const time = setTimeout(() => {
      if (!startDetailAccount) {
        if (pickArr.length) {
          setPickArr([]);
          return;
        }    
        setLoadingStatus(true);
        setCurrent(current + 1);
        setPageStatus(false);
      }
      clearTimeout(time);
    }, 100);
  }

  // 账户详情
  // eslint-disable-next-line 
  function detail(e, payment_id) {
    // eslint-disable-next-line 
    const { payment_method, bank_name, ...params } = msg.parameter;
    allAccountCallback();
    request.instanceZhangkuan.get(`${process.env.REACT_APP_ZK}${msg.api}`, {
      params: {
        ...params,
        per_page: 5,
        current_page: 1,
        payment_id,
      },
    }).then((res) => {
      appendMessage({ text: res }, 'left', 'Mobile', Robot, true, 'MobileMoneyDetail');
    });
  }
  function onTouchMove() {
    startDetailAccount = true;
  }
  function onTouchStart(e, payment_id) {
    startDetailAccount = false;
   const timeOut = setTimeout(() => {
      if (!startDetailAccount) {
        detail(e, payment_id);
      }
    }, 100);
    setTimeDetailAccount(timeOut);
  }
  useMount(() => {
    clearTimeout(timeDetailAccount);
  });
  function packUp() {
    startDetailAccount = false;
    const time = setTimeout(() => {
      if (!startDetailAccount) {
        setPickArr(force.slice(0, 3));
      }
      clearTimeout(time);
    }, 100);
  }
  return (
    <div>
      {
        (pickArr.length ? pickArr : force || dataArr).map((item, index) => (
          // eslint-disable-next-line 
          <div className="mobileAccounts" key={index} onTouchStart={(e) => onTouchStart(e, item.payment_id)} onTouchMove={onTouchMove}>
            <div>
              <div className="icon" style={{ marginRight: 'calc(8rem/75)' }}>
                <img src={item.leb === 1 ? item.bankIcon : iconList[item.leb]} alt="" />
              </div>
              <div className="company-name">
                <span>{item.payment_method}</span>
                <span className="huise">
                  {item.bank_name ? `（${item.bank_name}）` : ''}
                </span>
              </div>

            </div>
            <div className={item.account_number ? 'card-num' : 'none'} style={{ postion: 'relative', left: 'calc(36rem/75)' }}>
              {/* eslint-disable-next-line */}
              <span >{flagStatusObj[item.payment_id] ? item.trueValues : item.falseValue}</span>
              <div style={{ marginLeft: 'calc(12rem/75)', width: 'calc(32rem/75)' }} onTouchStart={(e) => isLook(e, item)}>
                <img src={flagStatusObj[item.payment_id] ? islook : nolook} alt="" />
              </div>
            </div>
          </div>
        ))
      }
      <div className={Number(msg.info.list.total) > 3 ? 'filter-bottom' : 'none'}>
        <div className="filter" />
        <div className="flex ismore" style={{ height: 'calc(48rem/75)' }}>
          <div className={current >= Number(msg.info.list.total_page) && pickArr.length === 0 ? 'none' : 'more'} onTouchStart={() => loadingStatus ? '' : nextPage()} onTouchMove={onTouchMove}>{loadingStatus ? <div className="loading"><img src={loading} alt="" /></div> : '查看更多'}</div>
          <div className={current > 1 && pickArr.length === 0 ? 'flex align-item left' : 'none'} style={{ position: 'absolute', right: 0 }} onTouchStart={packUp} onTouchMove={onTouchMove}>
            <div className={current > 1 ? 'pack-up' : 'none'}>
              收起
            </div>
            <div style={{ width: 'calc(28rem/75)', height: 'calc(28rem/75)' }}>
              <img src={right} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
