/* eslint-disable array-callback-return */
/* eslint-disable prefer-const */
/*
 * @Author: your name
 * @Date: 2021-10-14 17:55:17
 * @LastEditTime: 2022-07-26 18:45:07
 * @LastEditors: 张立鹏 2475171028@qq.com
 * @Description: In User Settings Edit
 * @FilePath: \signser\src\utils\utils.js
 */
import { v1 } from 'uuid';
import qs from 'querystring';
import QuikDefault from './quik_query_message_json/default.json';
import QuikAuth from './quik_query_message_json/auth.json'; // role为真 返回交集的module_name
import QuikRole from './quik_query_message_json/role.json'; // role为真全部返回

import contract from '@/images/contract.png'; // 查合同
import quickQueryV2300Rank from '@/images/quickQueryV2300_rank.png'; // 查排名 
import bill from '@/images/bill.png'; // 查发票
import moneyFlow from '@/images/money-flow.png'; // 查明细
import quickQueryV2300Aapproval from '@/images/quickQueryV2300_approval.png'; // 查审批
import quickQueryV2300Other from '@/images/quickQueryV2300_other.png'; // 查其他  

// eslint-disable-next-line import/extensions
import { RecommendClick, recommendEntrance, userInfo, chatFeedBack, getAcProjectBudgetRemind, getAcProjectBudgetContract, getBuriedpoint, getUserAuth, pushBuriedpoint } from '@/apis/http';

export const setSession = (key, params) => {
    try {
        return window.sessionStorage.setItem(key, JSON.stringify(params));
    } catch (error) {
        return error;
    }
}; // 存session

export const getSession = (key) => {
    try {
        return JSON.parse(window.sessionStorage.getItem(key));
    } catch (error) {
        return error;
    }
}; // 取session

export const isJSON = (str) => {
    if (typeof str === 'string') {
        try {
            const jsonObj = JSON.parse(str);
            if (typeof jsonObj === 'object' && jsonObj !== null) {
                return true;
            } 
                return false;
        } catch (e) {
            // console.log(`error${str}!!!${e}`);
        }
    }
}; // 判断数据是否是json

// 抄送我的审批 获取文本color和边框color
export const getTxtBgColor = (type, status) => {
  const approvalStatusColor = [
    { txt_color: '#3377FF', bg_color: '#EBF1FF', status: ['已通过', '已开票', '已收款', '已收票', '已退款', '已付款', '同意开票', '同意收票', '同意退款'] }, // 蓝
    { txt_color: '#FF475A', bg_color: '#FFF0F1', status: ['已驳回', '已撤销', '未通过'] }, // 红
    { txt_color: '#FFAA00', bg_color: '#FFF7E6', status: ['待审批', '待开票', '待收款', '待收票', '待退款', '待付款'] }]; // 黄
  let color = '';
  // eslint-disable-next-line array-callback-return
  approvalStatusColor.map((el) => { 
    if (el.status.indexOf(status) !== -1) {
      color = type === 1 ? el.txt_color : el.bg_color;
    }
  });
  return color;
};

// 我的审批 nav 头部
export const myApprovalNavList = (msg, changeNavTotal) => {
  let tabData = [
    { state: '待审批', total: msg.info.pending_count || 0, msg: { state: '待审批', info: {}, totalPage: msg.info.pending_count, list: [] } },
    { state: '已驳回', total: msg.info.refuse_count || 0, msg: { state: '已驳回', info: {}, totalPage: msg.info.refuse_count, list: [] } },
    { state: '已通过', total: msg.info.pass_count || 0, msg: { state: '已通过', info: {}, totalPage: msg.info.pass_count, list: [] } },
  ];
  const initState = msg.info?.parameter.state || '待审批';
  tabData = tabData.map((el) => {
    if (el.state === initState) {
      el.msg = { ...el.msg, ...msg };
    }
    el.msg.changeNavTotal = changeNavTotal;
    return el;
  });
  return tabData; 
};
// v2.0.0推荐点击事件 type 类型 首页推荐 recommendEnterClick 或 有答案无答案推荐 recommendAnswerClick
export const recommendClick = (type, messageInfo, item, msg) => {
  if (messageInfo) {
    const params = {
      trace_id: getTraceId(),
    };
    // 推荐入口点击推荐
    if (type === 'recommendEnterClick') {
      params.message = item;
      params.message_type = messageInfo.message_type;
      // 已处理话术的id message_type = 6
      // eslint-disable-next-line no-prototype-builtins
      if (messageInfo.hasOwnProperty('chat_deal_id')) {
        params.chat_deal_id = messageInfo.chat_deal_id;
      }
      // eslint-disable-next-line no-prototype-builtins
      if (messageInfo.hasOwnProperty('function_id') && messageInfo.function_id) {
        params.function_id = messageInfo.function_id;
      }
    }
    // 成功推荐和失败推荐点击
    if (type === 'recommendAnswerClick') {
      params.message = item.text;
      params.message_type = messageInfo.message_type;
      // 无答案需要拼接值
      if (msg && msg.recommendType === 'is_no_answer') {
        params[item.key] = item.value;
      } else {
        params.function_id = item.function_id;
      }
      if (messageInfo.chat_deal_id) {
        params.chat_deal_id = messageInfo.chat_deal_id;
      }
    }
    RecommendClick(params);
  }
};

// 获取推荐列表
// eslint-disable-next-line max-len
export const getRecommendEntrance = async (id, setMessageInfo, setArr, setRandom, pcSend) => {
  const search = qs.parse(window.location.search.slice(1)) || {};
  console.log(window.location.search, 'window.location.search');
  if (search.isRecommendEnter === 'true') {
    if (search.recommendMessageType === '1') {
      const messageInfo = await recommendEntrance({ get_list: 1 });
      setMessageInfo(messageInfo);
      // 有推荐信息
      if (messageInfo) {
        if (messageInfo.message_type === 1) {
          const title = document.getElementById(id);
          if (title.innerHTML !== messageInfo.title) {
            title.innerHTML = messageInfo.title || '';
          }
          // eslint-disable-next-line max-len
          const chatMessage = Array.isArray(messageInfo.chat_message) ? messageInfo.chat_message : [];
          setArr(chatMessage);
          setRandom(Math.floor(Math.random() * chatMessage.length));
        }
      }
    } else {
      const user = getSession('user') || {};
      // eslint-disable-next-line no-prototype-builtins
      if (!user.hasOwnProperty('user_id')) {
        const info = await userInfo();
        setSession('user', info);
      } 
      pcSend(search.recommendChatMessage);
    }
  }
};

// 获取ws推送列表
// eslint-disable-next-line no-unused-vars
export const getWsEntrance = async (pcSend, appendMessage, device) => {
  const search = qs.parse(window.location.search.slice(1)) || {};
  console.log(window.location.search, 'window.location.searchgetWsEntrance');
    // ws推送消息
    if (search.isWSEnter === 'true') {
      // 项目核算进入
      if (search.wsEnterType === 'businessAccounting') {
        const user = getSession('user') || {};
        // eslint-disable-next-line no-prototype-builtins
        if (!user.hasOwnProperty('user_id')) {
          const info = await userInfo();
          setSession('user', info);
        } 
        if (search.wsModuleName === 'add_project') {
          // eslint-disable-next-line max-len
          pcSend({ value: search.wsChatMessage, extends: JSON.stringify({ project_name: search.projectName }) });
        } else {
          pcSend(search.wsChatMessage);
        }
      }
      // 审批提醒进入  查看更多信息
      // 费用报销借款的推送消息详情 getAcProjectBudgetRemind  合同付款的推送消息详情 getAcProjectBudgetContract
      if (search.wsEnterType === 'approvalRemind') {
        const params = {
          module_name: search.wsModuleName,
          news_id: search.wsNewsId,
        };
        // eslint-disable-next-line no-prototype-builtins
        if (search.isNewVersion === 'false' && search.hasOwnProperty('isNewVersion')) {
          params.old_project_version = 1;
        }
        const api = search.wsModuleName === 'contract_payment' ? getAcProjectBudgetContract : getAcProjectBudgetRemind;
        api(params).then((res) => {
          appendMessage({ text: JSON.stringify({ data: res, code: 0 }) }, 'left', 'text');
          appendMessage({
            text: JSON.stringify({
              data: {
                // eslint-disable-next-line quote-props
                info: { list: [{ text: '您可以点击此处', style: { color: '#333333' } }, { 'text': '批量设置项目预算', style: { color: '#3377FF', cursor: 'pointer' }, pcSend: '批量设置项目预算', getBuriedpointParams: 'clickApproveRecommendSetting' }] },
                customer_title: 1,
                noPullToRefresh: true, // 是否页面滑动到底部
                is_text: 1,
              },
              code: 0,
            }),
          }, 'left', 'text');
          appendMessage({
            text: JSON.stringify({
              data: {
                info: { list:
                  [
                    { text: '如果项目不需要提醒预算，可以点击此处', style: { color: '#333333' } },
                    // eslint-disable-next-line object-property-newline
                    { text: '取消提醒', style: { color: '#3377FF', cursor: 'pointer' }, 
                      getBuriedpointParams: 'clickApproveRecommendCancel',
                      appendMessage: { info: { list: [{ text: '不取消了', 
                      getBuriedpointParams: 'clickApproveRecommendCancelNo',
                      appendMessage:
                        {
                          info: { list: [{ text: `你选择了不取消了，${process.env.REACT_APP_PROJECTNAME}将还会按照现在的规则给您推送项目预算。您也可以点击此处`, style: { color: '#333333' } }, { text: '批量设置项目预算', style: { color: '#3377FF', cursor: 'pointer' }, pcSend: '批量设置项目预算', getBuriedpointParams: 'clickApproveRecommendSetting' }] },
                          customer_title: 1,
                          is_text: 1,
                        },
                        style: { background: '#FFFFFF', color: '#1D1E22', border: '1px solid #D0D6DA', height: 'calc(64rem/75)', lineHeight: 'calc(64rem/75)', padding: '0 calc(32rem/75)' },
                      }, 
                      // eslint-disable-next-line object-property-newline
                      { text: '确定取消', pcSend: '取消项目审批提醒', getBuriedpointParams: 'clickApproveRecommendCancelYes', style: { background: '#216AFD', color: '#FFFFFF', marginRight: '0', height: 'calc(64rem/75)', lineHeight: 'calc(64rem/75)', padding: '0 calc(32rem/75)' } }] }, title: '您点击了取消提醒，取消后审批时将不会提醒项目预算。', is_inquiry: 1, is_text: 1, is_type: 'cancelBudgetCustomerStyle' },
                    },
                    { text: '。', style: { color: '#333333' } },
                  ] },
                  customer_title: 1,
                  noPullToRefresh: true, // 是否页面滑动到底部
                  is_text: 1,
              },
              code: 0,
            }),
          }, 'left', 'text');
        });
      }
    }
};

// 判断当前trace_id 无则生成
export const getTraceId = () => {
  let traceId = getSession('trace_id') || null;
  if (traceId === null) {
    traceId = v1();
    setSession('trace_id', traceId);
  }
  return traceId;
};

// 点赞点踩
// 点赞good 点踩bad
export const chatFeedBackFn = (id, val) => {
  chatFeedBack({ message_id: id, feedback: val });
};

// InquiryContent 发送话术
export const sendInquiryContent = (item, pcSend, appendMessage, device) => {
  // 埋点
  console.log(item, '2222', getBuriedpoint, device);
  // eslint-disable-next-line no-prototype-builtins
  if (item.hasOwnProperty('getBuriedpointParams')) signserPushBuriedpoint(item.getBuriedpointParams, device);
  // eslint-disable-next-line no-prototype-builtins
  if (item.hasOwnProperty('pcSend')) {
    pcSend(item.pcSend);
  // eslint-disable-next-line no-prototype-builtins
  } else if (item.hasOwnProperty('appendMessage')) {
    appendMessage({ text: JSON.stringify({ data: item.appendMessage, code: 0 }) }, 'left', 'text');
  } else {
    pcSend(item.text);
  }
};

// 快捷查询数据展示 spliteLen 切割数组长度
export const getQuikQueryInfo = async (setContent, spliteLen = 3) => {
  let userAuth = getSession('userAuth') || {};
  let arr = [];
  
  //  默认JSON
  if (Array.isArray(QuikDefault)) {
    QuikDefault.map((el) => {
      let item = { name: el.name, messages: el.messages };
      arr.push(item);
    });
  }

  // 数据过滤 type为appendRoleAuthQuik 只过滤role为真 type为 QuikAuth roles和authority一起过滤 arr1目标函数
  function filterArr(type, force, info) {
    let newArr = [];
    // eslint-disable-next-line prefer-destructuring
    const role = Array.isArray(info.role) ? info.role : [];
    // eslint-disable-next-line prefer-destructuring
    const authority = Array.isArray(info.authority) ? info.authority : [];
    // console.log(role, authority);
    force.map((el) => {
      let quikItem = null;
      el.auth_messages.map((authItem) => {
        let roleFlag = authItem.role.ids.filter((roleItem) => role.includes(roleItem));
        let messages = [];
        // auth 存在ids为空
        if ((Array.isArray(roleFlag) && roleFlag.length) || (type === 'appendRoleAuthQuik' && authItem.role.ids.length === 0)) {
          if (type === 'appendRoleQuik') {
            messages = Array.isArray(authItem.role.messages) ? authItem.role.messages : [];
          }
          if (type === 'appendRoleAuthQuik') {
            // eslint-disable-next-line max-len
            let authFlag = authItem.role.auth.module_names.filter((roleItem) => authority.includes(roleItem));
            // module_names为真
            if (Array.isArray(authFlag) && authFlag.length) {
              // eslint-disable-next-line max-len
              let authArr = authFlag.map((authChild) => authItem.role.auth.module_messages[authChild].messages);
              messages = Array.isArray(authArr) ? authArr.flat(Infinity) : [];
            }
          }
          if (Array.isArray(messages) && messages.length) {
            if (quikItem) {
              quikItem.messages = quikItem.messages.concat(messages);
            } else {
              quikItem = { name: el.name, messages };
            }
          }
        }
      });
      if (quikItem) newArr.push(quikItem);
    });
    return newArr;
  }

  // 判断是否有userId 更新数组carousel-box
  function setContentFn(content, setNewContent) {
    let arr1 = Array.isArray(filterArr('appendRoleQuik', QuikRole, userAuth)) ? filterArr('appendRoleQuik', QuikRole, userAuth) : [];
    let arr2 = Array.isArray(filterArr('appendRoleAuthQuik', QuikAuth, userAuth)) ? filterArr('appendRoleAuthQuik', QuikAuth, userAuth) : [];
    // console.log(QuikRole, QuikAuth, userAuth.authority.includes('seeCashFlow'));
    // eslint-disable-next-line max-len
    setNewContent(Array.isArray([...content, ...arr1, ...arr2]) ? spliteArr([...content, ...arr1, ...arr2]) : []);
  }

  // 数组截取 
  function spliteArr(currentArr) {
    let result = [];
    for (let i = 0; i < currentArr.length; i += spliteLen) {
      result.push(currentArr.slice(i, i + spliteLen));
    }
    // console.log(result);
    return result;
  }

  setContent(Array.isArray(arr) ? spliteArr(arr) : []);
  // eslint-disable-next-line no-prototype-builtins 
  if (userAuth.hasOwnProperty('authority')) {
    // QuikAuth QuikRole
    setContentFn(arr, setContent);
  } else {
    // 获取用户信息
    getUserAuth().then((res) => {
      setSession('userAuth', res);
       // eslint-disable-next-line no-prototype-builtins
      if (res && res.hasOwnProperty('authority')) {
        userAuth = res;
        setContentFn(arr, setContent);
        // console.log(res, 'ssssssssss', user);
      }
    });
  }
};

// 快捷查询icon
export const getQuikQueryBillStyle = (type) => {
  let item = {};
  let arr = [
    { name: '查合同', color: '#C0DDFF', img: contract },
    { name: '查排名', color: '#FDE492', img: quickQueryV2300Rank },
    { name: '查发票', color: '#FDCDC5', img: bill },
    { name: '查审批', color: '#D9CDFF', img: quickQueryV2300Aapproval },
    { name: '查收支', color: '#AFF0B5', img: moneyFlow },
    { name: '查其他', color: '#BBF0FC', img: quickQueryV2300Other },
  ];
  arr.map((el) => {
    if (el.name === type) item = el;
  });
  return item;
};

// signserPush 埋点
// 埋点
export const signserPushBuriedpoint = (type, device, params) => {
  const pointIdObj = {
    clickQuickQuery: device === 'pc' ? 11 : 18, // 快捷查询
    clickQuickBudgetSet: device === 'pc' ? 12 : 19, // 项目预算设置
    clickCustomerService: device === 'pc' ? 13 : 20, // 人工客服
    clickRemindSet: device === 'pc' ? 34 : 44, // 消息提醒设置
    clickApproveRecommendCancel: device === 'pc' ? 8 : 15, // 审批页面提醒- 取消提醒
    clickApproveRecommendCancelNo: device === 'pc' ? 9 : 16, // 审批页面提醒- 取消提醒-不取消了
    clickApproveRecommendCancelYes: device === 'pc' ? 10 : 17, // 审批页面提醒- 取消提醒-确定取消
    clickApproveRecommendSetting: device === 'pc' ? 7 : 14, // 审批页面提醒 自定义设置规则
  };
  const data = {
    point_id: pointIdObj[type],
  };
  if (params) {
    data.request_params = params;
  }
  pushBuriedpoint(data);
};
