/*
 * @Author: your name
 * @Date: 2021-12-09 14:37:03
 * @LastEditTime: 2022-04-14 16:28:22
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \Signser-web\src\utils\nls.js
 */
// https://www.yuque.com/speech-fe/js-sdk
/*eslint-disable*/
import NlsClient from 'ali-nls';
import { useEffect, useState } from 'react';
// eslint-disable-next-line import/extensions
import { asrConfig } from '@/apis/http';

// eslint-disable-next-line no-unused-vars
const useNls = (fn, noneMask, end) => {
    const [instance, setinstance] = useState(null);
    useEffect(() => {
        /**
         * @description 获取语音识别配置
         */
        asrConfig().then((res) => {
            const client = new NlsClient({
                appkey: res.app_key,
                accessToken: res.token,
                debug: true,
            });
            // 初始化语音识别
            const instances = client.initRecognition({
                enableIntermediateResult: true,
                enableInverseTextNormalization: true,
                vocabularyId: res.vocabulary_id,
                enablePunctuationPrediction: false,
            });
            let resContnet = '';
            let time;
            instances.on('sentenceEnd', (_, result) => {
                resContnet += result;
                end(resContnet, false)
                fn(resContnet, false);
                // eslint-disable-next-line no-unused-expressions
                time && clearInterval(time);
                time = setInterval(() => {
                    clearInterval(time);
                    instances.stop();
                    noneMask('nls');
                    end(resContnet, true)
                    fn(resContnet, true);
                }, 4000); // 4秒后如果用户没出声就自动发送
                const transContent = document.getElementById('trans-content');
                transContent.scrollTop = 99999999999; // 获取到展示语音文字的容器每次说话玩都保证滚动条在最底部
            });
            instances.on('completed', (taskId, msg) => {
                clearInterval(time);
                resContnet = '';
            });
            instances.on('sentenceBegin', () => {
                clearInterval(time);
            });
            instances.on('sentenceChanged', (index, result, taskId, msg) => {
                fn(resContnet + result, false, false);
            });
            setinstance(instances);
        });
    }, []);
    return instance;
};

export default useNls;
