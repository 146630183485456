/*
 * pc端条形图组件
 * @Author: your name
 * @Date: 2021-10-25 09:23:42
 * @LastEditTime: 2022-07-28 18:32:24
 * @LastEditors: 张立鹏 2475171028@qq.com
 * @Description: In User Settings Edit
 * @FilePath: \signser\src\containers\Bar\index.js
 */
/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import * as echarts from 'echarts/core';
import { TooltipComponent, GridComponent } from 'echarts/components';
import { BarChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { useScrollBottom } from '../../utils/hooks';
import _ from 'lodash';

echarts.use([
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer,
  UniversalTransition
]);
export default function Bar({ msg, id }) {
  // const [boxRight, setBoxRight] = useState('18%');
  const name = [];
  const money = [];
  let top = '8%'
  msg.info.list.forEach(res => {
    name.push(res.y);
    money.push(res.x)
  });
  const max = _.max(money.map((item) => {
    return Number(item)
  })); // 获取item中的最大金钱数
  let RMB;
  // 判断最大数是否大于一万
  if (max / 1000 > 10) {
    RMB = '万元';
  } else {
    RMB = '元'
  }
  if (msg.info.list.length >= 5) {
    top = '3%'
  }
  useEffect(() => {
    var chartDom = document.getElementById(id);
    chartDom.style.width = '100%';

    const height = `${msg.info.list.length * 40 + 34}`
    chartDom.style.height = `${height}px`;
    const myChart = echarts.init(chartDom);
    let option = {
      color: ['#3377FF'],
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        },
        backgroundColor: 'rgba(62, 70, 77, 0.8)',
        borderColor: 'transparent',
        formatter(params) {
          return `
              <div style=' color: white'>
                  ${params[0].name} <br />
                  金额: ${params[0].value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}元
              <div/>
            `
        }
      },
      grid: {
        top: top,
        left: '3%',
        // right: '85px' || boxRight,
        right: '85px',
        bottom: top,
        containLabel: true
      },
      xAxis: {
        type: 'value',
        name: `金额/${RMB}`,
        axisLabel: {
          show: true,
          fontSize: 10,
          color: '#606266',
          formatter(params) {
            if (RMB === '万元') {
              return (params / 10000).toFixed(2)
            } else {
              return params.toFixed(2)
            }
          }
        },
      },
      yAxis: {
        type: 'category',
        data: name,
        axisTick: {
          show: false
        },
        inverse: true,
        axisLabel: {
          formatter: function (value, index) {
            if (value.length > 5) {
              return value.substring(0, 4) + "...";
            } else {
              return value
            }
          }
        },
      },
      series: [
        {
          type: 'bar',
          data: money,
          barWidth: 20,
          barGap: '30%'
        },
      ]
    };
    option && myChart.setOption(option);
    window.addEventListener('resize', ()=> {
      // if (document.body.clientWidth > 600) {
      //   if (document.body.clientWidth > 700) setBoxRight('10%');
      // } else {
      //   setBoxRight('25%');
      // }
      myChart.resize();
    });
    return () => {
      window.removeEventListener('resize', ()=> {myChart.resize();});
    };
  }, [])
  useScrollBottom()
  return (
    <div/>
  );
}
