/*
 * @Author: 张立鹏 2475171028@qq.com
 * @Date: 2022-03-31 11:02:23
 * @LastEditors: 张立鹏 2475171028@qq.com
 * @LastEditTime: 2022-07-28 17:54:22
 * @FilePath: \Signser-web\src\containers\NextPage\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Accounts from '../Accounts';
import '@/style/nextPage.scss';
import Overdue from '../Overdue';
import Expire from '../Expire';
import Payment from '../Payment';
import Aging from '../Aging';
import Rate from '../Rate';
import MoneyDetail from '../MoneyDetail';
import DetailAccount from '../DetailAccount';

// eslint-disable-next-line 
export default function NextPage({ msg, updateMsg, id, nextPageType, allAccountCallback, appendMessage }) {
    NextPage.propTypes = {
        msg: PropTypes.object,
        updateMsg: PropTypes.func,
        nextPageType: PropTypes.string,
        id: PropTypes.string,
        appendMessage: PropTypes.func,
        allAccountCallback: PropTypes.func,
    };
    const [boxWidth, setBoxWidth] = useState(document.body.clientWidth - 32 - 40 - 8 - 32); //  窗口宽度
    // eslint-disable-next-line no-unused-vars
    function hanldeHeight() {
      setBoxWidth(document.body.clientWidth - 32 - 40 - 8 - 32);
    }
    // 监听窗口变化
    useEffect(() => {
      window.addEventListener('resize', hanldeHeight);
      return () => {
        window.removeEventListener('resize', hanldeHeight);
      };
    }, []);
    let value = '';
    if (msg.info?.list || msg.info?.info) {
      switch (nextPageType) {
        case 'Overdue':
          value = <Overdue id={id} msg={msg} updateMsg={updateMsg} />;
          break;
        case 'Expire':
          value = <Expire id={id} msg={msg} updateMsg={updateMsg} />;
          break;
        case 'Payment':
          value = <Payment id={id} msg={msg} updateMsg={updateMsg} />;
          break;
        case 'Aging':
          value = <Aging id={id} msg={msg} updateMsg={updateMsg} />;
          break;
        case 'Accounts':
          // eslint-disable-next-line 
          value = <Accounts id={id} msg={msg} updateMsg={updateMsg} allAccountCallback={allAccountCallback} appendMessage={appendMessage} />;
          break;
        case 'Rate':
          // eslint-disable-next-line 
          value = <Rate id={id} msg={msg} updateMsg={updateMsg} allAccountCallback={allAccountCallback} appendMessage={appendMessage} />;
          break;
        case 'MoneyDetail':
          value = <MoneyDetail id={id} msg={msg} updateMsg={updateMsg} />;
          break;
        case 'DetailAccount':
          value = <DetailAccount id={id} msg={msg} updateMsg={updateMsg} />;
          break;
        case 'Text':
          value = '';
          break;
        default:
          break;
      }
    }

    return (
      <div className="next-page" style={{ width: `${boxWidth}px` }}>
        {/* eslint-disable-next-line */}
        <header id={msg._id} className="header" dangerouslySetInnerHTML={{ __html: msg.title }} />
        {msg.info?.list || msg.info?.info ? value : ''}
      </div>
    );
}
