/* eslint-disable no-unreachable */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/*
pc端查合同应收 合同回款
 * @Author: your name
 * @Date: 2021-10-25 14:46:25
 * @LastEditTime: 2022-07-08 09:12:54
 * @LastEditors: 张立鹏 2475171028@qq.com
 * @Description: In User Settings Edit
 * @FilePath: \signser\src\containers\TableData\index.js
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './customerTitle.scss';
import { useMount } from '../../utils/hooks';
// eslint-disable-next-line import/extensions
import { signserPushBuriedpoint } from '@/utils/utils';
// eslint-disable-next-line

export default function CustomerTitlePCAndMobile({ msg, pcSend, appendMessage, device }) {
  CustomerTitlePCAndMobile.propTypes = {
        msg: PropTypes.object,
        device: PropTypes.string,
        pcSend: PropTypes.func,
        appendMessage: PropTypes.func,
    };
    const [content] = useState(msg.info?.list || []); // 接口返回的list
    const [pageSatus] = useState(true);

    useMount(() => {
      let timer;
      if (pageSatus && !(msg?.noPullToRefresh)) {
        timer = setTimeout(() => {
          Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollTop = Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollHeight;
        }, 300);
        return () => {
          clearTimeout(timer);
        };
      }
      if (msg?.noPullToRefresh) {
        timer = setTimeout(() => {
          Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollTop = 0;
        }, 300);
        return () => {
          clearTimeout(timer);
        };
      }
      return () => {
        // eslint-disable-next-line
        timer && clearTimeout(timer);
      };
    });

    function handleItemClick(item) {
      // 埋点
      console.log(device, 'device');
      // eslint-disable-next-line no-prototype-builtins
      if (item.hasOwnProperty('getBuriedpointParams')) signserPushBuriedpoint(item.getBuriedpointParams, device);
      // pcSend： 发送一段话   appendMessage: 发送文本
      // eslint-disable-next-line no-prototype-builtins
      if (item.hasOwnProperty('pcSend')) {
        pcSend(item.pcSend);
      // eslint-disable-next-line no-prototype-builtins
      } else if (item.hasOwnProperty('appendMessage')) {
        appendMessage({ text: JSON.stringify({ data: item.appendMessage, code: 0 }) }, 'left', 'text');
      }
    }
 
    return (
      <div style={{ maxWidth: device === 'mobile' ? 'calc(540rem/75)' : '' }}>
        {
          Array.isArray(content) && content.length ? (
            content.map((el, index) => (
              // pcSend： 发送一段话   appendMessage: 发送文本
              // eslint-disable-next-line react/no-array-index-key
              <span key={index} style={{ ...el.style }} onClick={() => handleItemClick(el)}>{el.text}</span>
            ))
          ) : ''
        }
      </div>
    );
}
