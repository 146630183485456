/* eslint-disable no-prototype-builtins */
/*
 * pc端查询客户、供应商联系数量
 * @Author: your name
 * @Date: 2022-03-24 17:10:17
 * @LastEditTime: 2022-04-08 09:43:35
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \signser\src\containers\ChatForm\index.js
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Pagination } from 'antd';
import '@/style/customersSuppliersNumber.scss';
import { useMount } from '../../utils/hooks';
// eslint-disable-next-line
import request from '@/utils/request';
import loading from '@/images/loading.png';

export default function CustomersSuppliersNumber({ msg }) {
  CustomersSuppliersNumber.propTypes = {
    msg: PropTypes.object,
  };
  const dataArr = Object.values(msg.info.list);
  const keys = Object.keys(msg.info.list);
  const [pageSatus, setPageStatus] = useState(true);
  const [current, setCurrent] = useState(msg.info.current_page); // 当前页数
  const [content, setContent] = useState(null); // 接口返回的list
  const [total, setTotal] = useState(msg.info.total); // list总数
  const [prePage, setPrePage] = useState(msg.info.per_page); // 每页展示的条数
  const [loadingStatus, setLoadingStatus] = useState(false);
  useMount(() => {
    // 分页请求
    if (!pageSatus) {
      if (current > Number(msg.info.total_page) || current === 0) return;
      setLoadingStatus(true);
      request.instanceZhangkuan.get(`${process.env.REACT_APP_ZK}/${msg.info.api}`, {
        params: {
          ...msg.info.parameter,
          per_page: prePage,
          current_page: current,
        },
      }).then((res) => {
        setLoadingStatus(false);
        setTotal(res.info.total);
        setPrePage(res.info.per_page);
        setContent([...Object.values(res.info.list)]);
      });
    }
  }, [current]);

  useMount(() => {
    let timer;
    if (pageSatus) {
      timer = setTimeout(() => {
        Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollTop = Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollHeight;
      }, 300);
      return () => {
        clearTimeout(timer);
      };
    }
    return () => {
      // eslint-disable-next-line
      timer && clearTimeout(timer);
    };
  });

  // 切换页码
  function onChange(page) {
    setCurrent(page);
    setPageStatus(false);
  }

  return (
    <div className={loadingStatus ? 'Customers v1123_relative' : 'Customers'}>
      <div className={loadingStatus ? 'mask' : 'none'} />
      <div className={loadingStatus ? 'loading' : 'none'}>
        <img src={loading} alt="" />
      </div>
      <div className="item-main">
        {(content || dataArr).map((ele, index) => (
          <div className="customer-item" key={keys[index]}>
            <span>
              { current === 1 ? (index + 1) : ((current - 1) * prePage + index + 1)}
              、
            </span>
            {/* // 19: [客户/供应商]数量 20: [客户/供应商]联系方式 */}
            <span>{ ele.hasOwnProperty('custom_name') ? ele.custom_name : ele.supplier_name}</span>
            <span className="MLR8">|</span>
            <span>
              负责人：
              {ele.name}
            </span>
          </div>
        ))}
      </div>
      {
        total ? (<Pagination hideOnSinglePage pageSize={prePage} onChange={(page, pageSize) => onChange(page, pageSize)} className="pageination-main" size="small" showSizeChanger={false} total={total} />) : ('')
      }
    </div>
  );
}
