/* eslint-disable react/jsx-first-prop-new-line */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable max-len */
/* eslint-disable prefer-object-spread */
/* eslint-disable prefer-const */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/*
 * 合同付款 43
 * @Author: your name
 * @Date: 2022-04-14 18:17:47
 * @LastEditTime: 2022-07-22 16:11:03
 * @LastEditors: 张立鹏 2475171028@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \signser-web\src\containers\Contacts\index.js
 */

import React, { useState, useRef } from 'react';
// eslint-disable-next-line no-unused-vars
import { Pagination, Input, Tooltip } from 'antd'; // Tooltip
// eslint-disable-next-line no-unused-vars
import { CaretDownOutlined, EditOutlined } from '@ant-design/icons';

import PropTypes from 'prop-types';
import 'moment/locale/zh-cn';
import './contract.scss';
import { useMount } from '../../../../../utils/hooks';
import { initBudgetContractArr, updBudgetContractDrownFlag, appendBudgetContractChild } from '../../utils/index';
// eslint-disable-next-line
import request from '@/utils/request';
import loading from '@/images/loading.png';
import drownImg from '@/images/drown_1124.png';
import contractTipImg from '@/images/contract-tips_v2300.png';

export default function ZKGJProjectBudgetContract({ msg, appendMessage }) {
  ZKGJProjectBudgetContract.propTypes = {
    msg: PropTypes.object,
    appendMessage: PropTypes.func, // 发送消息方法
  };
  // eslint-disable-next-line quotes
  // const contractTipsTxt = "<div><div>当前利润：当前利润 = 当前利润</div><div>当前收入：当前收入 = 销售收入</div><div>当前支出：当前支出 = 采购成本+费用支出+报销支出</div><div>剩余预算：剩余预算=项目预算-实际支出</div></div>";
  let [content, setContent] = useState(msg.info?.list || []); // 接口返回的list
  const [pageSatus, setPageStatus] = useState(true);
  const [current, setCurrent] = useState(msg.info?.current_page || 1); // 当前页数
  const [prePage, setPrePage] = useState(msg.info?.per_page || 3); // 每页展示的条数 
  const [totalPage, setTotalPage] = useState(msg.info?.total || 1); // 一共多少页
  const [loadingStatus, setLoadingStatus] = useState(false);
  const inputRef = useRef(null); // 文本框聚焦
 
  // const [contractloadingStatus, setContractLoadingStatus] = useState(false); // 查看合同loading
  // console.log(msg, 'ArBaddebtContractIndex22222', content, msg.info?.list, totalPage); 
  useMount(() => {
    // 初始化list
    if (Array.isArray(content)) {
      content = initBudgetContractArr(content);
      setContent(content);
    } 
    
    let timer;
    if (pageSatus && !(msg?.noPullToRefresh)) {
      timer = setTimeout(() => {
        Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollTop = Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollHeight;
      }, 300);
      return () => {
        clearTimeout(timer);
      };
    }
    return () => {
      // eslint-disable-next-line
      timer && clearTimeout(timer);
    };
  });

  function getDateList(perPage, currentPage) {
    // eslint-disable-next-line no-unreachable
    setLoadingStatus(true);
    const params = {
      ...msg.info.parameter,
      per_page: perPage,
      current_page: currentPage,
    };
    request.instanceZhangkuan.get(`${process.env.REACT_APP_SIGNSERPUSH}/${msg.info.api}`, {
      params,
    }).then((res) => {
      // console.log('res', res);
      // eslint-disable-next-line no-prototype-builtins
      if (res && res.hasOwnProperty('info')) {
        const arr1 = initBudgetContractArr(res.info.list || []);
        msg.info.list = arr1;
        msg.info.current_page = res.info.current_page || 1;
        msg.info.per_page = res.info.per_page || 3;
        setTotalPage(res.info.total);
        setCurrent(res.info.current_page || 1);
        setPrePage(res.info.per_page || 3);
        setContent(arr1);
      }
      setLoadingStatus(false);
    });
  }

  // 切换页码
  function onChangePageSize(page) {
    setCurrent(page);
    setPageStatus(false);
    getDateList(prePage, page);
  }

  // 展开下拉
  async function handleShowList(index, flag) {
    setContent(updBudgetContractDrownFlag(content, index, flag));
  }

  // 设置项目预算
  function openSetBudgetInput(index1, index2) {
    content[index1].projects.info.list[index2].showBudgetSetInput = true;
    content[index1].projects.info.list[index2].save_budget_money = content[index1].projects.info.list[index2].budget_money || '';
    // eslint-disable-next-line no-unused-expressions
    // inputRef.current && inputRef.current.focus({ cursor: 'start' });
    setContent([...content]);
    let timeout = setTimeout(() => {
      // eslint-disable-next-line no-unused-expressions
      inputRef.current && inputRef.current.focus({ cursor: 'end' }); 
      clearTimeout(timeout);
    });
  }

    // 设置项目预算回车
    function textareaKeyDown(e, index1, index2) {
      if (!e.shiftKey && e.keyCode === 13) {
        if (e.target.value) {
          setProjectBudget(e.target.value, index1, index2);
        }
      }
    }

  // 修改项目预算的值
  function setInputValue(val, index1, index2) {
    if (val) {
      // eslint-disable-next-line prefer-destructuring
      content[index1].projects.info.list[index2].save_budget_money = `${val.replace(/[^\-?\d.]/g, '')}`.match(/^\d*(\.?\d{0,2})/g)[0];
    } else {
      content[index1].projects.info.list[index2].save_budget_money = '';
    }
    setContent([...content]);
  }

  // 设置项目预算
  function setProjectBudget(value, index1, index2) {
    // eslint-disable-next-line no-unreachable
    if (Number(value) !== Number(content[index1].projects.info.list[index2].budget_money) || ((content[index1].projects.info.list[index2].budget_money === null || content[index1].projects.info.list[index2].budget_money === '') && value)) {
      const params = {
        // project_name: content[index1].projects.info.list[index2].project_name,
        budget_money: value,
        project_id: content[index1].projects.info.list[index2].id,
      };
      request.instanceZhangkuan.post(`${process.env.REACT_APP_ZK}/api/ac/project/budget/set`, {
        ...params,
      // eslint-disable-next-line no-unused-vars
      }).then((res) => {
        content[index1].projects.info.list[index2].budget_money = value;
        content[index1].projects.info.list[index2].showBudgetSetInput = false;
        setContent([...content]);
      });
    } else {
      content[index1].projects.info.list[index2].showBudgetSetInput = false;
      setContent([...content]);
    }
  }

  return (
    <div className={loadingStatus ? 'v2300-ZKGJProjectBudgetContract v1123_relative' : 'v2300-ZKGJProjectBudgetContract'}>
      <div className={loadingStatus ? 'mask' : 'none'} />
      <div className={loadingStatus ? 'loading' : 'none'}>
        <img src={loading} alt="" />
      </div>
      {
        Array.isArray(content) && content.length ? (
          <div className="budgetMain">
            {
              content.map((item, index) => (
                <div className="budgetList" key={index}>
                  <div className="list-contract">
                    <div className="contract-head">
                      <div className="contract-name">
                        {item.contract_name || '-'}
                      </div>
                      <div className="link-contract" onClick={() => { handleShowList(index, item.drownFlag); }}>
                        <div>
                          共关联
                          {item.projects.info.total || '0'}
                          个项目
                        </div>
                        <img src={drownImg} alt="" className={item.drownFlag ? 'img-up' : ''} />
                      </div>
                    </div>
                
                    <div style={{ display: item.drownFlag && item.projects.info.total ? 'block' : 'none' }}>
                      {
                        item.projects && Array.isArray(item.projects.info.list) && item.projects.info.list ? (
                          <div className="childList">
                            {
                              item.projects.info.list.map((el, index1) => (
                                <div className="contract-ct" key={index1}>
                                  <div className="title-flex">
                                    {/* className="budgetFlex" */}
                                    <div>
                                      <span className="fontW700">{el.project_name || '-'}</span>
                                      <span className="mlr5">|</span>
                                      项目预算：
                                      {
                                        el.showBudgetSetInput ? (
                                          <Input ref={inputRef} onBlur={(e) => setProjectBudget(e.target.value, index, index1)} onChange={(e) => { setInputValue(e.target.value, index, index1); }} onKeyDown={(e) => textareaKeyDown(e, index, index1)} className="budgetInputV2300PC" value={el.save_budget_money} maxLength="11" />
                                        ) : (
                                          el.budget_money ? (
                                            <>
                                              <span className="v2210_moneyUnitMR3">¥</span>
                                              {`${Number(el.budget_money)?.toFixed(2)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                                              <EditOutlined className="editebudgetTxt" onClick={() => openSetBudgetInput(index, index1)} />
                                            </>
                                          ) : (
                                            <span className="editebudgetTxt" onClick={() => openSetBudgetInput(index, index1)}>点击输入预算</span>
                                          )
                                        )
                                      }
                                    </div>
                                    {
                                      item.projects && item.projects.info && Array.isArray(item.projects.info.formula_explain) && item.projects.info.formula_explain.length ? (
                                        <div className="V2300PCTooltipMain mL8">
                                          <Tooltip arrowPointAtCenter placement="bottomRight" getPopupContainer={(triggerNode) => triggerNode.parentNode} title={item.projects.info.formula_explain.map((formulaItem, formulaIndex) => <div key={formulaIndex}>{formulaItem}</div>)}>
                                            <img src={contractTipImg} className="tipsImg" />
                                          </Tooltip>
                                        </div>  
                                      ) : ''
                                    }
                                  </div>
                                  <div className="mt8 money-date">
                                    <div className="flex1">
                                      剩余预算：
                                      {
                                        el.budget_money && (Number(el.budget_money) - Number(el.sjzc)) ? (
                                          <span className="remainingMoneyTxt">
                                            <span className="v2210_moneyUnitMR3">¥</span>
                                            {`${(Number(el.budget_money) - Number(el.sjzc))?.toFixed(2)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                                          </span>
                                        ) : '-'
                                      }
                                    </div>
                                    <div className="flex1 mL8">
                                      {
                                        msg?.info?.parameter?.old_project_version === '1' ? '当前收入：' : '实际收入：'
                                      }
                                      {
                                        el.sjsr ? (
                                          <>
                                            <span className="v2210_moneyUnitMR3">¥</span>
                                            {`${el.sjsr?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                                          </>
                                        ) : '-'
                                      }
                                    </div>
                                  </div>
                                  <div className="mt8 money-date">
                                    <div className="flex1">
                                      {
                                        msg?.info?.parameter?.old_project_version === '1' ? '当前支出：' : '实际支出：'
                                      }
                                      {
                                        el.sjzc ? (
                                          <>
                                            <span className="v2210_moneyUnitMR3">¥</span>
                                            {`${el.sjzc?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                                          </>
                                        ) : '-'
                                      }
                                    </div>
                                    <div className="flex1 mL8">
                                      {
                                        msg?.info?.parameter?.old_project_version === '1' ? '当前利润：' : '实际利润：'
                                      }
                                      {
                                        el.sjlr ? (
                                          <>
                                            <span className="v2210_moneyUnitMR3">¥</span>
                                            {`${el.sjlr?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                                          </>
                                        ) : '-'
                                      }
                                    </div>
                                  </div>
                                  {/* <div className="mt8">
                                    <div className="w260">
                                      实际利润：
                                      {
                                        el.sjlr ? (
                                          <>
                                            <span className="v2210_moneyUnitMR3">¥</span>
                                            {`${el.sjlr?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                                          </>
                                        ) : '-'
                                      }
                                    </div>
                                  </div> */}
                              
                                </div>
                              ))
                            }
                            {/* 显示展示多少项目 */}
                            {
                              item.projects.info.total > item.projects.info.list.length ? (
                                <div className="selLinkContract" onClick={() => { appendBudgetContractChild(msg, item, appendMessage); }}>
                                  查看全部
                                  {item.projects.info.total}
                                  个关联项目  
                                  <span className="mlr5">&gt;</span>
                                </div>
                              ) : ''
                            }
                          </div>
                        ) : ''
                      }
                    </div>
                  </div>
                </div>
              ))
            }
            {
              Number(totalPage) > prePage
              ? (
                <Pagination current={Number(current)} hideOnSinglePage pageSize={Number(prePage)} onChange={(page, pageSize) => onChangePageSize(page, pageSize)} className="v1124_pageinationPC" size="small" showSizeChanger={false} total={Number(totalPage)} />
              ) : ''
            }
          </div>
        ) : ''
      }
    </div>
  );
}
