/*
pc报销组件
 * @Author: your name
 * @Date: 2021-12-08 17:13:50
 * @LastEditTime: 2022-07-28 11:27:35
 * @LastEditors: 张立鹏 2475171028@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \Signser-web\src\containers\MobileRate\index.js
 */
import React, { useState } from 'react';
import '@/style/rate.scss';
import PropTypes from 'prop-types';
import { Pagination, Tooltip } from 'antd';
import success from '@/images/success.png';
// eslint-disable-next-line
import request from '@/utils/request';
import { useMount } from '../../utils/hooks';
import loading from '@/images/loading.png';

export default function Rate({ msg }) {
  Rate.propTypes = {
    msg: PropTypes.object,
  };
  // status参数说明: 0：未处理；1：已驳回；2：已同意申请 3：拒绝付款；4：申请人撤销申请；5：付款失败；6：已付款撤销；7：付款成功；9：付款中；
  const list = msg?.info.list;
  const twoStatus = ['待审批', '已驳回', '已通过', '已通过', '待审批', '已通过', 6, '已通过', '已通过', '已通过'];
  const threeStatus = ['待付款', '待付款', '待付款', '已拒绝', '待付款', '付款失败', 6, '已付款', '付款失败', '付款中'];
  const twoTime = [0, 4];
  const twoName = [4];
  const twoIcon = [5, 7, 9, 8];
  const threeTime = [...twoIcon, 3];
  const threeName = [...twoIcon, 2, 3];
  const threeNoaActive = [0, 1, 2, 4];
  const [pageSatus, setPageStatus] = useState(true);
  const [current, setCurrent] = useState(1);
  const [total, setTotal] = useState(msg.info?.total || 0); // list总数
  const [prePage, setPrePage] = useState(msg.info?.per_page || 5); // 每页展示的条数
  const [content, setContent] = useState(null); // 内容渲染
  const [loadingStatus, setLoadingStatus] = useState(false);

  useMount(() => {
    if (!pageSatus) {
      if (current > Number(msg.info.total_page) || current === 0) return;
      setLoadingStatus(true);
      request.instanceZhangkuan.get(`${process.env.REACT_APP_ZK}/${msg.info.api}`, {
        params: {
          ...msg.parameter,
          per_page: 3,
          current_page: current,
        },
      }).then((res) => {
        setLoadingStatus(false);
        setTotal(res.info.total);
        setPrePage(res.info.per_page);
        setContent([...res.info.list]);
      });
    }
  }, [current]);
  useMount(() => {
    let timer;
    if (pageSatus) {
      timer = setTimeout(() => {
        Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollTop = Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollHeight;
      }, 300);
      return () => {
        clearTimeout(timer);
      };
    }
    return () => {
      // eslint-disable-next-line
      timer && clearTimeout(timer);
    };
  });
  // 切换页码
  function onChange(page) {
    setCurrent(page);
    setPageStatus(false);
  }
  return (
    <div className={loadingStatus ? 'rate-list v1123_relative' : 'rate-list'}>
      <div className={loadingStatus ? 'mask' : 'none'} />
      <div className={loadingStatus ? 'loading' : 'none'}>
        <img src={loading} alt="" />
      </div>
      {
        list && (content || list).map((item) => (
          <div className="rate" key={item.id}>
            <div className="step">
              <div className="stepImg">
                <img src={success} alt="" />
              </div>
              <div className="line" />
              <div className="step-two">
                {twoIcon.includes(item.status) ? <img src={success} alt="" /> : <div className="num">2</div>}
              </div>
              <div className={threeNoaActive.includes(item.status) ? 'line noactive' : 'line'} />
              <div className={threeNoaActive.includes(item.status) ? 'step-two noactiveIcon' : 'step-two'}>
                <div className="num">3</div>
              </div>
            </div>
            <div className="tijiao">
              <div className="stepW184">
                {item.status === 4 ? '已撤销' : `提交${msg.leb}申请`}
              </div>
              <div className="stepW184">{item.status === 1 ? <span className="stepDesTxt">已驳回</span> : twoStatus[item.status]}</div>
              {/* eslint-disable-next-line */}
              <div>{item.status === 5 || item.status === 3 || item.status === 8 ? <span className="stepDesTxt">{threeStatus[item.status]}</span> : threeStatus[item.status]}</div>
            </div>
            <div className="name">
              <div className="stepW184">
                <div>{item.apply_user}</div>
                <div>{item.apply_time}</div>
              </div>
              <div className="stepW184">
                <div className={twoName.includes(item.status) ? 'none' : ''}>{item.approvl_user}</div>
                <div className={twoTime.includes(item.status) ? 'none' : ''}>{item.approvl_time}</div>
              </div>
              <div className="txt-ellipsis tooltipFontWeight400Size12">
                <div className={threeName.includes(item.status) ? '' : 'none'}>
                  <Tooltip placement="topLeft" getPopupContainer={(triggerNode) => triggerNode.parentNode} title={item.payment_user || '-'}>
                    <span>{item.payment_user}</span>
                  </Tooltip>
                </div>
                <div className={threeTime.includes(item.status) ? '' : 'none'}>
                  <Tooltip placement="topLeft" getPopupContainer={(triggerNode) => triggerNode.parentNode} title={item.payment_time || '-'}>
                    <span>{item.payment_time}</span>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        ))
      }
      {
        total ? (<Pagination hideOnSinglePage pageSize={prePage} onChange={(page, pageSize) => onChange(page, pageSize)} className="v1124_pageinationPC" size="small" showSizeChanger={false} total={total} />) : ('')
      }
    </div>
  );
}
