/*
 * @Author: your name
 * @Date: 2021-09-27 10:07:47
 * @LastEditTime: 2021-11-11 14:46:30
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \Signser-web\src\until\plupload.js
 */
/*eslint-disable*/
import plupload from 'plupload';

const initSinglePlupload = () => {
    const uploader = new plupload.Uploader({
        browse_button: 'upload',
        runtimes: 'html5,flash,silverlight,html4',
        url: 'https://signser-ali-asr-voice.oss-cn-hangzhou.aliyuncs.com',
        unique_names: true,
    });
    return uploader;
}

export default initSinglePlupload;