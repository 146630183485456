/*
pc端流水组件
 * @Author: your name
 * @Date: 2021-12-07 14:45:10
 * @LastEditTime: 2022-07-28 11:29:42
 * @LastEditors: 张立鹏 2475171028@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \Signser-web\src\containers\MoneyDetail\index.js
 */
import React, { useState } from 'react';
import { Pagination, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import '@/style/moneyDetail.scss';
// eslint-disable-next-line
import request from '@/utils/request';
import { useMount } from '../../utils/hooks';
import loading from '@/images/loading.png';

export default function MoneyDetail({ msg }) {
  MoneyDetail.propTypes = {
    msg: PropTypes.object,
  };
  const dataArr = msg?.info.info;
  const [pageSatus, setPageStatus] = useState(true);
  const [current, setCurrent] = useState(1);
  const [total, setTotal] = useState(msg.info?.total || 0); // list总数
  const [prePage, setPrePage] = useState(msg.info?.per_page || 5); // 每页展示的条数
  const [content, setContent] = useState(null);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const typeStatus = [0, '客户', '客户', '收款人', '客户', '', '供应商', '客户', '客户', '供应商', '', '客户', '收款人', '收款方', '收款人'];
  useMount(() => {
    if (!pageSatus) {
      if (current > Number(msg.info.total_page) || current === 0) return;
      setLoadingStatus(true);
      request.instanceZhangkuan.get(`${process.env.REACT_APP_ZK}/${msg.info.api}`, {
        params: {
          ...msg.parameter,
          per_page: 5,
          current_page: current,
        },
      }).then((res) => {
        setLoadingStatus(false);
        setTotal(res.info.total);
        setPrePage(res.info.per_page);
        setContent([...res.info.info]);
      });
    }
  }, [current]);

  useMount(() => {
    let timer;
    if (pageSatus) {
      timer = setTimeout(() => {
        Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollTop = Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollHeight;
      }, 300);
      return () => {
        clearTimeout(timer);
      };
    }
    return () => {
      // eslint-disable-next-line
      timer && clearTimeout(timer);
    };
  });
  // 切换页码
  function onChange(page) {
    setCurrent(page);
    setPageStatus(false);
  }
  return (
    <div className={loadingStatus ? 'moneyDetail v1123_relative' : 'moneyDetail'}>
      <div className={loadingStatus ? 'mask' : 'none'} />
      <div className={loadingStatus ? 'loading' : 'none'}>
        <img src={loading} alt="" />
      </div>
      {
        dataArr && (content || dataArr).map((item, index) => (
          // eslint-disable-next-line
          <div className="money" key={index}>
            <div className="money-date flex-alignCenter">
              {item.date}
            </div>
            <div className="flex-alignCenter flex1 overflowHidden">
              <div className="money-description overflowHidden">
                <div className="flex">
                  <div>摘要：</div>
                  <div className="flex1">{item.description}</div>
                </div>
                <div className={typeStatus[item.type] ? 'mt4 flex' : 'none'}>
                  <div>
                    {typeStatus[item.type]}
                    ：
                  </div>
                  <div className="txt-ellipsis flex1 tooltipFontWeight400Size12">
                    <Tooltip placement="topLeft" getPopupContainer={(triggerNode) => triggerNode.parentNode} title={item.name || '-'}>
                      <span>{item.name}</span>
                    </Tooltip>
                  </div>
                </div>
              </div>
              <div className="money-type mL8 overflowHidden">
                <div className="type-label">
                  {Number(item.money) >= 0 ? '收入' : '支出'}
                  ：
                </div>
                <div className="txt-ellipsis tooltipFontWeight400Size12">
                  <Tooltip placement="topLeft" getPopupContainer={(triggerNode) => triggerNode.parentNode} title={item.money.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || '-'}>
                    {Number(item.money) >= 0 ? <span className="color_3DCC68">{`+${item.money?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}</span> : <span className="color_FF475A">{item.money.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>}
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        ))
      }
      {
        total ? (<Pagination hideOnSinglePage pageSize={prePage} onChange={(page, pageSize) => onChange(page, pageSize)} className="v1124_pageinationPC" size="small" showSizeChanger={false} total={total} />) : ('')
      }
    </div>
  );
}
