/*
 * pc端查所有账户组件
 * @Author: your name
 * @Date: 2021-12-06 09:51:46
 * @LastEditTime: 2022-07-28 18:29:23
 * @LastEditors: 张立鹏 2475171028@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \Signser-web\src\AllAccounts\index.js
 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import '@/style/allAccounts.scss';
import wei from '@/images/wei@2x.png';
import xianjin from '@/images/xianjin@2x.png';
import zhi from '@/images/zhi@2x.png';
import qita from '@/images/qita.png';
import yinhangka from '@/images/yinhangka.png';
// eslint-disable-next-line import/extensions
import { accountList } from '@/apis/http';
import { useMount, useScrollBottom } from '../../utils/hooks';
import Robot from '@/images/robot_logo.png';

// eslint-disable-next-line no-unused-vars
export default function AllAccounts({ allAccountCallback, appendMessage, msg }) {
  AllAccounts.propTypes = {
    allAccountCallback: PropTypes.func, // 发送消息等待期间的过渡动画
    appendMessage: PropTypes.func, // 发送消息方法
    msg: PropTypes.object, // 消息详情
  };
  const [boxWidth, setBoxWidth] = useState(document.body.clientWidth - 32 - 40 - 8 - 32); //  窗口宽度
  const [boxListNum, setboxListNum] = useState(0); //  一排可以放几个元素
  useScrollBottom();
  const [accounts, setAccounts] = useState([
    {
        icon: yinhangka,
        leb_name: '银行账户',
        leb: 1,
        leb_num: 0,
        link: '/api/ap/account/list',
    },
    {
        icon: xianjin,
        leb_name: '现金账户',
        leb: 2,
        leb_num: 0,
        link: '/api/ap/account/list',
    },
    {
        icon: zhi,
        leb_name: '支付宝账户',
        leb: 3,
        leb_num: 0,
        link: '/api/ap/account/list',
    },
    {
        icon: wei,
        leb_name: '微信账户',
        leb: 4,
        leb_num: 0,
        link: '/api/ap/account/list',
    },
    {
        icon: qita,
        leb_name: '其他账户',
        leb: 5,
        leb_num: 0,
        link: '/api/ap/account/list',
    },
  ]); // 初始化accounts数据为零时后端不返回苏剧则用我们初始化的数据
  
  useMount(() => {
    const list = msg?.info.list;
    const list2 = accounts;
    if (list && list.length) {
      list.forEach((item) => {
        list2[item.leb - 1].leb_num = item.leb_num;
      });
      setPageWidth(boxWidth, setboxListNum, setBoxWidth);
      setAccounts([...list2]);
    }
  });
  // eslint-disable-next-line no-unused-vars
  function hanldeHeight() {
    setPageWidth(document.body.clientWidth - 32 - 40 - 8 - 32, setboxListNum, setBoxWidth);
  }

  // 设置页面宽度
  function setPageWidth(boxWidth1, setboxListNum1, setBoxWidth1) {
    // eslint-disable-next-line radix
    const listNum = parseInt((boxWidth1 + 8) / 132);
    const listWidth = boxWidth1 >= (listNum * 132 - 8) ? (listNum * 132 - 8) : boxWidth1;
    setboxListNum1(listNum);
    setBoxWidth1(listWidth);
  }
  // 监听窗口变化
  useEffect(() => {
    window.addEventListener('resize', hanldeHeight);
    return () => {
      window.removeEventListener('resize', hanldeHeight);
    };
  }, []);
  /**
   * @description 点击查看指定账户
   * @param {*} number 账户数量 数量为0时不可点击
   * @param {*} leb 账户类型
   * @returns 
   */
  function handleAccount(number, leb) {
    if (number === 0) return;
    allAccountCallback();
    accountList({
      ...msg.info.parameter,
      leb: leb.toString(),
      per_page: '3',
      current_page: '1',
    }).then((res) => {
      appendMessage({ text: res }, 'left', 'nextPage', Robot, true, 'Accounts');
    });
  }
    return (
      <div className="allAccounts" style={{ width: `${boxWidth}px` }}>
        {
            accounts.length && accounts.map((element, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div style={{ marginRight: (boxListNum === index + 1 ? '0' : '8px') }} className={element.leb_num === 0 ? 'accountsItem normal' : 'accountsItem'} key={index} onClick={() => handleAccount(element.leb_num, element.leb)}>
                <div className="flex-alignCenter">
                  <img src={element.icon} alt="" className="itemImg" />
                  {element.leb_name}
                </div>
                <div>
                  {element.leb_num} 
                  个
                </div>  
              </div>
              ))
        }
      </div>
    );
}
