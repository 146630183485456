/*
 * @Author: your name
 * @Date: 2021-11-05 16:51:19
 * @LastEditTime: 2021-11-05 16:53:45
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \Signser-web\src\utils\context.js
 */
import { createContext } from 'react';

export default createContext();
