/* eslint-disable max-len */
/* eslint-disable prefer-const */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/*
 * 坏账子合同 32
 * @Author: your name
 * @Date: 2022-04-14 18:17:47
 * @LastEditTime: 2022-06-23 18:42:51
 * @LastEditors: 张立鹏 2475171028@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \signser-web\src\containers\Contacts\index.js
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import 'moment/locale/zh-cn';
import './baddebt.scss';
import { useMount } from '../../../../utils/hooks';
import right from '@/images/icon-right.png';
// eslint-disable-next-line import/extensions
import request from '@/utils/request';
import loading from '@/images/loading.png';

let start = false;
export default function MobileArBaddebtContractChild({ msg }) {
  MobileArBaddebtContractChild.propTypes = {
    msg: PropTypes.object,
  };
  let [force, setForce] = useState(msg?.info?.list);
  const [pageSatus, setPageStatus] = useState(true);
  const [current, setCurrent] = useState(1);
  const [loadingStatus, setLoadingStatus] = useState(false);
  let [pickArr, setPickArr] = useState([]);
  // console.log(msg, 'msg');
  useMount(() => {
    if (current > 1) {
      request.instanceZhangkuan.get(`${process.env.REACT_APP_ZK}/${msg.info.api}`, {
        params: {
          ...msg.info.parameter,
          per_page: msg.info.per_page,
          current_page: current,
        },
      }).then((res) => {
        setLoadingStatus(false);
        let list = res.info.list || [];
        if (current < 3) {
          force = [...force, ...list];
          setForce(force);
        } else {
          setForce([...force, ...list]);
        }
      });
    }
  }, [current]);

  useMount(() => {
    let timer;
    if (pageSatus) {
      timer = setTimeout(() => {
        Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollTop = Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollHeight;
      }, 600);
      return () => {
        clearTimeout(timer);
      };
    }
    return () => {
      // eslint-disable-next-line
      timer && clearTimeout(timer);
    };
  });

  // 下一页
  function nextPage() {
    start = false;
    const time = setTimeout(() => {
      if (!start) {
        if (pickArr.length) {
          setPickArr([]);
          return;
        }
        setLoadingStatus(true);
        setCurrent(current + 1);
        setPageStatus(false);
      }
      clearTimeout(time);
    }, 100);
  }

  // 收起
  function packUp() {
    start = false;
    const time = setTimeout(() => {
      if (!start) {
        setPickArr(force.slice(0, msg.info.per_page ? msg.info.per_page : 3));
      }
      clearTimeout(time);
    }, 100);
  }
  function onTouchMove() {
    start = true;
  }
  return (
    <div className="v2200MobileArBaddebtContractIndex" style={{ minWidth: 'calc(540rem/75)', paddingBottom: Number(msg.info.total) > msg.info.per_page ? 'calc(52rem/75)' : '0' }}>
      {
        // eslint-disable-next-line max-len
        Array.isArray((pickArr.length ? pickArr : force)) && (pickArr.length ? pickArr : force).length ? (
          <div className="childMain">
            <div className="list-box">
              {
                (pickArr.length ? pickArr : force).map((el, index) => (
                  <div className="list-main" key={index}>
                    <div className="title">{el.contract_name}</div>
                    <div className="followup_name">
                      <span className="color-909399">跟进人：</span>
                      {el.followup_name}
                    </div>
                    <div className="mt8">
                      <div>
                        <span className="color-909399">坏账金额：</span>
                        <span className="color-FF475A">
                          <span className="v2210_moneyUnitMobileMR3">¥</span>
                          {`${el.bad_money?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                        </span>
                        <span className="mlr8">/</span>
                        <span className="v2210_moneyUnitMobileMR3">¥</span>
                        {`${el.contract_money?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                      </div>
                    </div>
                    <div className="mt8">
                      <span className="color-909399">坏账日期：</span>
                      {el.max_date}
                    </div>
                    <div className="mt8">
                      <span className="color-909399">坏账原因：</span>
                      {el.reason || '-'}
                    </div>
                
                  </div>
                ))
              }
            </div>
            {/* 分页 */}
            <div className={Number(msg.info.total) > msg.info.per_page ? 'v1124_filterBottomMobile  v2200_filterBottomMobile' : 'none'}>
              {/* <div className="filter" /> */}
              <div className="flex ismore" style={{ height: 'calc(48rem/75)' }}>
                {/* eslint-disable-next-line */}
                <div className={current >= Number(msg.info.total_page) && pickArr.length === 0 ? 'none' : 'more'} onTouchStart={() => loadingStatus ? '' : nextPage()} onTouchMove={onTouchMove}>{loadingStatus ? <div className="loading"><img src={loading} alt="" /></div> : '查看更多'}</div>
                <div className={current > 1 && pickArr.length === 0 ? 'flex align-item left pick-style' : 'none'} style={{ position: 'absolute', right: 0 }} onTouchStart={packUp} onTouchMove={onTouchMove}>
                  <div className="pack-up">
                    收起
                  </div>
                  <div style={{ width: 'calc(28rem/75)', height: 'calc(28rem/75)' }}>
                    <img src={right} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : ''
      }
    </div>
  );
}
