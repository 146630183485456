/*
 * @Author: your name
 * @Date: 2021-10-25 10:08:24
 * @LastEditTime: 2022-07-22 09:45:27
 * @LastEditors: 张立鹏 2475171028@qq.com
 * @Description: In User Settings Edit
 * @FilePath: \signser\src\containers\Text\Text.js
 */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/extensions
import Context from '@/utils/context.js';
import '@/style/text.scss';
import MobileText from '../MobileText';
import PCText from '../PCText';
// eslint-disable-next-line
export default function Text({ msg, pcSend, failSend, allAccountCallback, appendMessage, sendFAQ, kfID, deleteMsg, textID, macCode, setIsShowFooterInput }) {
    const device = useContext(Context); // 首页传的设备类型
    Text.propTypes = {
        msg: PropTypes.object,
        pcSend: PropTypes.func,
        failSend: PropTypes.func,
        allAccountCallback: PropTypes.func,
        appendMessage: PropTypes.func,
        sendFAQ: PropTypes.func,
        setIsShowFooterInput: PropTypes.func,
        kfID: PropTypes.string,
        deleteMsg: PropTypes.func,
        textID: PropTypes.string,
        macCode: PropTypes.func,
    };
    
    return (
      // eslint-disable-next-line no-nested-ternary
      <div className={['Android', 'iPhone'].indexOf(device) >= 0 ? '' : (msg.position === 'left' ? 'v23100PCTextLeftMain' : 'v23100PCTextRightMain')}>
        { ['Android', 'iPhone'].indexOf(device) >= 0 ? <MobileText setIsShowFooterInput={setIsShowFooterInput} msg={msg} pcSend={pcSend} failSend={failSend} allAccountCallback={allAccountCallback} appendMessage={appendMessage} sendFAQ={sendFAQ} kfID={kfID} deleteMsg={deleteMsg} textID={textID} macCode={macCode} /> : <PCText setIsShowFooterInput={setIsShowFooterInput} msg={msg} pcSend={pcSend} failSend={failSend} appendMessage={appendMessage} allAccountCallback={allAccountCallback} sendFAQ={sendFAQ} kfID={kfID} deleteMsg={deleteMsg} textID={textID} macCode={macCode} /> }
      </div>
    );
}
