/*
移动Knowledge组件
 * @Author: your name
 * @Date: 2021-10-27 10:06:42
 * @LastEditTime: 2022-04-27 17:17:24
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \signser\src\containers\MobileKnowledge\index.js
 */
/*eslint-disable*/
import React from 'react';
import PropTypes from 'prop-types';
import '@/style/MobileKnowledge.scss';
import WxImageViewer from 'react-wx-images-viewer';
// eslint-disable-next-line
import { useScrollBottom } from '@/utils/hooks';
import { useMount } from '../../utils/hooks';

let startImg = false;
export default function MobileKnowledge({ msg, pcSend }) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [imgs, setImgs] = React.useState([]);
  const [index, setIndex] = React.useState(0);
  MobileKnowledge.propTypes = {
    msg: PropTypes.object,
    pcSend: PropTypes.func,
  };
  useScrollBottom();
  // 展示图片大图
  let timer = null;
  function bigImg(e) {
    startImg = false;
    if (e.target.tagName === 'IMG') {
      // eslint-disable-next-line no-unused-expressions
      timer && clearTimeout(timer);
      timer = setTimeout(() => {
        setIndex(0);
        if (!startImg) {
          setIsOpen(true);
          setImgs([e.target.src]);
        }
      }, 300);
    }
  }
  // 取消图片大图
  function cancelMask() {
    setIsOpen(false);
  }
  function onTouchMove() {
    startImg = true;
  }
  // 发送消息
  function send(title) {
    pcSend(title);
  }
  useMount(() => {
    Array.from(document.getElementsByClassName('knowledge-height')).forEach((item) => {
      item.onload = () => {
        const timerImg = setTimeout(() => {
          Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollTop = Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollHeight;
          clearTimeout(timerImg);
        }, 300);
      };
    })
    
  })
  return (
    <div className="MobileKnowledges">
      {/* eslint-disable-next-line */}
      <div className="MobileKnowledge" >
        <div dangerouslySetInnerHTML={{ __html: msg.content.text }} onTouchStart={(e) => bigImg(e)} onTouchMove={onTouchMove} />
        {
            msg.content && Array.isArray(msg.content.RelatedKnowledges) ? (
              <>
                <div style={{ color: '#909399', fontSize: 'calc(24rem/75)', margin: 'calc(16rem/75) 0', lineHeight: 'calc(34rem/75)' }}>猜您可能还想了解</div>
                {
                  msg.content.RelatedKnowledges.map((el, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div style={{ color: '#3377FF', cursor: 'pointer', fontSize: 'calc(24rem/75)', marginBottom: 'calc(16rem/75)' }} key={index} onTouchStart={() => send(el.Title)}>{el.Title}</div>
                  ))
                }
              </>
            ) : ''
          }
      </div>
      {isOpen ? <WxImageViewer onClose={() => cancelMask()} urls={imgs} index={index} /> : ''}
    </div>
  );
}
