/*
 * 移动端松开手指的样式
 * @Author: your name
 * @Date: 2021-11-23 15:08:19
 * @LastEditTime: 2022-01-25 18:04:46
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \Signser-web\src\containers\Cancel\index.js
 */
import React from 'react';
import PropTypes from 'prop-types';
import cancel from '@/images/quxiao.png';
import '@/style/cancel.scss';

export default function Cancel({ cancelSend }) {
    Cancel.propTypes = {
        cancelSend: PropTypes.bool,
    };
    return (
      <div className={cancelSend ? 'cancel-com' : 'none'}>
        <div className="cancel-img">
          <img src={cancel} alt="" />
        </div>
        <span>松开手指，取消发送</span>
      </div>
    );
}
