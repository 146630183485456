/*
移动端发票组件
 * @Author: your name
 * @Date: 2021-11-01 11:57:45
 * @LastEditTime: 2022-06-23 18:43:02
 * @LastEditors: 张立鹏 2475171028@qq.com
 * @Description: In User Settings Edit
 * @FilePath: \Signser-web\src\containers\MobileTextTable\index.js
 */
import React from 'react';
import PropTypes from 'prop-types';
import { useScrollBottom } from '../../utils/hooks';

export default function MobileTextTable({ msg }) {
    MobileTextTable.propTypes = {
        msg: PropTypes.object,
    };
    const { info } = msg;
    useScrollBottom();
    return (
      <div>
        <div style={{ width: 'calc(540rem/75)' }}>
          <br />
          <span style={{ color: '#FFAA00' }}>{info.electronic_bill_num}</span>
          &nbsp;&nbsp;张增值税电子普通发票
          <br />
          <span style={{ color: '#FFAA00' }}>{info.special_bill_num}</span>
          &nbsp;&nbsp;张增值税专用发票
          <br />
          <span style={{ color: '#FFAA00' }}>{info.normal_bill_num}</span>
          &nbsp;&nbsp;张增值税普通发票
          <br />
          <span style={{ color: '#FFAA00' }}>{info.contract_bill_num}</span>
          &nbsp;&nbsp;张合同发票
          <br />
          <span style={{ color: '#FFAA00' }}>{info.other_bill_num}</span>
          &nbsp;&nbsp;张其他发票
        </div>
      </div>
    );
}
