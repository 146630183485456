/* eslint-disable react/jsx-indent-props */
/* eslint-disable no-unused-expressions */
/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* 应收 已开票 未开票list
 * @Author: your name
 * @Date: 2022-04-19 18:46:09
 * @LastEditTime: 2022-04-27 10:32:14
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \signser-web\src\containers\v1.1.24_Mobile\ReceivableOrPayable\list.js
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import 'moment/locale/zh-cn';
import './receivableOrPayable.scss';
import { useMount } from '../../../utils/hooks';
// eslint-disable-next-line max-len
import { getIconImg, initMobileArr, updMobileDrownFlag } from '../../v1.1.24_PC/ReceivableOrPayable/utils/index';
// eslint-disable-next-line
import right from '@/images/icon-right.png';
// eslint-disable-next-line import/extensions
import request from '@/utils/request';
import loading from '@/images/loading.png';
import drownImg from '@/images/drown_1124.png';
import tipsImg from '@/images/tips-bill_20220426.png';

let start = false;
export default function List({ msg, activeKey }) {
  List.propTypes = {
    msg: PropTypes.object,
    activeKey: PropTypes.string,
  };
  // eslint-disable-next-line prefer-const
  let arr = initMobileArr(msg.info.list || []);
  // 查详情
  // eslint-disable-next-line prefer-const
  let [force, setForce] = useState(null);
  const [pageSatus, setPageStatus] = useState(true);
  const [current, setCurrent] = useState(1);
  const [loadingStatus, setLoadingStatus] = useState(false);
  // eslint-disable-next-line prefer-const
  let [pickArr, setPickArr] = useState([]);

  useMount(() => {
    if (current > 1) {
      request.instanceZhangkuan.get(`${process.env.REACT_APP_ZK}/${msg.info.api}`, {
        params: {
          ...msg.info.parameter,
          per_page: msg.info.per_page,
          current_page: current,
        },
      }).then((res) => {
        setLoadingStatus(false);
        const list = initMobileArr(res.info.list || []);
        if (current < 3) {
          arr = !force ? [...arr, ...list] : [...force, ...list];
          setForce(arr);
        } else {
          setForce([...force, ...list]);
        }
      });
    }
  }, [current]);

  useMount(() => {
    let timer;
    if (pageSatus) {
      timer = setTimeout(() => {
        Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollTop = Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollHeight;
      }, 300);
      return () => {
        clearTimeout(timer);
      };
    }
    return () => {
      // eslint-disable-next-line
      timer && clearTimeout(timer);
    };
  });

  // 下一页
  function nextPage() {
    start = false;
    const time = setTimeout(() => {
      if (!start) {
        if (pickArr.length) {
          setPickArr([]);
          return;
        }
        setLoadingStatus(true);
        setCurrent(current + 1);
        setPageStatus(false);
      }
      clearTimeout(time);
    }, 100);
  }

  // 收起
  function packUp() {
    start = false;
    const time = setTimeout(() => {
      if (!start) {
        setPickArr(force.slice(0, msg.info.per_page ? msg.info.per_page : 3));
      }
      clearTimeout(time);
    }, 100);
  }

  // icon 展示数据
  async function handleShowList(index, flag) {
    // eslint-disable-next-line prefer-destructuring
    const item = (pickArr.length ? pickArr : force || arr)[index];
    let requestArr = [];
    // 需要请求接口
    if (!item.requestFlag) {
      const params = {
        contract_ids: item.relation_ids,
      };
      if (activeKey !== '未开票') {
        params.bill_id = item.bill_id;
      }
      const api = activeKey === '未开票' ? 'api/ar/bill/custom/contract' : 'api/ar/bill/contract';
      const getApiInfo = await request.instanceZhangkuan.get(`${process.env.REACT_APP_ZK}/${api}`, { params });
      requestArr = getApiInfo?.info?.list;
    } 
    requestArr = Array.isArray(requestArr) ? requestArr : [];
    if (Array.isArray(arr)) {
      arr = updMobileDrownFlag(arr, index, flag, requestArr);
    }
    let newFocus = [];
    if (Array.isArray(force)) {
      newFocus = updMobileDrownFlag(force, index, flag, requestArr);
    } else {
       // 初始状态
      // eslint-disable-next-line no-lonely-if
      if (Array.isArray(arr) && force === null) {
        newFocus = updMobileDrownFlag(arr, index, flag, requestArr);
      }
    }
    Array.isArray(newFocus) && newFocus.length ? setForce(newFocus) : '';
    if (Array.isArray(pickArr) && pickArr.length) {
      pickArr = updMobileDrownFlag(pickArr, index, flag, requestArr);
      setPickArr(pickArr);
    }
  }

  function onTouchMove() {
    start = true;
  }
  return (
    <div className="receivableBillMobile" style={{ minWidth: 'calc(527rem/75)' }}>
      {
        activeKey === '未开票' ? (
          <div className="billList" style={{ paddingBottom: msg.info.total_page <= 1 ? '0' : 'calc(52rem/75)' }}>
            {(pickArr.length ? pickArr : force || arr).map((item, index) => (
                // eslint-disable-next-line react/jsx-indent
                <div className="noBill-item" key={index}>
                  <div className={item.drownFlag && Array.isArray(item.contracts) && item.contracts.length ? 'item-header heder-bgEAF2FE' : 'item-header'}>
                    <div className="header-name">{item.custom_name}</div>
                    <div>
                      { item.drownFlag ? '' : (
                        Array.isArray(item.contracts) && item.contracts.length ? (
                          <div className="link-contract" onClick={() => { handleShowList(index, item.drownFlag); }}>
                            <span>
                              关联
                              {item.relation_count}
                              个合同
                            </span>
                            <img src={drownImg} alt="" className={item.drownFlag ? 'img-up' : ''} />
                          </div>
                        ) : (
                          <div className="link-contract">
                            <span>关联0个合同</span>
                            <img src={drownImg} alt="" className={item.drownFlag ? 'img-up' : ''} />
                          </div>
                        )  
                      )}
                    </div>
                  </div>
                  {
                    item.drownFlag && Array.isArray(item.contracts) && item.contracts.length ? (
                      <div className="item-content">
                        <div className="link-contract link-bottom" onClick={() => { handleShowList(index, item.drownFlag); }}>
                          <span>
                            关联
                            {item.relation_count}
                            个合同
                          </span>
                          <img src={drownImg} alt="" className="img-up" />
                        </div>
                        {
                          item.contracts.map((linkItem, linkIndex) => (
                            <div className="list-item" key={linkIndex}>
                              <div className="index-name flex">
                                <div className="index-number">
                                  <div className="index-txt">{linkIndex + 1}</div>
                                </div>
                                <div className="flex1 txt-color333333">{linkItem.contract_name}</div>
                              </div>
                              <div className="mt18 flex ml40 txt-color333333">
                                <div className="money-peopleL">
                                  <span className="v2210_moneyUnitMobileMR3">¥</span>
                                  {`${linkItem.unbill_money?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                                </div>
                                <div className="money-peopleR">
                                  {
                                    msg.info.parameter.department_name || msg.info.parameter.is_my_department ? (linkItem.depart_name) : (linkItem.followup_name)
                                  }
                                  
                                </div>
                              </div>
                              <div className="mt6 flex ml40 txt-color909399">
                                <div className="money-peopleL">未开票金额</div>
                                <div className="money-peopleR">
                                  {
                                    msg.info.parameter.department_name || msg.info.parameter.is_my_department ? '所属部门：' : '跟进人'
                                  }
                                </div>
                              </div>
                            </div>
                          ))
                        }

                        {/* 展示提示信息 */}
                        {
                          item.drownFlag && item.relation_count > 10 ? (
                            <div className="show-tips ml24">
                              <img src={tipsImg} alt="" />
                              <div>
                                其余
                                {item.relation_count - item.contracts.length}
                                个关联合同可前往账款管家查看
                              </div>
                            </div>
                          ) : ''
                        }
                      </div>
                    ) : ''
                  }
                </div>
              ))}
          </div>  
        ) : (
          <div className="billList" style={{ paddingBottom: msg.info.total_page <= 1 ? '0' : 'calc(52rem/75)' }}>
            {(pickArr.length ? pickArr : force || arr).map((item, index) => (
              // eslint-disable-next-line
              <div className="content-item" key={index}>
                <div className="item-header">
                  <div className="customer-name">
                    {item.custom_name}
                  </div>
                  <div className="number-date">
                    {item.billing_number}
                    <span className="ml5">|</span>
                    {item.billing_date}
                  </div>
                </div>
                <div className="item-bill item-bill_pt56">
                  {/* item头部内容 */}
                  <div className="flex bill-title">
                    <div className="img-txt">
                      <img src={getIconImg(item.bill_type)} alt="" />
                      <div>{item.bill_type}</div>
                    </div>
                    <div className="txtAlignR">
                      <span className="v2210_moneyUnitMobileMR3">¥</span>
                      {`${item.billing_money?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                    </div>
                  </div>
                  <div className="flex">
                    <div className="txt-des">
                      税额
                      <span className="ml5" />
                      <span className="v2210_moneyUnitMobileMR3">¥</span>
                      {`${item.tax?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                    </div>
                    <div className="txtAlignR txt-des">开票金额</div>
                  </div>
                  <div className="flex">
                    <div className="txt-des">
                      税率 
                      <span className="ml5" />
                      {item.tax_rate}
                    </div>
                    <div />
                  </div>
                  {/* 线条 */}
                  <div className={item.drownFlag ? 'bottom-line' : ''} />
                  {
                    <>
                      {
                        item.leb === 0 ? (
                          <>
                            {
                              Array.isArray(item.contracts) && item.contracts.length ? (
                                <div className="txt-des link-drown_txt" onClick={() => { handleShowList(index, item.drownFlag); }}>
                                  <span>
                                    关联
                                    {item.relation_count}
                                    个合同
                                  </span>
                                  <img src={drownImg} alt="" className={item.drownFlag ? 'img-up' : ''} />
                                </div>
                              ) : ''
                            }
                            {
                              item.drownFlag ? (
                                <div className="contract-main">
                                  {
                                    Array.isArray(item.contracts) ? (
                                      item.contracts.map((listItem, listItemIndex) => (
                                        <div className="contract-item" key={listItemIndex}>
                                          <div className="item-line">
                                            <div className="contract-label">
                                              关联合同
                                              {listItemIndex + 1}
                                              ：
                                            </div>
                                            <div className="contract-txt">{listItem.contract_name}</div>
                                          </div>
                                          {
                                            // eslint-disable-next-line max-len
                                            msg.info.parameter.department_name || msg.info.parameter.is_my_department 
                                            // eslint-disable-next-line operator-linebreak
                                            ?
                                            (
                                              <div className="item-line">
                                                <div className="contract-label">所属部门：</div>
                                                <div className="contract-txt">{listItem.depart_name}</div>
                                              </div>
                                            ) : (
                                              <div className="item-line">
                                                <div className="contract-label">跟进人：</div>
                                                <div className="contract-txt">{listItem.followup_name}</div>
                                              </div>
                                            )
                                          }
                                          {
                                            Array.isArray(listItem.children) ? (
                                              listItem.children.map((childItem, childIndex) => (
                                                <div className="contract-child" key={childIndex}>
                                                  <div>
                                                    <span>{childItem.contract_number}</span>
                                                    <span className="ml5">开票金额：</span>
                                                    <span>
                                                      <span className="v2210_moneyUnitMobileMR3">¥</span>
                                                      {`${childItem.contract_billing_money?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                                                    </span>
                                                  </div>
                                                </div>
                                              ))
                                            ) : (
                                              <div className="item-line">
                                                <div className="contract-label">开票金额：</div>
                                                <div className="contract-txt">
                                                  <span className="v2210_moneyUnitMobileMR3">¥</span>
                                                  {`${listItem.contract_billing_money?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                                                </div>
                                              </div>
                                            )
                                          }
                                        </div>
                                      ))
                                    ) : ''
                                  }
                                  {
                                    item.drownFlag && item.relation_count > 10 ? (
                                      <div className="show-tips">
                                        <img src={tipsImg} alt="" />
                                        <div>
                                          其余
                                          {item.relation_count - item.contracts.length}
                                          个关联合同可前往账款管家查看
                                        </div>
                                      </div>
                                    ) : ''
                                  }
                                </div>
                              ) : ''
                            }
                          </>
                        ) : (
                          <>
                            <div className="txt-des link-drown_txt" onClick={() => { handleShowList(index, item.drownFlag); }}>
                              <span> 关联1个项目 </span>
                              <img src={drownImg} alt="" className={item.drownFlag ? 'img-up' : ''} />
                            </div>
                            {
                              item.drownFlag ? (
                                <div className="contract-main">
                                  <div className="contract-item">
                                    <div className="item-line">
                                      <div className="contract-label">关联项目1：</div>
                                      <div className="contract-txt">{item.project_name}</div>
                                    </div>
                                    {
                                      // eslint-disable-next-line max-len
                                      msg.info.parameter.department_name || msg.info.parameter.is_my_department 
                                      // eslint-disable-next-line operator-linebreak
                                      ?
                                      (
                                        <div className="item-line">
                                          <div className="contract-label">所属部门：</div>
                                          <div className="contract-txt">{item.depart_name}</div>
                                        </div>
                                      ) : (
                                        <div className="item-line">
                                          <div className="contract-label">项目成员：</div>
                                          <div className="contract-txt">{item.project_members}</div>
                                        </div>
                                      )
                                    }
                                    <div className="item-line">
                                      <div className="contract-label">开票金额：</div>
                                      <div className="contract-txt">
                                        <span className="v2210_moneyUnitMobileMR3">¥</span>
                                        {`${item.billing_money?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : ''
                            }
                          </>
                        )
                      }
                    </>
                  }
    
                </div>
              </div>
            ))}
          </div>
        )
      }
      <div className={Number(msg.info.total) > msg.info.per_page ? 'v1124_filterBottomMobile' : 'none'}>
        {/* <div className="filter" /> */}
        <div className="flex ismore" style={{ height: 'calc(48rem/75)' }}>
          {/* eslint-disable-next-line */}
          <div className={current >= Number(msg.info.total_page) && pickArr.length === 0 ? 'none' : 'more'} onTouchStart={() => loadingStatus ? '' : nextPage()} onTouchMove={onTouchMove}>{loadingStatus ? <div className="loading"><img src={loading} alt="" /></div> : '查看更多'}</div>
          <div className={current > 1 && pickArr.length === 0 ? 'flex align-item left pick-style' : 'none'} style={{ position: 'absolute', right: 0 }} onTouchStart={packUp} onTouchMove={onTouchMove}>
            <div className="pack-up">
              收起
            </div>
            <div style={{ width: 'calc(28rem/75)', height: 'calc(28rem/75)' }}>
              <img src={right} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
