/* eslint-disable no-unused-vars */
/* eslint-disable no-unreachable */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/jsx-first-prop-new-line */
/* eslint-disable react/self-closing-comp */
/* eslint-disable max-len */
/* eslint-disable prefer-const */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/*
 * mobile  合同付款 43
 * @Author: your name
 * @Date: 2022-04-14 18:17:47
 * @LastEditTime: 2022-07-04 18:39:04
 * @LastEditors: 张立鹏 2475171028@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \signser-web\src\containers\Contacts\index.js
 */
import React, { useState, useRef } from 'react';
import { Input, Popover } from 'antd-mobile';
import PropTypes from 'prop-types';
import { CaretDownOutlined, EditOutlined } from '@ant-design/icons';
import 'moment/locale/zh-cn';
import './contract.scss';
import { useMount } from '../../../../../utils/hooks';
import right from '@/images/icon-right.png';
// eslint-disable-next-line import/extensions
import { initBudgetContractArr, updBudgetContractDrownFlag, appendBudgetContractChild } from '../../../../v1.1.24_PC/zkgj/utils/index';
// eslint-disable-next-line import/extensions
import request from '@/utils/request';
import loading from '@/images/loading.png';
import drownImg from '@/images/drown_1124.png';
import contractTipImg from '@/images/contract-tips_v2300.png';

let start = false;
export default function MobileZKGJProjectBudgetContract({ msg, appendMessage }) {
  MobileZKGJProjectBudgetContract.propTypes = {
    msg: PropTypes.object,
    appendMessage: PropTypes.func, // 发送消息方法
  };
  let [force, setForce] = useState(initBudgetContractArr(msg?.info?.list));
  const [pageSatus, setPageStatus] = useState(true);
  const [current, setCurrent] = useState(1);
  const [loadingStatus, setLoadingStatus] = useState(false);
  let [pickArr, setPickArr] = useState([]);
  const inputRef = useRef(null); // 文本框聚焦
  useMount(() => {
    if (current > 1) {
      request.instanceZhangkuan.get(`${process.env.REACT_APP_SIGNSERPUSH}/${msg.info.api}`, {
        params: {
          ...msg.info.parameter,
          per_page: msg.info.per_page,
          current_page: current,
        },
      }).then((res) => {
        setLoadingStatus(false);
        let list = initBudgetContractArr(res.info.list || []);
        if (current < 3) {
          force = [...force, ...list];
          setForce(force);
        } else {
          setForce([...force, ...list]);
        }
      });
    }
  }, [current]);

  useMount(() => {
    let timer;
    if (pageSatus && !(msg?.noPullToRefresh)) {
      timer = setTimeout(() => {
        Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollTop = Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollHeight;
      }, 300);
      return () => {
        clearTimeout(timer);
      };
    }
    return () => {
      // eslint-disable-next-line
      timer && clearTimeout(timer);
    };
  });

  // 下一页
  function nextPage() {
    start = false;
    const time = setTimeout(() => {
      if (!start) {
        if (pickArr.length) {
          setPickArr([]);
          return;
        }
        setLoadingStatus(true);
        setCurrent(current + 1);
        setPageStatus(false);
      }
      clearTimeout(time);
    }, 100);
  }

  // 收起
  function packUp() {
    start = false;
    const time = setTimeout(() => {
      if (!start) {
        setPickArr(force.slice(0, msg.info.per_page ? msg.info.per_page : 3));
      }
      clearTimeout(time);
    }, 100);
  }
  
  // 展开下拉
  async function handleShowList(index, flag) {
    // 没有请求接口
    if (Array.isArray(pickArr)) {
      setPickArr(updBudgetContractDrownFlag(pickArr, index, flag));
    }
    if (Array.isArray(force)) {
      setForce(updBudgetContractDrownFlag(force, index, flag));
    }
  }

  function onTouchMove() {
    start = true;
  }

  // 文本框输入
  function openSetBudgetInput(index1, index2) {
    let content = (pickArr.length ? pickArr : force)[index1].projects.info.list[index2];
    content.showBudgetSetInput = true;
    content.save_budget_money = content.budget_money || '';
    if (pickArr.length) {
      pickArr[index1].projects.info.list[index2] = content;
      setPickArr([...pickArr]);
    }
    force[index1].projects.info.list[index2] = content;
    setForce([...force]);
    let timeout = setTimeout(() => {
      // eslint-disable-next-line no-unused-expressions
      inputRef.current && inputRef.current.focus({ cursor: 'start' });
      clearTimeout(timeout);
    });
  }
  
  // 设置文本框的值
  function setInputValue(e, index1, index2) {
    // console.log(e, index1, index2, 'setInputValue');
    let content = (pickArr.length ? pickArr : force)[index1].projects.info.list[index2];
    let val = e?.target?.value || e;
    if (val) {
      // eslint-disable-next-line prefer-destructuring
      content.save_budget_money = `${val.replace(/[^\-?\d.]/g, '')}`.match(/^\d*(\.?\d{0,2})/g)[0];
    } else {
      content.save_budget_money = '';
    }
    if (pickArr.length) {
      pickArr[index1].projects.info.list[index2] = content;
      setPickArr([...pickArr]);
    }
    force[index1].projects.info.list[index2] = content;
    // console.log(force, 'force');
    setForce([...force]);
  }

  // 设置项目预算
  async function setProjectBudget(e, index1, index2) {
    let content = (pickArr.length ? pickArr : force)[index1].projects.info.list[index2];
    if (Number(e) !== Number(content.budget_money) || ((content.budget_money === null || content.budget_money === '') && e)) { 
      const params = {
        budget_money: e,
        project_id: content.id,
      };
      let res = await request.instanceZhangkuan.post(`${process.env.REACT_APP_ZK}/api/ac/project/budget/set`, {
        ...params,
      });
      content.budget_money = e;
    } 
    content.showBudgetSetInput = false;
    if (pickArr.length) {
      pickArr[index1].projects.info.list[index2] = content;
      setPickArr([...pickArr]);
    }
    force[index1].projects.info.list[index2] = content;
    setForce([...force]);
    // console.log(e, index1, index2, 'setProjectBudget');
  }
  
  return (
    <div className="v2300MobileZKGJProjectBudgetContract" style={{ minWidth: 'calc(540rem/75)', paddingBottom: Number(msg.info.total) > msg.info.per_page ? 'calc(52rem/75)' : '0' }}>
      {
        // eslint-disable-next-line max-len
        Array.isArray((pickArr.length ? pickArr : force)) && (pickArr.length ? pickArr : force).length ? (
          <>
            {/* 内容部分 */}
            <div className="budgetList">
              {
                (pickArr.length ? pickArr : force).map((item, index) => (
                  // eslint-disable-next-line
                  <div className="content-item" key={index}>
                    <div className="item-hd">
                      <div className="title">{item.contract_name || '-'}</div>
                      <div className="contract-number" onClick={() => { handleShowList(index, item.drownFlag); }}>
                        <span>
                          关联
                          {item.projects.info.total || '0'}
                          个项目
                        </span>
                        <img src={drownImg} alt="" className={item.drownFlag ? 'img-up' : ''} />
                      </div>
                    </div>
                    <div style={{ display: item.drownFlag && item.projects.info.total ? 'block' : 'none' }}>
                      {
                        item.projects && Array.isArray(item.projects.info.list) && item.projects.info.list ? (
                          item.projects.info.list.map((el, index1) => (
                            <div className="chilrldContract-content" key={index1}>
                              <div className="contract-tip">
                                <div className="contract-name">{el.project_name || '-'}</div>
                                {
                                  item.projects && item.projects.info && Array.isArray(item.projects.info.formula_explain) && item.projects.info.formula_explain.length ? (
                                    <div className="V2300MobileTooltipMain V2300MobileTooltipZKGJProjectBudgetContract">
                                      <Popover trigger="click" placement="bottom-start" getContainer={() => document.getElementsByClassName('V2300MobileTooltipZKGJProjectBudgetContract')[0]} content={item.projects.info.formula_explain.map((formulaItem, formulaIndex) => <div key={formulaIndex}>{formulaItem}</div>)}>
                                        <img src={contractTipImg} className="tipsImg" />
                                      </Popover>
                                    </div>  
                                  ) : ''
                                }
                              </div>
                              <div className="mt16 budgerFlex">
                                项目预算：
                                {
                                  el.showBudgetSetInput ? (
                                    <div className="setBudgetMain">
                                      <Input ref={inputRef} onBlur={(e) => setProjectBudget(e.target.value, index, index1)} onChange={(e) => { setInputValue(e, index, index1); }} className="budgetInputV2300Mobile" value={el.save_budget_money} maxLength="11" />
                                    </div>
                                  ) : (
                                    el.budget_money ? (
                                      <>
                                        <span className="v2210_moneyUnitMobileMR3">¥</span>
                                        {`${Number(el.budget_money)?.toFixed(2)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                                        <EditOutlined className="setBudgetTxtIcon" onClick={() => openSetBudgetInput(index, index1)} />
                                      </>
                                    ) : (
                                      <span className="setBudgetTxt" onClick={() => openSetBudgetInput(index, index1)}>点击输入预算</span>
                                    )
                                  )
                                }
                              </div>
                              <div className="mt8">
                                剩余预算：
                                {
                                  el.budget_money && (Number(el.budget_money) - Number(el.sjzc)) ? (
                                    <span className="remainingMoneyTxt">
                                      <span className="v2210_moneyUnitMobileMR3">¥</span>
                                      {`${(Number(el.budget_money) - Number(el.sjzc))?.toFixed(2)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                                    </span>
                                  ) : '-'
                                }
                              </div>
                              <div className="mt8">
                                {
                                  msg?.info?.parameter?.old_project_version === '1' ? '当前利润：' : '实际利润：'
                                }
                                {
                                  el.sjlr ? (
                                    <>
                                      <span className="v2210_moneyUnitMobileMR3">¥</span>
                                      {`${el.sjlr?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                                    </>
                                  ) : '-'
                                }
                              </div>
                              <div className="mt8">
                                {
                                  msg?.info?.parameter?.old_project_version === '1' ? '当前收入：' : '实际收入：'
                                }
                                {
                                  el.sjsr ? (
                                    <>
                                      <span className="v2210_moneyUnitMobileMR3">¥</span>
                                      {`${el.sjsr?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                                    </>
                                  ) : '-'
                                }
                              </div>
                              <div className="mt8">
                                {
                                  msg?.info?.parameter?.old_project_version === '1' ? '当前支出：' : '实际支出：'
                                }
                                {
                                  el.sjzc ? (
                                    <>
                                      <span className="v2210_moneyUnitMobileMR3">¥</span>
                                      {`${el.sjzc?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                                    </>
                                  ) : '-'
                                }
                              </div>
                            </div>
                          ))
                        ) : ''
                      }
                      {

                      }
                      {/* 显示展示多少合同 */}
                      {
                        item.projects.info.total > item.projects.info.list.length ? (
                          <div className="selLinkContract" onClick={() => appendBudgetContractChild(msg, item, appendMessage)}>
                            查看全部
                            {item.projects.info.total}
                            个关联合同 
                            <span className="mlr8">&gt;</span>
                          </div>
                        ) : ''
                      }
                    </div>
                  </div>
                ))
              }
            </div>
            {/* 分页 */}
            <div className={Number(msg.info.total) > msg.info.per_page ? 'v1124_filterBottomMobile v2200_filterBottomMobile' : 'none'}>
              {/* <div className="filter" /> */}
              <div className="flex ismore" style={{ height: 'calc(48rem/75)' }}>
                {/* eslint-disable-next-line */}
                <div className={current >= Number(msg.info.total_page) && pickArr.length === 0 ? 'none' : 'more'} onTouchStart={() => loadingStatus ? '' : nextPage()} onTouchMove={onTouchMove}>{loadingStatus ? <div className="loading"><img src={loading} alt="" /></div> : '查看更多'}</div>
                <div className={current > 1 && pickArr.length === 0 ? 'flex align-item left pick-style' : 'none'} style={{ position: 'absolute', right: 0 }} onTouchStart={packUp} onTouchMove={onTouchMove}>
                  <div className="pack-up">
                    收起
                  </div>
                  <div style={{ width: 'calc(28rem/75)', height: 'calc(28rem/75)' }}>
                    <img src={right} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : ''
      }
    </div>
  );
}
