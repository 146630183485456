/*
 * @Author: your name
 * @Date: 2022-03-28 17:10:43
 * @LastEditTime: 2022-07-27 16:10:28
 * @LastEditors: 张立鹏 2475171028@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \signser-web\src\containers\Approval\MyApproval.js
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Pagination, Tooltip } from 'antd';
import 'moment/locale/zh-cn';
import '@/style/myApproval.scss';
import { useMount } from '../../utils/hooks';
import { getTxtBgColor } from '../../utils/utils';
// eslint-disable-next-line
import request from '@/utils/request';
import loading from '@/images/loading.png';
import emptyImg from '@/images/approvalPC_empty.png';

export default function MyApproval({ msg }) {
  MyApproval.propTypes = {
    msg: PropTypes.object,
  };
  const dataArr = Object.values(msg.info.list);
  const keys = Object.keys(msg.info.list);
  const [pageSatus, setPageStatus] = useState(true);
  const [current, setCurrent] = useState(msg.info?.current_page || 1); // 当前页数
  const [content, setContent] = useState(null); // 接口返回的list
  const [total, setTotal] = useState(msg.info?.total || 0); // list总数
  const [prePage, setPrePage] = useState(msg.info?.per_page || 5); // 每页展示的条数
  const [loadingStatus, setLoadingStatus] = useState(false);
  useMount(() => {
    // 分页请求
    if (!pageSatus) {
      if (current > Number(msg.info.total_page) || current === 0) return;
      setLoadingStatus(true);
      request.instanceZhangkuan.get(`${process.env.REACT_APP_ZK}/${msg.info.api}`, {
        params: {
          ...msg.info.parameter,
          per_page: prePage,
          current_page: current,
        },
      }).then((res) => {
        setLoadingStatus(false);
        setTotal(res.info.total);
        setPrePage(res.info.per_page);
        setContent([...Object.values(res.info.list)]);
      });
    }
  }, [current]);

  useMount(() => {
    let timer;
    if (pageSatus) {
      timer = setTimeout(() => {
        Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollTop = Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollHeight;
      }, 300);
      return () => {
        clearTimeout(timer);
      };
    }
    return () => {
      // eslint-disable-next-line
      timer && clearTimeout(timer);
    };
  });

  // 切换页码
  function onChange(page) {
    setCurrent(page);
    setPageStatus(false);
  }
  return (
    <div className={loadingStatus ? 'myApproval v1123_relative' : 'myApproval'}>
      <div className={loadingStatus ? 'mask' : 'none'} />
      <div className={loadingStatus ? 'loading' : 'none'}>
        <img src={loading} alt="" />
      </div>
      {
        <>
          <div className="item-main">
            {/* 头部标题 */}
            <div className="approval-header">
              <div className="header-item pL32">审批标题</div>
              <div className="header-item item-status">审批状态</div>
            </div>
            {/* 表格内容 */}
            <div className="approval-content">
              {
                total ? (
                  <>
                    {(content || dataArr).map((ele, index) => (
                      <div className="content-item" key={keys[index]}>
                        <div className="approval-title pL32 txt-ellipsis cursorPointer tooltipFontWeight400Size12">
                          <Tooltip placement="top" getPopupContainer={(triggerNode) => triggerNode.parentNode} title={ele.describe || '-'}>
                            <span>{ele.describe || '-'}</span>
                          </Tooltip>
                        </div>
                        <div className="approval-deal">
                          <span className="deal-statusTxt" style={{ color: getTxtBgColor(1, ele.status), background: getTxtBgColor(2, ele.status) }}>{ele.status}</span>
                        </div>
                      </div>
                    ))}
                    <Pagination hideOnSinglePage pageSize={prePage} onChange={(page, pageSize) => onChange(page, pageSize)} className="pageination-main" size="small" showSizeChanger={false} total={total} />
                  </>
                ) : (
                  <div className="empty-main">
                    <img src={emptyImg} alt="" />
                    <div className="empty-txt">暂无数据</div>
                  </div>
                )
              }
            </div>
          </div>
        
        </>
      }
    </div>
  );
}
