/*
 * 有答案推荐和无答案推荐
 * @Author: rightAware 2475171028@qq.com
 * @Date: 2022-05-06 14:46:43
 * @LastEditors: rightAware 2475171028@qq.com
 * @LastEditTime: 2022-05-12 14:39:57
 * @FilePath: \signser-web\src\containers\v1.1.24_PC\recommend\NoAnswer.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMount } from '../../../utils/hooks';
import './recommend.scss';
// eslint-disable-next-line import/extensions
import { recommendNoAnser } from '@/apis/http';
import { recommendClick } from '../../../utils/utils';
import loading from '@/images/loading.png';

// is_no_answer 无答案 is_yes_answer 有答案
export default function RecommendIndex({ msg, pcSend, appendMessage }) {
  RecommendIndex.propTypes = {
    msg: PropTypes.object,
    pcSend: PropTypes.func,
    appendMessage: PropTypes.func,
  };
  const [content, setContent] = useState(null); // 接口返回的list
  const [pageSatus, setPageStatus] = useState(true);
  const [loadingStatus, setLoadingStatus] = useState(false);
  useMount(() => {
    setContent(msg);
    // eslint-disable-next-line max-len
    setLoadingStatus(false);
    setPageStatus(false);
  });
  useMount(() => {
    let timer;
    if (pageSatus) {
      timer = setTimeout(() => {
        Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollTop = Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollHeight;
      }, 300);
      return () => {
        clearTimeout(timer);
      };
    }
    return () => {
      // eslint-disable-next-line
      timer && clearTimeout(timer);
    };
  });

  // 点击某一项
  function handleClickItem(item) {
    if (item === '这不是我想问的') {
      recommendNoAnser({ message: item }).then((res) => {
        if (res && res.info && Array.isArray(res.info.list) && res.info.list.length) {
          appendMessage({ text: JSON.stringify({ data: { ...res, recommendType: 'is_no_answer' }, code: 0 }) }, 'left', 'text');
        }
      });
      return;
    }
    pcSend(item.text);
    recommendClick('recommendAnswerClick', content, item, msg);
  }

  return (
    <div className={loadingStatus ? 'recommendPCMain v1123_relative' : 'recommendPCMain'}>
      <div className={loadingStatus ? 'mask' : 'none'} />
      <div className={loadingStatus ? 'loading' : 'none'}>
        <img src={loading} alt="" />
      </div>
      {/* // 是否显示标题0不显示1显示 */}
      {
        content && content.is_text === 0 ? '' : (
          <header className="header" style={{ wordBreak: 'break-word', wordWrap: 'break-word', marginBottom: '6px' }}>{content?.title}</header>
        )
      } 
      {/* 无答案：inquiry_type === 2 是圆圈样式 3位列表样式 */}
      {
        content && content.info && Array.isArray(content.info.list) ? (
          <div className={msg && msg.inquiry_type === 2 ? 'info-list info-list_circle' : 'info-list'}>
            {
              content.info.list.map((el, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div className={msg && msg.inquiry_type === 2 ? 'list-item list-item_circle' : 'list-item'} key={index} onClick={() => handleClickItem(el)}>
                  {
                    msg && msg.inquiry_type === 2 ? (
                      <span>
                        {el.text} 
                      </span>
                    ) : (
                      <span>
                        {index + 1}
                        、
                        {el.text} 
                      </span>
                    )
                  }
                </div>
              ))
            }
            {
              msg && msg.recommendType === 'is_no_answer' && msg.inquiry_type === 2 ? (
                <div className="list-item list-item_circle" onClick={() => handleClickItem('这不是我想问的')}>这不是我想问的</div>
              ) : ''
            }
          </div>
        ) : ''
      }
    </div>
  );
}
