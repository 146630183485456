/*
pc发票组件
 * @Author: your name
 * @Date: 2021-10-28 17:59:48
 * @LastEditTime: 2022-01-25 18:25:55
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \signser\src\containers\TextTable\index.js
 */
import React from 'react';
import PropTypes from 'prop-types';
import { useScrollBottom } from '../../utils/hooks';

export default function TextTable({ msg }) {
  TextTable.propTypes = {
      msg: PropTypes.object,
  };
  const { info } = msg;
  useScrollBottom();

    return (
      <div>
        <div style={{ width: '278px' }}>
          <br />
          <span style={{ color: '#FFAA00' }}>{info.electronic_bill_num}</span>
          &nbsp;&nbsp;张增值税电子普通发票
          <br />
          <span style={{ color: '#FFAA00' }}>{info.special_bill_num}</span>
          &nbsp;&nbsp;张增值税专用发票
          <br />
          <span style={{ color: '#FFAA00' }}>{info.normal_bill_num}</span>
          &nbsp;&nbsp;张增值税普通发票
          <br />
          <span style={{ color: '#FFAA00' }}>{info.contract_bill_num}</span>
          &nbsp;&nbsp;张合同发票
          <br />
          <span style={{ color: '#FFAA00' }}>{info.other_bill_num}</span>
          &nbsp;&nbsp;张其他发票
        </div>
      </div>
    );
}
