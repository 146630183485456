/*
 * @Author: your name
 * @Date: 2022-01-25 09:47:43
 * @LastEditTime: 2022-01-26 18:04:23
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \Signser-web\src\containers\ReceivableRanking\index.js
 */
// 回款排名组件
import React from 'react';
import PropTypes from 'prop-types';
import '@/style/ReceivableRanking.scss';
import { useScrollBottom } from '../../utils/hooks';

export default function ReceivableRanking({ msg }) {
    ReceivableRanking.propTypes = {
        msg: PropTypes.object,
    };
    useScrollBottom();
    return (
      <div className="ReceivableRanking">
        {
            msg && msg.info && msg.info.list.map((item, index) =>
            // eslint-disable-next-line
            <div style={{ wordBreak: 'break-all', wordWrap: 'break-word' }} key={index} dangerouslySetInnerHTML={{ __html: item }} />)
        }
      </div>
    );
}
