/* eslint-disable no-plusplus */
/* eslint-disable no-prototype-builtins */
/*
 * @Author: your name
 * @Date: 2022-04-06 09:29:58
 * @LastEditTime: 2022-07-29 16:36:18
 * @LastEditors: 张立鹏 2475171028@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \signser-web\src\containers\MobileMyApproval\list.js
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Calendar, Popup } from 'antd-mobile';
import moment from 'moment';
import '@/style/MobileMyApproval.scss';
import 'antd-mobile/es/global';
import { useMount } from '../../utils/hooks';
// eslint-disable-next-line
import request from '@/utils/request';
import right from '@/images/icon-right.png';
import loading from '@/images/loading.png';
import emptyImg from '@/images/approvalPC_empty.png';
import selDateIcon from '@/images/approval_selDate.png';
import selDateTrueIcon from '@/images/approval_selDateTrue.png';

let start = false;

export default function MyApprovalList({ msg, id, activeKey }) {
  MyApprovalList.propTypes = {
    msg: PropTypes.object,
    id: PropTypes.string,
    activeKey: PropTypes.string,
  };
  let arr = Object.values(msg.info.list || []);
  let saveSelDate = null; // 设置保存时间
  
  const [updateKey, setUpdateKey] = useState(false); // 日期组件的key
  const [visible, setVisible] = useState(false); // 日期组件显示隐藏
  const [dataState, setDataState] = useState(); // 设置日期组件
  const [force, setForce] = useState(null);
  const [pageSatus, setPageStatus] = useState(true);
  const [current, setCurrent] = useState(msg.info?.current_page || 1);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [pickArr, setPickArr] = useState([]);
  const [totalPage, setTotalPage] = useState(msg.totalPage); // 一共多少页

  useMount(() => {
    arr = Object.values(msg.info.list || []);
    setTotalPage(msg.totalPage);
  }, [msg.totalPage]);

  const titleDOm = document.getElementById(id);
  if (titleDOm && msg.state === activeKey && titleDOm.innerHTML !== msg.title) {
    titleDOm.innerHTML = msg.title || '';
  }

  useMount(() => {
    let timer;
    if (pageSatus) {
      timer = setTimeout(() => {
        Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollTop = Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollHeight;
      }, 300);
      return () => {
        clearTimeout(timer);
      };
    }
    return () => {
      // eslint-disable-next-line
      timer && clearTimeout(timer);
    };
  });
  
  // 请求数据
  function getDataList(page, date) {
    const params = {
      ...msg.info.parameter,
      per_page: 5,
      current_page: page,
      date: dataState,
    };
    if (Array.isArray(date) && date.length) {
      params.date = JSON.stringify([`${date[0]}/${date[1]}`]); // 日期
    }
    request.instanceZhangkuan.get(`${process.env.REACT_APP_ZK}/${msg.info.api}`, {
      params,
    }).then((res) => {
      setLoadingStatus(false);
      const title = document.getElementById(id);
      if (title && title.innerHTML !== res.title && msg.state === activeKey) {
        title.innerHTML = res.title || '';
        msg.title = res.title;
      }
      switch (res.info?.parameter.state || msg.state) {
        case '待审批':
          msg.changeNavTotal('待审批', res.info.pending_count);
          msg.totalPage = res.info.pending_count;
          setTotalPage(res.info.pending_count);
          break;
        case '已驳回':
          msg.changeNavTotal('已驳回', res.info.refuse_count);
          msg.totalPage = res.info.refuse_count;
          setTotalPage(res.info.refuse_count);
          break;
        case '已通过':
          msg.changeNavTotal('已通过', res.info.pass_count);
          msg.totalPage = res.info.pass_count;
          setTotalPage(res.info.pass_count);
          break;  
        default:
          msg.changeNavTotal('待审批', res.info.pending_count);
          msg.totalPage = res.info.pending_count;
          setTotalPage(res.info.pending_count);
          break;
      }
      msg.info.list = res.info.list;
      msg.info.current_page = res.info.current_page || 1;
      msg.info.total_page = res.info.total_page;
      if (page < 3) {
        arr = [...arr, ...Object.values(res.info.list)];
        setForce(arr);
      } else {
        setForce([...force, ...Object.values(res.info.list)]);
      }
    });
  }

  function getTime(val) {
    return Array.isArray(val) && [moment(val[0]).format('YYYY-MM-DD'), moment(val[1]).format('YYYY-MM-DD')];
  }

  // 弹窗选择操作 submit： 确定 cancel 为 取消
  function handlePopupConfirm(type = 'cancel') {
    if (type === 'submit' && Array.isArray(saveSelDate) && saveSelDate.length) {
      arr = [];
      setDataState(saveSelDate);
      getDataList(1, saveSelDate);
      setForce(null);
      setPickArr([]);
      setLoadingStatus(true);
      setVisible(false);
      setCurrent(1);
      setPageStatus(false);
    }
    console.log(saveSelDate, '选择后的saveSelDate', type);
    saveSelDate = null;
    setUpdateKey(updateKey + 1);
    setVisible(false);
  }

  // 下一页
  function nextPage() {
    start = false;
    const time = setTimeout(() => {
      if (!start) {
        if (pickArr.length) {
          setPickArr([]);
          return;
        }
        setLoadingStatus(true);
        setCurrent(current + 1);
        getDataList(current + 1, dataState);
        setPageStatus(false);
      }
      clearTimeout(time);
    }, 100);
  }

  // 收起
  function packUp() {
    start = false;
    const time = setTimeout(() => {
      if (!start) {
        setPickArr(force.slice(0, 5));
      }
      clearTimeout(time);
    }, 100);
  }

  function onTouchMove() {
    start = true;
  }

  return (
    <div style={{ minWidth: 'calc(527rem/75)' }}>
      <div className="item-main" style={{ marginBottom: msg.info.total_page <= 1 ? 'calc(0rem/75)' : 'calc(52rem/75)' }}>
        <div className="approval-header">
          <div className="header-item pL24">审批标题</div>
          <div className="header-item item-status">
            <div className="time-date" onClick={() => { setVisible(true); }}>
              <div>
                {activeKey === '待审批' ? '发起时间' : '审批时间'}
              </div>
              <img src={Array.isArray(dataState) && dataState.length ? selDateTrueIcon : selDateIcon} alt="" />
              <Popup
                visible={visible}
                bodyClassName="popupDefaultHeight"
                className="mobile_v1_1_23-popup-container"
                onMaskClick={() => {
                  setVisible(false);
                  saveSelDate = null;
                  setUpdateKey(updateKey + 1);
                }}>
                <div className="confirm-date">
                  <div onTouchStart={(e) => { e.stopPropagation(); handlePopupConfirm('cancel'); }}>
                    取消
                  </div>
                  <div onTouchStart={(e) => { e.stopPropagation(); handlePopupConfirm('submit'); }} className="txt_3377FF">确定</div>
                </div>
                <Calendar
                  key={updateKey}
                  selectionMode="range"
                  defaultValue={Array.isArray(dataState) && dataState.length 
                    ? [new Date(dataState[0]), new Date(dataState[1])] : null}
                  onChange={(val) => {
                    // if (val && Array.isArray(val)) {
                    //   if (val[0] === val[1]) {
                    //     const item = moment(val[0]).format('YYYY-MM-DD');
                    //     if (Array.isArray(saveSelDate) && saveSelDate.length) {
                    //       if (saveSelDate[0] === item) {
                    //         saveSelDate = [saveSelDate[0], item];
                    //         arr = [];
                    //         setDataState(saveSelDate);
                    //         getDataList(1, saveSelDate);
                    //         setForce(null);
                    //         setPickArr([]);
                    //         setLoadingStatus(true);
                    //         setVisible(false);
                    //         setCurrent(1);
                    //         setPageStatus(false);
                    //         saveSelDate = null;
                    //       }
                    //     } else {
                    //       saveSelDate = [item];
                    //     }
                    //   } else {
                    //     saveSelDate = getTime(val);
                    //     arr = [];
                    //     setDataState(saveSelDate);
                    //     getDataList(1, saveSelDate);
                    //     setForce(null);
                    //     setPickArr([]);
                    //     setLoadingStatus(true);
                    //     setVisible(false);
                    //     setCurrent(1);
                    //     setPageStatus(false);
                    //     saveSelDate = null;
                    //   }
                    // } else {
                    //   saveSelDate = null;
                    // }
                    if (val && Array.isArray(val)) {
                      saveSelDate = getTime(val);
                    } 
                  }} />
              </Popup>
            </div>
          </div>
        </div>
        {arr.length === 0 ? (
          <div className="empty-main">
            <img src={emptyImg} alt="" />
            <div className="empty-txt">暂无数据</div>
          </div>
          ) : (
            <>
              {/* eslint-disable-next-line */}
              {(pickArr.length ? pickArr : force || arr).map((ele, index) => (
                // eslint-disable-next-line
                <div className="content-item" key={index}>
                  <div className="approval-title pL24">{ele.describe}</div>
                  <div className="approval-deal">
                    <span className="deal-statusTxt">{ele.add_time}</span>
                  </div>
                </div>
              ))}
            </>
        )}
      </div>
      <div className={Number(totalPage) > 5 ? 'mobile-filter-bottom' : 'none'}>
        <div className={current >= Number(msg.info.total_page) && pickArr.length === 0 ? '' : 'filter'} style={{ minWidth: 'calc(527rem/75)' }} />
        <div className="flex ismore" style={{ height: 'calc(48rem/75)' }}>
          {/* eslint-disable-next-line */}
          <div className={current >= Number(msg.info.total_page) && pickArr.length === 0 ? 'none' : 'more'} onTouchStart={() => loadingStatus ? '' : nextPage()} onTouchMove={onTouchMove}>{loadingStatus ? <div className="loading"><img src={loading} alt="" /></div> : '查看更多'}</div>
          <div className={current > 1 && pickArr.length === 0 ? 'flex align-item left pick-style' : 'none'} style={{ position: 'absolute', right: 0 }} onTouchStart={packUp} onTouchMove={onTouchMove}>
            <div className="pack-up">
              收起
            </div>
            <div style={{ width: 'calc(28rem/75)', height: 'calc(28rem/75)' }}>
              <img src={right} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
