/* eslint-disable array-callback-return */
/* eslint-disable prefer-const */
/*
 * @Author: your name
 * @Date: 2021-10-14 17:55:17
 * @LastEditTime: 2022-07-19 18:04:47
 * @LastEditors: 张立鹏 2475171028@qq.com
 * @Description: In User Settings Edit
 * @FilePath: \signser\src\utils\utils.js
 */
// eslint-disable-next-line import/extensions
import { signserPushBuriedpoint } from '@/utils/utils';

// 对话流页面/设置预算
// eslint-disable-next-line import/prefer-default-export
export const setRemindPoint = (origin = 'pc', pcSend, txt) => {
  signserPushBuriedpoint('clickRemindSet', origin);
  pcSend(txt);
};
